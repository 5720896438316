import React from "react";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";
import {COLOR_COMPARE_PLANS_EXCESS, COLOR_COMPARE_PLANS_SHORTFALL} from "../../constants/colors";

interface ReserveTargetLengthRowProps {
    comparePlan1PortfolioReserveTargetLength: number | undefined;
    comparePlan2PortfolioReserveTargetLength: number | undefined;
    comparePlan2PlanSummaryResponse: PlanSummaryResponse;
    isComparePlan2: Boolean;
    showPillText?: boolean;
    differenceInPortfolioReserveTargetLength?: number;
}

const ReserveTargetLengthRow: React.FC<ReserveTargetLengthRowProps> = ({
                                                                           comparePlan1PortfolioReserveTargetLength,
                                                                           comparePlan2PortfolioReserveTargetLength,
                                                                           isComparePlan2,
                                                                           comparePlan2PlanSummaryResponse,
                                                                           showPillText,
                                                                           differenceInPortfolioReserveTargetLength
                                                                       }) => {
    return (
        <div>
            {!isComparePlan2 && (
                <div className="plan-summary-card">
                    <div style={{textAlign: "center", marginTop: 15}}>
                        <b>{comparePlan1PortfolioReserveTargetLength ?? 0} years</b>
                    </div>
                </div>
            )}

            {isComparePlan2 && comparePlan2PlanSummaryResponse != null && (
                <div className="plan-summary-card">
                    <div style={{textAlign: "center", marginTop: 15}}>
                        <b>{comparePlan2PortfolioReserveTargetLength ?? 0} years</b>
                        {showPillText && differenceInPortfolioReserveTargetLength!= 0 &&
                            <span
                                className={differenceInPortfolioReserveTargetLength! >= 0 ? "compare-plans-pill-container pill-container-excess position-absolute" : "compare-plans-pill-container pill-container-shortfall position-absolute"}
                                style={{
                                    color: differenceInPortfolioReserveTargetLength! >= 0 ? COLOR_COMPARE_PLANS_EXCESS : COLOR_COMPARE_PLANS_SHORTFALL,
                                }}>
                                <span
                                    className="pill-text"> {`${differenceInPortfolioReserveTargetLength! > 0 ? '+':''}${differenceInPortfolioReserveTargetLength!} years`}
                                </span>
                            </span>
                        }
                    </div>
                </div>
            )}
        </div>
    )
}

export default ReserveTargetLengthRow;