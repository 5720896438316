import React from "react";
import classNames from "classnames";

export const StateOfResidencyAccordionHeader = (isInReportPreview: boolean) => {
    return (
        <div className={classNames("state-of-residency-accordion-header",
            {"state-of-residency-accordion-header__web": !isInReportPreview}
        )}
             data-testid={"state-of-residency-accordion-header"}>
            <span className={"span-1"}>Name</span>
            <span className={"span-2"}>State</span>
            <span className={"span-3 textalign-right"}>Effective Rate %</span>
        </div>
    )
};