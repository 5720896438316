import React, {Suspense, useCallback, useMemo, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {selectDifferenceBetweenPlans, setDifferenceBetweenPlans} from "./ComparePlansSlice";

const ComparePlansHeader = React.lazy(() => import("./ComparePlansHeader"));
const ComparePlansContent = React.lazy(() => import("./ComparePlansContent"));

interface ComparePlansProps {
    showDeltaBetweenPlans: boolean;
}

export const ComparePlans: React.FC<ComparePlansProps> = () => {
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState<boolean>(false);
    const showDifferenceBetweenPlans = useSelector(selectDifferenceBetweenPlans, shallowEqual);

    const handleShowDeltaBetweenPlans = useCallback((isChecked: boolean) => {
        dispatch(setDifferenceBetweenPlans(isChecked));
    }, [dispatch]);

    const handleComparePlanDropDownChange = useCallback((isComparePlan2Selected: boolean) => {
        setSelectedValue(isComparePlan2Selected);
    }, []);

    const memoizedShowDifferenceBetweenPlans = useMemo(() => showDifferenceBetweenPlans, [showDifferenceBetweenPlans]);
    const memoizedSelectedValue = useMemo(() => selectedValue, [selectedValue]);

    return (
        <div className="compare-plans">
            <Suspense fallback={<div>Loading...</div>}>
                <ComparePlansHeader value={memoizedShowDifferenceBetweenPlans} onChange={handleShowDeltaBetweenPlans}
                                    selectedValue={memoizedSelectedValue}/>
                <ComparePlansContent value={memoizedShowDifferenceBetweenPlans}
                                     onChange={handleComparePlanDropDownChange}/>
            </Suspense>
        </div>
    );
}

export default React.memo(ComparePlans);