import {PresentationPaneButton, PresentationPaneHeader} from "../../components";
import React from "react";
import {formatNumberRoundedToOneDecimal, formatNumberRoundedToTwoDecimals, formatYear} from "../../utils/format";
import {calculateYearsBetween, getTodayDateAsString} from "../../utils/dateUtils";
import {Container} from "react-grid-system";
import {useAppSelector} from "../../store/hooks";
import {AllocationCardContent} from "../../Goals/PortfolioReserve/MonitorPortfolioReserve";
import goalUtils from "../../Goals/Summary/GoalUtils";
import {ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import {NO_OP} from "../../constants/common";
import {selectPortfolioReserveModel} from "../../Goals/PortfolioReserve/PortfolioReserveSlice";
import {
    EMPTY_REVISE_ASSET_ALLOCATION_RESPONSE,
    ReviseAssetAllocationResponse
} from "../../ClientManagement/AssetAllocation/ReviseAssetAllocation";
import SetPortfolioReserveHeader from "../../Goals/PortfolioReserve/SetPortfolioReserveHeader";

type MonitorPortfolioReserveReportProps = {
    approvedProfile: ProfileResponse,
    currentAllocation: ReviseAssetAllocationResponse,
    proposedAllocation: ReviseAssetAllocationResponse
}

const MonitorPortfolioReserveReport = ({
                                           approvedProfile,
                                           currentAllocation,
                                           proposedAllocation
                                       }: MonitorPortfolioReserveReportProps) => {

    const portfolioReserveModel = useAppSelector(selectPortfolioReserveModel);
    const {portfolioReserve} = portfolioReserveModel;

    const reserveTargetLength = approvedProfile.portfolioReserveTargetLength ? approvedProfile.portfolioReserveTargetLength : 0;
    const portfolioReserveActivationDate = approvedProfile ? approvedProfile.portfolioReserveActivationDate : null;
    const yearsFromPortfolioReserveActivationDate = portfolioReserveActivationDate ? calculateYearsBetween(portfolioReserveActivationDate, getTodayDateAsString()) : 0
    const currentSupportYears = portfolioReserve.preciseCalculatedTargetLength;
    const replenishedAllocationResponse: ReviseAssetAllocationResponse = {
        ...EMPTY_REVISE_ASSET_ALLOCATION_RESPONSE,
        totalProposedRiskAssetsPercent: portfolioReserve.targetLengthAllocation.riskAssetPercent,
        totalProposedRiskControlAssetsPercent: portfolioReserve.targetLengthAllocation.riskControlPercent
    }

    const remainingReserveYears = goalUtils.getRemainingPortfolioReserveLength(reserveTargetLength, portfolioReserveActivationDate)

    const totalCurrentRiskAssetsPercentOfTotalPortfolio = Number(formatNumberRoundedToTwoDecimals(currentAllocation.totalCurrentRiskAssetsAmount / (currentAllocation.totalCurrentRiskAssetsAmount + currentAllocation.totalCurrentRiskControlAssetsAmount) * 100));
    const totalCurrentRiskControlAssetsPercentOfTotalPortfolio = Number(formatNumberRoundedToTwoDecimals(currentAllocation.totalCurrentRiskControlAssetsAmount / (currentAllocation.totalCurrentRiskAssetsAmount + currentAllocation.totalCurrentRiskControlAssetsAmount) * 100));

    return (
        <div className="monitor-portfolio-reserve-page">
            <div>
                <SetPortfolioReserveHeader
                    displaySetReserveTargetButton={true}
                    isSetReserve={false}
                    onTogglePortfolioReserve={NO_OP} />
            </div>
            <div className="monitor-portfolio-reserve-page__body">
                <div className="monitor-portfolio-reserve-page__body__text font-xl">
                    <div className={"monitor-portfolio-reserve-page__body__text__title-card"}>
                        {"Reserve Target: "}
                    </div>
                    <div className="monitor-portfolio-reserve-page__body__text__title-card__year">
                        {formatYear(reserveTargetLength)}
                    </div>
                </div>
                {
                    portfolioReserveActivationDate &&
                    <div className="monitor-portfolio-reserve-page__body__text font-xl">
                        <div className={"monitor-portfolio-reserve-page__body__text__title-card"}>
                            {"Your reserve has been funding your Lifestyle Spending for "}
                        </div>
                        <div className="monitor-portfolio-reserve-page__body__text__title-card__year">
                            {formatNumberRoundedToOneDecimal(yearsFromPortfolioReserveActivationDate)} Years
                        </div>
                    </div>
                }
                <div className={"monitor-portfolio-reserve-page__body__cards"}>
                    {/*this is the left column of the page*/}
                    <Container data-testid={"current-card"} className='drawdown-card__current'>
                        <AllocationCardContent value={currentSupportYears}
                                               data={currentAllocation}
                                               roundedRiskAssetsPercent={totalCurrentRiskAssetsPercentOfTotalPortfolio}
                                               roundedRiskControlAssetsPercent={totalCurrentRiskControlAssetsPercentOfTotalPortfolio}
                                               allocationType={"current"}
                                               hasSmallHeader={true}
                                               header={"Current Drawdown"}
                                               subheader={"Your current Asset Allocation supports"}
                                               subEnding={"of your Lifestyle Spending"}
                                               donutTitle={"Current Asset Allocation"}
                        />
                    </Container>

                    {/*this is the right column of the page*/}
                    <Container data-testid={"proposed-card"} className="drawdown-card">
                        <AllocationCardContent value={remainingReserveYears}
                                               data={proposedAllocation}
                                               roundedRiskAssetsPercent={proposedAllocation.totalProposedRiskAssetsPercentOfTotalPortfolio}
                                               roundedRiskControlAssetsPercent={proposedAllocation.totalProposedRiskControlAssetsPercentOfTotalPortfolio}
                                               allocationType={"proposed"}
                                               hasSmallHeader={true}
                                               header={"Proposed Allocation"}
                                               subheader={"Your proposed Asset Allocation would support"}
                                               subEnding={"of your Lifestyle Spending"}
                                               donutTitle={"Proposed Asset Allocation"}
                        />
                        <div className="nav-drawer__separator"/>
                        <AllocationCardContent value={reserveTargetLength}
                                               data={replenishedAllocationResponse}
                                               roundedRiskAssetsPercent={portfolioReserve.targetLengthAllocation.riskAssetPercent}
                                               roundedRiskControlAssetsPercent={portfolioReserve.targetLengthAllocation.riskControlPercent}
                                               allocationType={"replenished"}
                                               hasSmallHeader={false}
                                               header={""}
                                               subheader={"Replenishing to your "}
                                               subEnding={" would result in a proposed Asset Allocation: "}
                                               donutTitle={"Proposed Asset Allocation with replenished Risk Control"}
                        />
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default MonitorPortfolioReserveReport;
