import React, {useEffect, useState} from 'react';
import {AssetsSummary} from "./models/Assets";
import {AlertBanner} from "../components";
import {assetsApiClient} from "./AssetsApiClient";

export interface InfoBannerForStandaloneAndBankingDepositProps {
    assets: AssetsSummary;
    profileId: string;
}

const InfoBannerForStandaloneAndBankingDeposit = ({assets, profileId}: InfoBannerForStandaloneAndBankingDepositProps) => {
    const [showInfoBanner, setShowInfoBanner] = useState<boolean>(false);

    useEffect(() => {
        if (assets.accounts.data.length > 0 && (assets.bankingAccounts && assets.bankingAccounts.length > 0)
            && assets.bankingAccounts.some(account => account.showBanner)) {
            setShowInfoBanner(true);
        } else if(assets.bankingAccounts.length > 0 && (assets.accounts.data.length === 0)) {
            setShowInfoBanner(false);
        }else {
            setShowInfoBanner(false);
        }
    }, [assets]);

    const closeBanner = () => {
        assetsApiClient.closeBanner(profileId, 'banking').then(r => { console.log(r); });
        setShowInfoBanner(false);
    }

    return <AlertBanner
        className={'marginbottom-lg'}
        fullWidth={false}
        icon="info"
        type="info"
        showAlert={showInfoBanner}
        showCloseBtn={true}
        message={"NT bank accounts have been added. Please review standalone accounts to ensure duplicates are removed."}
        onClose={closeBanner}
    />
}

export default InfoBannerForStandaloneAndBankingDeposit;