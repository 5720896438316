import React from "react";
import {Col, Container, Row} from "react-grid-system";
import {formatCurrency} from "../../utils/format";
import classNames from "classnames";

type ImpactCardProps = {
    impactTypeClass: string,
    impactHeaderText: string,
    impactValue: number | null | undefined,
    hasSORCard: boolean | undefined
}

const ImpactCard = (props: ImpactCardProps) => {
    const formattedImpactValue: string = props.impactValue ? formatCurrency(props.impactValue) : "--";
    const impactValueClass = props.impactValue ? `${props.impactTypeClass}` : 'greyed-out';

    return (
        <Container className={classNames(`impact-card ${props.impactTypeClass}-border`,
            {'impact-card-background-with-sor-card': props.hasSORCard},
            {'impact-card-background-without-sor-card': !props.hasSORCard}
        )}>
            <Row>
                <Col>
                    <h4>{`Impact to ${props.impactHeaderText}`}</h4>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row className={"impact-value"}>
                        <Col>
                            <span className={`${impactValueClass}`}>{formattedImpactValue}</span>
                        </Col>
                    </Row>
                    <Row className={"impact-card__footer"}>
                        <Col>
                            <span>with included strategies</span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default ImpactCard;