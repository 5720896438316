import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import React, {useEffect} from "react";
import {Button} from "../../components";
import {ReleaseTogglesType} from "../../ReleaseToggles/ReleaseToggles";

type ReportHeaderControlsProps = {
    activeIndex: string,
    setActive: any,
    isPreviewDisabled: boolean,
    isUploadReportDisabled: boolean,
    isDownloadDisabled: boolean,
    sendAnnualReportDate: () => void,
    getClientPersonaIds: () => void,
    releaseToggles: ReleaseTogglesType,
    formatFileName: () => string,
    generatedReportUrl: string,
    isReportGenerationTriggered: boolean
}

export function ReportHeaderControls({
                                         activeIndex,
                                         setActive,
                                         isPreviewDisabled,
                                         isUploadReportDisabled,
                                         sendAnnualReportDate,
                                         getClientPersonaIds,
                                         releaseToggles,
                                         formatFileName,
                                         isDownloadDisabled,
                                         generatedReportUrl,
                                         isReportGenerationTriggered
                                     }: ReportHeaderControlsProps) {
    const profile = useAppSelector(selectProfile);
    useEffect(() => {
        if (profile?.id && isReportGenerationTriggered === false) {
            setActive(0);
        }

    }, [profile?.id]);

    return <>
        {
            parseInt(activeIndex) !== 1 ? (
                <Button
                    className="next-btn"
                    icon="right"
                    iconName="arrow_right"
                    kind="primary"
                    onClick={() => setActive(parseInt(activeIndex) + 1)}
                    size="medium"
                    type="button"
                    rounded={true}
                    disabled={isPreviewDisabled}
                >
                    Preview
                </Button>
            ) : (
                <div>
                    <a href={generatedReportUrl}
                       role="button"
                       aria-label="Download PDF link"
                       download={formatFileName()}
                    >
                        <Button
                            className="next-btn"
                            kind="primary"
                            icon="right"
                            iconName="download"
                            onClick={sendAnnualReportDate}
                            size="medium"
                            type="button"
                            rounded={true}
                            disabled={isDownloadDisabled}
                        >
                            Download PDF
                        </Button>
                    </a>

                    {releaseToggles?.enableSendReportToDCH && (profile.sample === false && profile.prospect === false) && <Button
                        className="next-btn marginleft-md"
                        icon="right"
                        iconName="arrow_right"
                        kind="primary"
                        onClick={getClientPersonaIds}
                        size="medium"
                        type="button"
                        rounded={true}
                        disabled={isUploadReportDisabled}
                    >
                        Send to Private Passport
                    </Button>}
                </div>
            )
        }
    </>
}