import React from "react";
import {formatPercentWithTwoDecimals} from "../../utils/format";
import {StateOfResidencyInput} from "../../components";
import {NO_OP} from "../../constants/common";
import classNames from "classnames";

type StateOfResidencyAccordionHeaderProps = {
    description: string,
    isProfileSOR: boolean,
    stateOfResidency: string,
    effectiveRate: number | undefined,
    isInReportPreview: boolean,
    enabled: boolean,
    listOfStates: string[],
};

export function StateOfResidencyAccordionItem({
                                                  description,
                                                  isProfileSOR,
                                                  stateOfResidency,
                                                  effectiveRate,
                                                  enabled,
                                                  isInReportPreview,
                                                  listOfStates
                                              }: Readonly<StateOfResidencyAccordionHeaderProps>) {
    const displayEffectiveRate = effectiveRate === undefined ? "––" : formatPercentWithTwoDecimals(effectiveRate)

    return (
        <div>
            {
                <div
                    className={
                        classNames({"state-of-residency-accordion-item__report": isInReportPreview},
                            {"state-of-residency-accordion-item__web": !isInReportPreview},
                            {"state-of-residency-accordion-item__web-selected": !isProfileSOR}
                        )
                    }>
                    <span className={"span-1"}>{description}</span>
                    {isProfileSOR
                        ? <span className={"span-2"}>{stateOfResidency}</span>
                        : <span className={"span-2"}>
                            <StateOfResidencyInput listOfStates={listOfStates}
                                                   onSelected={event => NO_OP}
                                                   value={stateOfResidency}
                                                   panelWidth={"210px"}
                            /></span>
                    }

                    <span className={`span-3 textalign-right`}>
                        {displayEffectiveRate}
                    </span>
                </div>
            }
        </div>
    );
}