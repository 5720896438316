import DropdownMultiselect from './DropdownMultiselect'
import {Member} from '../EstateFlowchartTypes'
import {DraggableProvided} from 'react-beautiful-dnd'

type SuccessorTableRowProps = Readonly<{
    row: SuccessorRow,
    index: number,
    dropdownHeight: number,
    provided: DraggableProvided,
    onChangeSuccessor: (options: string[], index: number) => void,
    onDeleteSuccessor: (order: number) => void,
}>

type SuccessorRow = {
    order: number,
    name: string,
    successors: Member[],
    selectedSuccessors: string[],
}

function SuccessorTableRow({
                               row,
                               index,
                               dropdownHeight,
                               provided,
                               onChangeSuccessor,
                               onDeleteSuccessor
                           }: SuccessorTableRowProps): JSX.Element {
    return (
        <div className="layout-data-entry-form__field trust-details__successor-row"
             data-testid={`successor-trustees-dropdown-${index}`} key={index}
             ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <i className="dds-icons icon drag__icon" aria-hidden="true">drag_vertical</i>
            <DropdownMultiselect
                className={'dds-ms'}
                alignRight={false}
                creatable
                disabled={false}
                dropUp={false}
                dynamicDropAlign={false}
                dynamicDropDirection={false}
                hideSelectAll
                id="DDMS-creatable"
                label=""
                onChange={(options: string[]) => {
                    onChangeSuccessor(options, index)
                }}
                open={false}
                options={row.successors}
                panelHeight={dropdownHeight}
                panelWidth="100%"
                placeholder="Select or Add New"
                required={false}
                searchable
                selectAllLabel=""
                selected={row.selectedSuccessors}
                selectedDisplayTextAfter=""
                selectedDisplayTextBefore=""
                showTotalSelected={false}
                showClearAllButton={row.selectedSuccessors.length > 1}
                showFullSelected={true}
            />

            <i className="dds-icons icon paddingright-md paddingleft-md delete-successor-trustee"
               data-testid="successor-row-delete" onClick={() => onDeleteSuccessor(row.order)}
               aria-hidden="true">delete</i>
        </div>
    )
}

export default SuccessorTableRow