import React from "react";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";
import RiskLegend, {riskLegendColor} from "../../components/Legend/RiskLegend";
import RiskDonut from "../../ClientManagement/AssetAllocation/RiskDonut";
import {formatNumberRoundedToWholeNumber} from "../../utils/format";
import {COLOR_COMPARE_PLANS_EXCESS, COLOR_COMPARE_PLANS_SHORTFALL} from "../../constants/colors";

interface ComparePlansRiskDonutProps {
    comparePlan1PlanSummaryResponse: PlanSummaryResponse;
    comparePlan2PlanSummaryResponse: PlanSummaryResponse;
    isComparePlan2: Boolean;
    showPillText?: boolean;
    differenceInTotalRiskPercent?: number;
}

const ComparePlansRiskDonut: React.FC<ComparePlansRiskDonutProps> = ({
                                                                         comparePlan1PlanSummaryResponse,
                                                                         comparePlan2PlanSummaryResponse,
                                                                         isComparePlan2,
                                                                         showPillText,
                                                                         differenceInTotalRiskPercent
                                                                     }) => {
    return (
        <div>
            {!isComparePlan2 && (<div className={"risk-donut-container"}>

                <div className={"risk-donut-compare-plans"}>
                    <RiskDonut
                        donutSize={'xsm'}
                        data={comparePlan1PlanSummaryResponse!.proposedAllocation}
                    />
                </div>
                <div className={"risk-assets-compare-plans"}>
                    <div className={"risk-assets-and-risk-control"} style={{marginTop: '0px'}}>
                        <div className={"risk"}>
                            <div className={"risk-legend-label"}>
                                <RiskLegend legendColor={riskLegendColor.RISK_ASSETS} label={'RA'}/>
                                <span
                                    className={"risk-percentage"}><b>{` ${formatNumberRoundedToWholeNumber(comparePlan1PlanSummaryResponse!.proposedAllocation.totalRiskAssetsPercent)}%`}</b></span>

                            </div>
                        </div>
                        <div className={"risk"}>
                            <div className={"risk-legend-label"}>
                                <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}
                                            label={'RCA'}/>
                                <span
                                    className={"risk-percentage"}><b>{` ${formatNumberRoundedToWholeNumber(comparePlan1PlanSummaryResponse!.proposedAllocation.totalRiskControlAssetsPercent)}%`}</b></span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>)}

            {isComparePlan2 && comparePlan2PlanSummaryResponse != null && (
                <div className={"risk-donut-container"} style={{marginRight: "-15px"}}>
                    <div className={"risk-donut-compare-plans"}>
                        <RiskDonut
                            donutSize={'xsm'}
                            data={comparePlan2PlanSummaryResponse!.proposedAllocation}
                        />
                    </div>
                    <div className={"risk-assets-compare-plans"}>

                        <div className={"risk-assets-and-risk-control"} style={{marginTop: '0px'}}>
                            <div className={"risk"}>
                                <div className={"risk-legend-label"}>
                                    <RiskLegend legendColor={riskLegendColor.RISK_ASSETS} label={'RA'}/>
                                    <span
                                        className={"risk-percentage"}><b>{` ${formatNumberRoundedToWholeNumber(comparePlan2PlanSummaryResponse!.proposedAllocation.totalRiskAssetsPercent)}%`}</b></span>
                                    {showPillText && differenceInTotalRiskPercent !== undefined && differenceInTotalRiskPercent !== 0 &&
                                        <span
                                            className={differenceInTotalRiskPercent >= 0 ? "compare-plans-pill-container pill-container-excess position-absolute" : "compare-plans-pill-container pill-container-shortfall position-absolute"}
                                            style={{
                                                color: (differenceInTotalRiskPercent) >= 0 ? COLOR_COMPARE_PLANS_EXCESS : COLOR_COMPARE_PLANS_SHORTFALL,
                                            }}>
                                                <span
                                                    className="pill-text"> {`${differenceInTotalRiskPercent > 0 ? '+' : ''}${differenceInTotalRiskPercent}%`}</span>
                                            </span>
                                    }
                                </div>
                            </div>
                            <div className={"risk"}>
                                <div className={"risk-legend-label"}>
                                    <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}
                                                label={'RCA'}/>
                                    <span
                                        className={"risk-percentage"}><b>{` ${formatNumberRoundedToWholeNumber(comparePlan2PlanSummaryResponse!.proposedAllocation.totalRiskControlAssetsPercent)}%`}</b></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </div>
    );
}
export default ComparePlansRiskDonut;