import {
    PartnerDashboardProfile,
    ProfileRequest,
    ProfileResponse,
    ProspectRequest,
    ProspectResponse, SampleRequest, SampleResponse
} from "./models/ProfileResponse";
import {FamilyMemberForm} from "./models/MemberType";
import {FamilyTreeType} from "./models/FamilyTreeType";
import {get, HttpOptions, post, put, remove} from "../core/api";
import {InvestorGroupType, MemberGroup} from "./models/InvestorGroupType";
import {ProposalRequest} from "./models/ProposalRequest";
import {PartnerDashboardProfilesResponse} from "./models/PartnerDashboardProfilesResponse";
import {msalBrowserUtils} from "../MsalUtils";

const isSuccess: (response: Response) => boolean = ({status}) => status === 200 || status === 201;

const isNoContent: (response: Response) => boolean = ({status}) => status === 204;

const extractBody = (res: Response) => res.json();

export const getPartnerDashboardProfiles = (): Promise<PartnerDashboardProfilesResponse> => {
    return get('/client-management/partnerdashboard')
        .then(extractBody);
};

export const postProfile = (profile: ProfileRequest): Promise<ProfileResponse> => {
    return post('/client-management/profiles', profile)
        .then(extractBody);
}

export const postProspect = (prospect: ProspectRequest): Promise<ProspectResponse> => {
    return post('/client-management/prospects', prospect)
        .then(extractBody);
}

export const putProspect = (prospect: ProspectResponse): Promise<ProspectResponse> => {
    return put(`/client-management/prospects/${prospect.profile.id}`, prospect)
        .then(extractBody);
}

export const convertProspect = (profileId: string): Promise<ProspectResponse> => {
    return put(`/client-management/prospects/${profileId}/convert`, null)
        .then(extractBody);
}

export const postSample = (sample: SampleRequest): Promise<SampleResponse> => {
    return post(`/client-management/sample`, sample)
         .then(extractBody);
}

export const putSample = (sample: SampleResponse): Promise<SampleResponse> => {
    return put(`/client-management/sample/${sample.profile.id}`, sample)
        .then(extractBody);
}

export const putProfile = (profile: ProfileResponse): Promise<ProfileResponse> => {
    const updatedProfile = {...profile, lastModifiedByName: msalBrowserUtils.getActiveAccountName()}
    return put(`/client-management/profiles/${profile.id}`, updatedProfile)
        .then((response: Response) => {
            if (isSuccess(response)) {
                return response.json();
            } else {
                return undefined;
            }
        });
}

export const putPartnerDashboardProfile = (partnerDashboardProfile: PartnerDashboardProfile): Promise<PartnerDashboardProfile> => {
    return put(`/client-management/partnerdashboard/${partnerDashboardProfile.id}`, partnerDashboardProfile)
        .then(extractBody);
}

export const getProfile = (profileId: string): Promise<ProfileResponse> => {
    return get(`/client-management/profiles/${profileId}`)
        .then((response: Response) => {
            if (isSuccess(response)) {
                return extractBody(response);
            } else if (isNoContent(response)) {
                window.location.assign("/unavailable");
            }
        });
}

export const getFamily = (profileId: string): Promise<FamilyTreeType> => {
    return get(`/client-management/profiles/${profileId}/familytree`)
        .then(extractBody);
}

export const postNewFamilyMember = (member: FamilyMemberForm, profileId: string): Promise<boolean> => {
    return post(`/client-management/profiles/${profileId}/members`, member)
        .then(isSuccess);
}

export const editFamilyMember = (member: FamilyMemberForm, profileId: string): Promise<boolean> => {
    return put(`/client-management/profiles/${profileId}/members/${member.id}`, member)
        .then(isSuccess);
}

export const deleteFamilyMember = (memberId: string, profileId: string): Promise<boolean> => {
    return remove(`/client-management/profiles/${profileId}/members/${memberId}`)
        .then(isSuccess)
}

export const getInvestorGroup = (profileId: string, options?: HttpOptions): Promise<InvestorGroupType> => {
    const path = `/client-management/profiles/${profileId}/investorGroup`;
    const response = options !== undefined ? get(path, options) : get(path);
    return response.then(extractBody);
}

export const getMemberGroup = (profileId: string): Promise<MemberGroup> => {
    return get(`/client-management/external/profiles/${profileId}/members`)
        .then(extractBody);
}

export const getProposals = (profileId: string): Promise<ProfileResponse[]> => {
    return get(`/client-management/profiles/${profileId}/proposals`)
        .then(extractBody);
}

export const getArchivedProposals = (profileId: string): Promise<ProfileResponse[]> => {
    return get(`/client-management/profiles/${profileId}/proposals/archived`)
        .then(extractBody);
}

export const hasProposals = (profileId: string): Promise<boolean> => {
    return getProposals(profileId).then(proposals => proposals.length > 0);
}

export const putProposal = (profileId: string, proposalId: string, proposalRequest: ProposalRequest): Promise<ProfileResponse> => {
    return put(`/client-management/profiles/${profileId}/proposals/${proposalId}`, proposalRequest)
        .then(extractBody);
}

export const putAnnualReportSentDate = (profileId: string, annualReportSentDate: string): Promise<PartnerDashboardProfile> => {
    return put(`/client-management/partnerdashboard/${profileId}/${annualReportSentDate}`, null)
        .then(extractBody);
}

export const clientManagementApiClient = {
    postProfile,
    postProspect,
    putProspect,
    convertProspect,
    postSample,
    putSample,
    getFamily,
    postNewFamilyMember,
    editFamilyMember,
    deleteFamilyMember,
    getProfile,
    putProfile,
    getInvestorGroup,
    getMemberGroup,
    getProposals,
    getArchivedProposals,
    hasProposals,
    putProposal,
    getPartnerDashboardProfiles,
    putPartnerDashboardProfile,
    putAnnualReportSentDate,
};
