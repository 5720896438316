import React from "react";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {Dropdown, DropdownItem} from "../../components";
import {Beneficiaries, Trust, Trustee, TrustType} from "../EstateFlowchartTypes";

type AddTrustButtonDropdownProps = {
    className: string,
    updateBeneficiaryInformation: Function,
    beneficiariesInformation: Beneficiaries,
}

export const AddDistributionButtonDropdown: React.FC<AddTrustButtonDropdownProps> = ({
                                                                                         className,
                                                                                         updateBeneficiaryInformation,
                                                                                         beneficiariesInformation
                                                                                     }) => {
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const createTrust = (trustType: TrustType, beneficiaryId?: string): Trust => (
        {
            trustType: trustType,
            trustName: "",
            tags: "Exempt",
            trustees: [],
            successors: [],
            beneficiaries: [] as Trustee[],
            funding: "",
            distributions: "",
            termination: "",
            powersOfAppointment: [],
            beneficiaryId: beneficiaryId
        }
    );

    const updateBeneficiaryWithNewTrust = (trusts: Trust[]) => {
        updateBeneficiaryInformation({
            firstDeath: {
                trusts: trusts
            }
        });
    }

    function handleClickAddDistribution(trustType: TrustType) {
        if (beneficiariesInformation.firstDeath?.trusts && beneficiariesInformation.firstDeath.trusts.length > 0) {
            let trusts = [...beneficiariesInformation.firstDeath.trusts];
            trusts.push(createTrust(trustType, trusts[0].beneficiaryId))
            updateBeneficiaryWithNewTrust(trusts);
        } else {
            updateBeneficiaryWithNewTrust([createTrust(trustType)])
        }
    }

    const dropdownItemArray = () => {
        return [
            {
                itemText: "Credit Shelter Trust",
                value: "Credit Shelter Trust",
                onClick: () => {
                    handleClickAddDistribution(TrustType.creditShelter);
                },
            },
            {
                itemText: "In Trust",
                value: "In Trust",
                onClick: () => {
                    handleClickAddDistribution(TrustType.intrust);
                },
            },
            {
                itemText: "Marital Trust",
                value: "Marital Trust",
                onClick: () => {
                    handleClickAddDistribution(TrustType.marital);
                },
            },
            {
                itemText: "Survivor's Trust",
                value: "Survivor Trust",
                onClick: () => {
                    handleClickAddDistribution(TrustType.survivor);
                },
            }
        ]
    };

    return <Dropdown
        className={className}
        alignRight={false}
        buttonIcon="left"
        buttonKind="primary"
        dropdownKind="menu"
        defaultText="Add Distribution"
        iconNameOpen="add"
        iconNameClose="add"
        panelWidth={245}
        rounded
        disabled={isProfileWithProposalsOrArchived}
    >
        {dropdownItemArray().map((item: any, i: number) =>
            <DropdownItem key={i}
                          itemText={item.itemText}
                          value={item.value}
                          onClick={item.onClick}
                          disabled={item.disabled}
            />)}
    </Dropdown>;

};