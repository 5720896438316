import React, {useState} from "react";
import {Button, UnderlinedHeader} from "src/components";
import PowerOfAppointmentRow from "./PowersOfAppointmentRow";
import {Member, PoaType, PowerOfAppointment, TransferType} from "../EstateFlowchartTypes";

type PowersOfAppointmentSectionProps = {
    powersOfAppointment: PowerOfAppointment[]
    updateTrust: (newPowersOfAppointments: PowerOfAppointment[]) => void,
    familyMembers: Member[],
    trustIndex: number,
    saveHasBeenClicked: boolean,
    isValid: any,
}
export default function PowersOfAppointmentSection({
                                                       powersOfAppointment,
                                                       updateTrust,
                                                       familyMembers,
                                                       trustIndex,
                                                       saveHasBeenClicked,
                                                       isValid
                                                   }: Readonly<PowersOfAppointmentSectionProps>): JSX.Element {

    const [powersOfAppointments, setPowersOfAppointments] = useState<PowerOfAppointment[]>(powersOfAppointment);

    const onAddPowerOfAppointment = () => {
        const numberOfPoa = powersOfAppointments.length;
        const newPowerOfAppointment: PowerOfAppointment = {
            type: PoaType.Limited,
            transferType: TransferType.Testamentary,
            powerHolder: "",
            permissibleAppointees: "",
            additionalDetails: "",
            poaOrder: numberOfPoa,
        }
        const updatedListOfPowersOfAppointment = [...powersOfAppointments, newPowerOfAppointment];
        setPowersOfAppointments(updatedListOfPowersOfAppointment);
        updateTrust(updatedListOfPowersOfAppointment);
    }

    const onChangePowerOfAppointment = (index: number, updatedPowerOfAppointment: PowerOfAppointment) => {
        const updatedPowersOfAppointment = powersOfAppointments.map((existingPowerOfAppointment, i) =>
            i === index ? updatedPowerOfAppointment : existingPowerOfAppointment);
        setPowersOfAppointments(updatedPowersOfAppointment);
        updateTrust(updatedPowersOfAppointment);
    }

    return (
        <section className="powers-of-appointment">
            <UnderlinedHeader className={"powers-of-appointment-header"}
                              leftAlignedContent={
                                  <h4>Powers of Appointment</h4>
                              }
                              rightAlignedContent={
                                  <Button
                                      className={"add-powers-of-appointment-button"}
                                      onClick={() => {
                                          onAddPowerOfAppointment()
                                      }}
                                      icon="left"
                                      iconName="add"
                                      kind="borderless"
                                  >
                                      ADD POWERS OF APPOINTMENT
                                  </Button>
                              }
            />

            {powersOfAppointments.length > 0 ?
                powersOfAppointments.map((powerOfAppointmentForm, index) => (
                    <PowerOfAppointmentRow
                        key={index}
                        index={index}
                        trustIndex={trustIndex}
                        powerOfAppointmentForm={powerOfAppointmentForm}
                        onChangePOA={(updatedPOA: PowerOfAppointment) => {
                            onChangePowerOfAppointment(index, updatedPOA);
                        }}
                        familyMembers={familyMembers}
                        saveHasBeenClicked={saveHasBeenClicked}
                        isValid={isValid}
                    />
                )) : null}

            {powersOfAppointments.length > 0 ? (
                <div className={"powers-of-appointment-bottom-button"}>

                    <Button
                        className={"add-powers-of-appointment-button"}
                        onClick={() => {
                            onAddPowerOfAppointment();
                        }}
                        icon="left"
                        iconName="add"
                        kind="borderless"
                    >
                        ADD POWERS OF APPOINTMENT
                    </Button>
                </div>
            ) : null}


        </section>

    )
}