import React from "react";
import {formatCurrencyWithSuffix} from "../../utils/format";

interface PillTextComponentProps {
    pillTextValue: number;
    position?: string;
    color: string;
    className?: string;
}

const PillTextComponent: React.FC<PillTextComponentProps> = ({pillTextValue, color, className}) => {

    return (
        <span
            className={className}
            style={{
                color: color,
            }}>
            <span className="pill-text"> {formatCurrencyWithSuffix(pillTextValue)}</span>
        </span>
    )
}

export default PillTextComponent;