import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../routes/types";
import {emptyInvestorGroup, InvestorGroupType} from "../ClientManagement/models/InvestorGroupType";
import {AssetsSummary, emptyAssetsSummary} from "../Assets/models/Assets";
import {selectClientAssets, setClientAssets} from "../Assets/clientAssetsSlice";
import {emptyEstateSummary, EstateSummary, EstateType} from "../WealthTransfer/models/api";
import {useSelector} from "react-redux";
import {selectDifferenceBetweenPlans,} from "../Plans/CompareScenarios/ComparePlansSlice";
import {PlanSummaryResponse} from "../Plans/models/PlanSummaryResponse";
import {getPlanSummary} from "../Plans/PlanSummary/planSummarySlice";
import {clientManagementApiClient} from "../ClientManagement/ClientManagementApiClient";
import {assetsApiClient} from "../Assets/AssetsApiClient";
import {wealthTransferApiClient} from "../WealthTransfer/WealthTransferApiClient";
import LoadingIndicator from "../pages/LoadingIndicator";
import {formatCurrency, formatNumberRoundedToWholeNumber, roundUpToNext, truncateCurrency} from "../utils/format";
import BarChartLegend, {barChartLegend} from "../components/Legend/BarChartLegend";
import {calculateCNWInEstateTotalValue} from "../Assets/AssetSummary/common/AssetSummaryCalculator";
import {
    CreatePlanSummaryNetWorthOverTimeGraphOptions
} from "../Plans/PlanSummary/PlanSummaryNetWorthOverTimeGraphUtils";
import Highcharts from "highcharts";
import {PresentationPaneHeader} from "../components";
import _ from "lodash";
import ChartStatusLine from "../ClientManagement/AssetReliance/ChartStatusLine";
import {COLOR_COMPARE_PLANS_EXCESS, COLOR_COMPARE_PLANS_SHORTFALL, COLOR_NT_GREY} from "../constants/colors";
import {ComparePlansBarchart} from "../Plans/CompareScenarios/ComparePlansBarchart";
import PillTextComponent from "../Plans/CompareScenarios/PillTextComponent";
import {Col, Row} from "react-grid-system";
import NetWorthOverTimeRow from "../Plans/CompareScenarios/NetWorthOverTimeRow";
import ComparePlansRiskDonut from "../Plans/CompareScenarios/ComparePlansRiskDonut";
import ReserveTargetLengthRow from "../Plans/CompareScenarios/ReserveTargetLengthRow";
import EstimatedEstateTaxRow from "../Plans/CompareScenarios/EstimatedEstateTaxRow";
import PrintViewWrapper from "./DetailedEmailReports/PrintViewWrapper";
import {resourcesApiClient} from "../Resources/ResourcesApiClient";
import {displayName} from "react-quill";
import ScrollableContainer from "../components/ScrollableContainer/ScrollableContainer";

const ComparePlansWrapper = () => {
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [comparePlan1investorGroup, setComparePlan1InvestorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);
    const [comparePlan2InvestorGroup, setComparePlan2InvestorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);
    const clientAssets: AssetsSummary | undefined = useAppSelector(selectClientAssets);
    const [comparePlan1SelectedId, setComparePlan1SelectedId] = useState<string>();
    const [comparePlan2SelectedId, setComparePlan2SelectedId] = useState<string | null>(null);
    const [comparePlan1SummaryResponse, setComparePlan1SummaryResponse] = useState<PlanSummaryResponse>();
    const [comparePlan2SummaryResponse, setComparePlan2SummaryResponse] = useState<PlanSummaryResponse | null>(null);
    const [comparePlan2ClientAssets, setComparePlan2ClientAssets] = useState<AssetsSummary | undefined>(emptyAssetsSummary);
    const [comparePlan1PortfolioReserveTargetLength, setComparePlan1PortfolioReserveTargetLength] = useState<number | undefined>(undefined);
    const [comparePlan2PortfolioReserveTargetLength, setComparePlan2PortfolioReserveTargetLength] = useState<number | undefined>(undefined);
    const [comparePlan1EstateSummary, setComparePlan1EstateSummary] = useState<EstateSummary>(emptyEstateSummary);
    const [comparePlan2EstateSummary, setComparePlan2EstateSummary] = useState<EstateSummary>(emptyEstateSummary);
    const showDeltaBetweenPlans = useSelector(selectDifferenceBetweenPlans);
    const [comparePlan1DisplayName, setComparePlan1DisplayName] = useState<string>("");
    const [comparePlan2DisplayName, setComparePlan2DisplayName] = useState<string>("");

    const pages: number[] = [1, 2];

    const fetchData = useCallback(async (proposalId: string): Promise<PlanSummaryResponse | undefined> => {
        setIsLoading(true);
        try {
            const response = await dispatch(getPlanSummary(proposalId));
            if (response.payload) {
                const [newInvestorGroup, assetSummaryResponse, estateSummaryResponse, profileResponse]
                    = await Promise.all([clientManagementApiClient.getInvestorGroup(proposalId),
                    assetsApiClient.getAssetsSummary(proposalId),
                    wealthTransferApiClient.getEstateSummary(proposalId, EstateType.CURRENT),
                    clientManagementApiClient.getProfile(proposalId)]);
                setComparePlan1InvestorGroup(newInvestorGroup);
                dispatch(setClientAssets(assetSummaryResponse));
                setComparePlan1EstateSummary(estateSummaryResponse);
                setComparePlan1DisplayName(profileResponse.displayName)
                setComparePlan1PortfolioReserveTargetLength(profileResponse.portfolioReserveTargetLength);
                setComparePlan1SelectedId(proposalId);
                setComparePlan1SummaryResponse(response.payload as PlanSummaryResponse);
                setIsLoading(false);
                return response.payload as PlanSummaryResponse;
            } else {
                console.error("No payload in response");
            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
        return undefined;
    }, [dispatch]);

    useEffect(() => {
        let isMounted = true;
        const fetchComparePlanIds = async () => {
            try {
                const comparePlanResponse = await resourcesApiClient.getComparePlan(id);
                if (isMounted) {
                    setComparePlan1SelectedId(prevId => {
                        if (prevId !== comparePlanResponse.comparePlan1SelectedId) {
                            return comparePlanResponse.comparePlan1SelectedId || id;
                        }
                        return prevId;
                    });
                    setComparePlan2SelectedId(prevId => {
                        if (prevId !== comparePlanResponse.comparePlan2SelectedId) {
                            return comparePlanResponse.comparePlan2SelectedId || null;
                        }
                        return prevId;
                    });
                }
            } catch (error) {
                if (isMounted) {
                    setComparePlan1SelectedId(id);
                    setComparePlan2SelectedId(null);
                    setComparePlan2SummaryResponse(null);
                }
            }
        };

        fetchComparePlanIds();

        return () => {
            isMounted = false;
        };
    }, [id]);

    useEffect(() => {
        if (comparePlan1SelectedId) {
            fetchData(comparePlan1SelectedId);
        }
    }, [comparePlan1SelectedId, fetchData]);

    useEffect(() => {
        let isMounted = true;
        (async () => {
            if (comparePlan2SelectedId) {
                setIsLoading(true);
                const response = await dispatch(getPlanSummary(comparePlan2SelectedId));
                if (isMounted && response.payload) {
                    const [newInvestorGroup, assetSummaryResponse, estateSummaryResponse, proposalResponse] = await Promise.all([
                        clientManagementApiClient.getInvestorGroup(comparePlan2SelectedId),
                        assetsApiClient.getAssetsSummary(comparePlan2SelectedId),
                        wealthTransferApiClient.getEstateSummary(comparePlan2SelectedId, EstateType.CURRENT),
                        clientManagementApiClient.getProfile(comparePlan2SelectedId)
                    ]);
                    setComparePlan2InvestorGroup(newInvestorGroup);
                    setComparePlan2ClientAssets(assetSummaryResponse);
                    setComparePlan2EstateSummary(estateSummaryResponse);
                    setComparePlan2DisplayName(proposalResponse.displayName)
                    setComparePlan2PortfolioReserveTargetLength(proposalResponse.portfolioReserveTargetLength);
                    setComparePlan2SummaryResponse(response.payload as PlanSummaryResponse);
                    setComparePlan2SelectedId(comparePlan2SelectedId);
                }
                setIsLoading(false);
            }
        })();
        return () => {
            isMounted = false;
        };
    }, [comparePlan2SelectedId, dispatch]);

    const netAssetsComparePlan1 = useMemo(() => {
        return (comparePlan1SummaryResponse?.totalNetValue ?? 0) + (comparePlan1SummaryResponse?.totalAssetPurchaseValue ?? 0);
    }, [comparePlan1SummaryResponse]);

    const netAssetsComparePlan2 = useMemo(() => {
        return (comparePlan2SummaryResponse?.totalNetValue ?? 0) + (comparePlan2SummaryResponse?.totalAssetPurchaseValue ?? 0);
    }, [comparePlan2SummaryResponse]);

    const totalGoalsComparePlan1 = useMemo(() => comparePlan1SummaryResponse?.goalsTotalPresentValue ?? 0, [comparePlan1SummaryResponse]);
    const totalGoalsComparePlan2 = useMemo(() => comparePlan2SummaryResponse?.goalsTotalPresentValue ?? 0, [comparePlan2SummaryResponse]);

    const excessAssetsComparePlan1 = useMemo(() => comparePlan1SummaryResponse?.excessAssets ?? 0, [comparePlan1SummaryResponse]);
    const excessAssetsComparePlan2 = useMemo(() => comparePlan2SummaryResponse?.excessAssets ?? 0, [comparePlan2SummaryResponse]);

    const differenceInNetAssets = useMemo(() => netAssetsComparePlan2 - netAssetsComparePlan1, [netAssetsComparePlan1, netAssetsComparePlan2]);
    const differenceInTotalGoals = useMemo(() => totalGoalsComparePlan2 - totalGoalsComparePlan1, [totalGoalsComparePlan1, totalGoalsComparePlan2]);
    const differenceInExcessAssets = useMemo(() => excessAssetsComparePlan2 - excessAssetsComparePlan1, [excessAssetsComparePlan1, excessAssetsComparePlan2]);


    if (isLoading || comparePlan1SummaryResponse === undefined) {
        return <LoadingIndicator/>
    }
    const calculateDifference = (value1: number, value2: number) => {
        return value2 - value1;
    };
    let totalRiskAssetsPercentPlan1 = comparePlan1SummaryResponse?.proposedAllocation.totalRiskAssetsPercent ?? 0;
    totalRiskAssetsPercentPlan1 = Number(formatNumberRoundedToWholeNumber(totalRiskAssetsPercentPlan1));
    let totalRiskAssetsPercentPlan2 = comparePlan2SummaryResponse?.proposedAllocation.totalRiskAssetsPercent ?? 0;
    totalRiskAssetsPercentPlan2 = Number(formatNumberRoundedToWholeNumber(totalRiskAssetsPercentPlan2));
    const differenceInTotalRiskPercent = calculateDifference(totalRiskAssetsPercentPlan1, totalRiskAssetsPercentPlan2)
    const differenceInPortfolioReserveTargetLength = calculateDifference(comparePlan1PortfolioReserveTargetLength!, comparePlan2PortfolioReserveTargetLength!)

    const isEmptyState = comparePlan2SummaryResponse == null;

    const differenceInEstimatedEstateTax = calculateDifference(
        comparePlan1EstateSummary.estimatedEstateTax,
        comparePlan2EstateSummary.estimatedEstateTax
    );

    const futureValueOfExcessAssetsComparePlan1 = comparePlan1SummaryResponse?.futureValueOfExcessAssets ?? 0;
    const futureValueOfExcessAssetsComparePlan2 = comparePlan2SummaryResponse?.futureValueOfExcessAssets ?? 0;

    const chartLegend =
        <div>
            <div className="compare-plans-sub-header">Excess Assets</div>
            <div className="bar-chart-legend-container">
                <div className="asset-reliance-barchart-legend-label">
                    <BarChartLegend legend={barChartLegend.NET_ASSETS} label={"ASSETS"}/>
                </div>
                <div className="asset-reliance-barchart-legend-label">
                    <BarChartLegend legend={barChartLegend.GOALS} label={"GOALS"}/>
                </div>
                {(excessAssetsComparePlan1 < 0 || excessAssetsComparePlan2 < 0) &&
                    <div className="asset-reliance-barchart-legend-label">
                        <BarChartLegend legend={barChartLegend.ASSET_SHORTFALL} label={"ASSET SHORTFALL"}/>
                    </div>
                }
                {(excessAssetsComparePlan1 > 0 || excessAssetsComparePlan2 > 0) &&
                    <div className="asset-reliance-barchart-legend-label">
                        <BarChartLegend legend={barChartLegend.EXCESS_ASSETS} label={"EXCESS ASSETS"}/>
                    </div>
                }
            </div>
        </div>;

    const minimumValueToApplyGraphRounding = 1_000_000;
    const getMaxValue = (value1: number, value2: number) => Math.max(value1, value2);
    const getChartUnderlayMaxValue = (underLayValue: number) =>
        underLayValue >= minimumValueToApplyGraphRounding ? roundUpToNext(underLayValue, 5) : minimumValueToApplyGraphRounding;

    const calculateNetWealthAtCurrentAge = (assets: AssetsSummary, totalTaxLiabilities: number) =>
        calculateCNWInEstateTotalValue(assets) + totalTaxLiabilities;

    const createGraphOptions = (investorGroup: InvestorGroupType, netWealthAtCurrentAge: number, futureValueOfExcessAssetsAtAge: any, disableAnimation: boolean) =>
        CreatePlanSummaryNetWorthOverTimeGraphOptions({
            investorGroup,
            netWealthAtCurrentAge,
            futureValueOfExcessAssetsAtAge,
        }, disableAnimation);

    const barchartMaxValueComparePlan1 = getMaxValue(totalGoalsComparePlan1, netAssetsComparePlan1);
    const barchartMaxValueComparePlan2 = getMaxValue(totalGoalsComparePlan2, netAssetsComparePlan2);
    const chartUnderlayMaxValueComparePlans = getChartUnderlayMaxValue(getMaxValue(barchartMaxValueComparePlan1, barchartMaxValueComparePlan2));
    const totalTaxLiabilitiesForAllAccounts = clientAssets!.totalTaxLiabilities.totalPresentTaxLiabilityForAllAccounts ?? 0;
    const comparePlan1NetWealthAtCurrentAge = calculateNetWealthAtCurrentAge(clientAssets!, totalTaxLiabilitiesForAllAccounts);
    const comparePlan1GraphOptions: Highcharts.Options = createGraphOptions(
        comparePlan1investorGroup,
        comparePlan1NetWealthAtCurrentAge,
        comparePlan1SummaryResponse!.futureValueOfExcessAssetsByAge,
        true
    );
    const comparePlan2TotalTaxLiabilitiesForAllAccounts = comparePlan2ClientAssets!.totalTaxLiabilities.totalPresentTaxLiabilityForAllAccounts ?? 0;
    const comparePlan2NetWealthAtCurrentAge = calculateNetWealthAtCurrentAge(comparePlan2ClientAssets!, comparePlan2TotalTaxLiabilitiesForAllAccounts);
    const comparePlan2GraphOptions: Highcharts.Options = createGraphOptions(
        comparePlan2InvestorGroup,
        comparePlan2NetWealthAtCurrentAge,
        comparePlan2SummaryResponse?.futureValueOfExcessAssetsByAge ?? [],
        true
    );

    const renderChartStatusLines = (barChartColWidth: number, chartUnderlayMaxValue: number) => {
        return _.range(6).map((index) => {
            const verticalOffset = `${(index * 20)}`;
            const lineWidth = (2 <= 1 ? 6 : 2) * barChartColWidth;
            const labelNumber = truncateCurrency((chartUnderlayMaxValue * ((5 - index) * 0.2)), {
                formatAsCurrency: false,
                includeUnits: true,
                maxValue: chartUnderlayMaxValue
            });
            return (
                <div key={index}>
                    <ChartStatusLine
                        id={'barChartUnderlay'}
                        label={`${labelNumber}`}
                        height={'1px'}
                        zIndex={0}
                        width={`calc(${lineWidth}px + 100%)`}
                        lineColor={COLOR_NT_GREY}
                        verticalOffset={verticalOffset}
                        marginLeft={0}
                        textColor={''}
                        isAssetReliance={true}
                    />
                </div>
            );
        });
    };

    const renderComparePlansBarchart = (netAssets: number, totalGoals: number, excessAssets: number, chartUnderlayMaxValue: number) => {
        return (
            <ComparePlansBarchart
                totalStacks={2}
                netAssets={netAssets}
                totalGoals={totalGoals}
                excessAssets={excessAssets}
                chartUnderlayMaxValue={chartUnderlayMaxValue}
            />
        );
    };

    const renderComparePlansContent = (comparePlanSummaryResponse: PlanSummaryResponse | null, netAssetsComparePlan: number, totalGoalsComparePlan: number, excessAssetsComparePlan: number, chartUnderlayMaxValuePlan: number) => {
        return comparePlanSummaryResponse ? (
            renderComparePlansBarchart(netAssetsComparePlan, totalGoalsComparePlan, excessAssetsComparePlan, chartUnderlayMaxValuePlan)
        ) : (
            <div className='compare-plans-empty-state'></div>
        );
    };

    const renderPillTextComponent = (differenceValue: number) => {
        const color = differenceValue >= 0 ? COLOR_COMPARE_PLANS_EXCESS : COLOR_COMPARE_PLANS_SHORTFALL;
        const className = differenceValue >= 0 ? "compare-plans-pill-container pill-container-excess" : "compare-plans-pill-container pill-container-shortfall";

        return (
            <PillTextComponent
                color={color}
                pillTextValue={differenceValue}
                className={className}
            />
        );
    };

    const getExcessAssetsClassName = (excessAssets1: number, excessAssets2: number) => {
        return (excessAssets1 < 0 && excessAssets2 <= 0) ? "shortfall-accent" : "excess-accent";
    };

    const renderTableContent = (
        comparePlanContent: number,
        showDelta: boolean,
        difference: number
    ) => {
        return (
            <div>
                {formatCurrency(comparePlanContent)}
                {showDelta && difference != 0 && renderPillTextComponent(difference)}
            </div>
        );
    };

    const getClassName = (excessAssetsComparePlanValue: number) => {
        return excessAssetsComparePlanValue < 0 ? 'accordion-column negative-difference' : 'accordion-column';
    };
    return (
        <>
            {pages.map((pageNumber) => {
                return (
                    <PrintViewWrapper displayName={displayName} key={pageNumber}>
                        <ScrollableContainer id={'compare-plan-page-scroll-container'} className="funding-page">
                            <PresentationPaneHeader className={`compare-plan__header`}
                                                    displayName={""}
                                                    title={`Compare Plans${pageNumber > 1 ? ' (continued)' : ''}`}
                            />

                            <main className="compare-plans-content">
                                {pageNumber === 1 &&
                                    <div>
                                        <Row className={"row-styling"} style={{paddingRight: '40px'}}>
                                            <div className="compare-plan-row-container" style={{paddingBottom: "15px"}}>
                                                <Col width={TITLE_CELL_WIDTH} style={{paddingLeft: '0'}}>
                                                    {chartLegend}
                                                </Col>
                                                <span className="divider"></span>
                                                <Col md={CHART_TITLE_CELL_WIDTH} style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}>
                                                    <div
                                                        className={"static-dropdown-header"}> {comparePlan1DisplayName} </div>
                                                </Col>
                                                {comparePlan2SelectedId && (
                                                    <>
                                                        <span className="divider"></span>
                                                        <Col md={CHART_TITLE_CELL_WIDTH} style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}>
                                                            <div
                                                                className={"static-dropdown-header"}> {comparePlan2DisplayName} </div>
                                                        </Col>
                                                    </>
                                                )}
                                            </div>
                                        </Row>
                                        <Row className={"row-styling"} style={{height: '150px'}}>
                                            <div className={"compare-plan-row-container"}>
                                                <Col style={{width: '374px', flex: 'unset'}}>
                                                    {renderChartStatusLines(0, chartUnderlayMaxValueComparePlans)}
                                                </Col>
                                                <span className={"divider"}></span>
                                                <Col md={CHART_TITLE_CELL_WIDTH}>
                                                    {renderComparePlansBarchart(netAssetsComparePlan1, totalGoalsComparePlan1, excessAssetsComparePlan1, chartUnderlayMaxValueComparePlans)}
                                                </Col>
                                                <span className={"divider"}></span>
                                                <Col md={CHART_TITLE_CELL_WIDTH}>
                                                    {renderComparePlansContent(comparePlan2SummaryResponse, netAssetsComparePlan2, totalGoalsComparePlan2, excessAssetsComparePlan2, chartUnderlayMaxValueComparePlans)}
                                                </Col>
                                            </div>
                                        </Row>
                                        <Row className={"row-styling-accordion"} style={{marginTop: "40px"}}>
                                            <div className={"compare-plan-row-container"}>
                                                <Col className={"accordion-header"} width={TITLE_CELL_WIDTH}
                                                     style={{paddingLeft: 0, borderLeft: "15px solid #3b9fa5"}}>
                                                    Net Assets
                                                </Col>

                                                <Col md={CHART_TITLE_CELL_WIDTH} className={"accordion-column"}>
                                                    {formatCurrency(netAssetsComparePlan1)}
                                                </Col>
                                                <Col md={CHART_TITLE_CELL_WIDTH}
                                                     className={!isEmptyState ? "accordion-column" : ""}>
                                                    {comparePlan2SummaryResponse ? renderTableContent(
                                                            netAssetsComparePlan2, showDeltaBetweenPlans, differenceInNetAssets) :
                                                        <div style={{border: "noBorder !important"}}></div>}
                                                </Col>
                                            </div>
                                        </Row>

                                        <Row className={"row-styling-accordion"}>
                                            <div className={"compare-plan-row-container"}>
                                                <Col className={"accordion-header"} width={TITLE_CELL_WIDTH}
                                                     style={{paddingLeft: 0, borderLeft: "15px solid #F1B040"}}>
                                                    Goals
                                                </Col>
                                                <Col md={CHART_TITLE_CELL_WIDTH} className={"accordion-column"}>
                                                    {formatCurrency(totalGoalsComparePlan1)}
                                                </Col>
                                                <Col md={CHART_TITLE_CELL_WIDTH}
                                                     className={!isEmptyState ? "accordion-column" : ""}>
                                                    {comparePlan2SummaryResponse ? renderTableContent(
                                                            totalGoalsComparePlan2, showDeltaBetweenPlans, differenceInTotalGoals) :
                                                        <div style={{border: "noBorder !important"}}></div>}
                                                </Col>
                                            </div>
                                        </Row>
                                        <Row className={"row-styling-accordion"}>
                                            <div className={"compare-plan-row-container"}>
                                                <Col width={TITLE_CELL_WIDTH} style={{paddingLeft: 0}}
                                                     className={getExcessAssetsClassName(excessAssetsComparePlan1, excessAssetsComparePlan2)}>
                                                    {(excessAssetsComparePlan1 < 0 && excessAssetsComparePlan2 <= 0) ? 'Asset Shortfall' : 'Excess Assets'}
                                                </Col>
                                                <Col md={CHART_TITLE_CELL_WIDTH}
                                                     className={getClassName(excessAssetsComparePlan1)}>
                                                    {formatCurrency(excessAssetsComparePlan1)}
                                                </Col>
                                                <Col md={CHART_TITLE_CELL_WIDTH}
                                                     className={!isEmptyState ? getClassName(excessAssetsComparePlan2) : ""}>
                                                    {comparePlan2SummaryResponse ? renderTableContent(
                                                            excessAssetsComparePlan2, showDeltaBetweenPlans, differenceInExcessAssets) :
                                                        <div style={{border: "noBorder !important"}}></div>}
                                                </Col>
                                            </div>
                                        </Row>
                                    </div>
                                }
                                {pageNumber === 2 &&
                                    <div>
                                        <Row className={"row-styling"} style={{paddingRight: '40px'}}>
                                            <div className="compare-plan-row-container" style={{paddingBottom: "15px"}}>
                                                <Col width={TITLE_CELL_WIDTH} style={{paddingLeft: '0'}}>
                                                </Col>
                                                <span className="divider"></span>
                                                <Col md={CHART_TITLE_CELL_WIDTH} style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}>
                                                    <div
                                                        className={"static-dropdown-header"}> {comparePlan1DisplayName} </div>
                                                </Col>
                                                {comparePlan2SelectedId && (
                                                    <>
                                                        <span className="divider"></span>
                                                        <Col md={CHART_TITLE_CELL_WIDTH} style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}>
                                                            <div
                                                                className={"static-dropdown-header"}> {comparePlan2DisplayName} </div>
                                                        </Col>
                                                    </>
                                                )}
                                            </div>
                                        </Row>
                                        <Row className={"row-styling"}>
                                            <div className="compare-plan-row-container">
                                                <Col width={TITLE_CELL_WIDTH} style={{paddingLeft: 0}}>
                                                    <div className="compare-plans-sub-header">Net Worth Over Time</div>
                                                    <div className='subhead'>Expected excess at the end of the planning
                                                        period
                                                    </div>
                                                </Col>
                                                <span className="divider"></span>
                                                <Col md={CHART_TITLE_CELL_WIDTH}>
                                                    <NetWorthOverTimeRow
                                                        comparePlan1PlanSummaryResponse={comparePlan1SummaryResponse}
                                                        comparePlan2PlanSummaryResponse={comparePlan2SummaryResponse!}
                                                        comparePlan1GraphOptions={comparePlan1GraphOptions}
                                                        comparePlan2GraphOptions={comparePlan2GraphOptions}
                                                        isComparePlan2={false}
                                                    />
                                                </Col>
                                                <span className="divider"></span>

                                                <Col md={CHART_TITLE_CELL_WIDTH}>
                                                    <NetWorthOverTimeRow
                                                        comparePlan1PlanSummaryResponse={comparePlan1SummaryResponse}
                                                        comparePlan2PlanSummaryResponse={comparePlan2SummaryResponse!}
                                                        comparePlan1GraphOptions={comparePlan1GraphOptions}
                                                        comparePlan2GraphOptions={comparePlan2GraphOptions}
                                                        differenceInFutureValueOfExcessAssets={(futureValueOfExcessAssetsComparePlan2 - futureValueOfExcessAssetsComparePlan1)}
                                                        isComparePlan2={true}
                                                        showPillText={showDeltaBetweenPlans}
                                                    />
                                                </Col>
                                            </div>
                                        </Row>
                                        <Row className={"row-styling"}>
                                            <div className="compare-plan-row-container">
                                                <Col className={"col-visual-borders"} width={TITLE_CELL_WIDTH}
                                                     style={{paddingLeft: 0}}>
                                                    <div className="compare-plans-sub-header">Proposed Asset Allocation
                                                    </div>
                                                    <div className='subhead'>Total Portfolio</div>
                                                </Col>
                                                <span className="divider"></span>
                                                <Col className={"col-visual-borders"} md={CHART_TITLE_CELL_WIDTH}>
                                                    <ComparePlansRiskDonut
                                                        comparePlan1PlanSummaryResponse={comparePlan1SummaryResponse}
                                                        comparePlan2PlanSummaryResponse={comparePlan2SummaryResponse!}
                                                        isComparePlan2={false}
                                                    />
                                                </Col>
                                                <span className="divider"></span>
                                                <Col className={!isEmptyState ? "col-visual-borders" : ""}
                                                     md={CHART_TITLE_CELL_WIDTH}>
                                                    <ComparePlansRiskDonut
                                                        comparePlan1PlanSummaryResponse={comparePlan1SummaryResponse}
                                                        comparePlan2PlanSummaryResponse={comparePlan2SummaryResponse!}
                                                        isComparePlan2={true}
                                                        differenceInTotalRiskPercent={differenceInTotalRiskPercent}
                                                        showPillText={showDeltaBetweenPlans}
                                                    />
                                                </Col>
                                            </div>
                                        </Row>
                                        <Row className={"row-styling"}>
                                            <div className={"compare-plan-row-container"}>
                                                <Col className={"col-visual-borders"} width={TITLE_CELL_WIDTH}
                                                     style={{paddingLeft: 0}}>
                                                    <div className="compare-plans-sub-header"
                                                         style={{marginTop: 15}}>Portfolio
                                                        Reserve Target
                                                        Length
                                                    </div>
                                                </Col>
                                                <span className="divider"></span>
                                                <Col className={"col-visual-borders"} md={CHART_TITLE_CELL_WIDTH}>
                                                    <ReserveTargetLengthRow
                                                        comparePlan1PortfolioReserveTargetLength={comparePlan1PortfolioReserveTargetLength}
                                                        comparePlan2PortfolioReserveTargetLength={comparePlan2PortfolioReserveTargetLength}
                                                        comparePlan2PlanSummaryResponse={comparePlan2SummaryResponse!}
                                                        isComparePlan2={false}
                                                    />
                                                </Col>
                                                <span className="divider"></span>
                                                <Col className={!isEmptyState ? "col-visual-borders" : ""}
                                                     md={CHART_TITLE_CELL_WIDTH}>
                                                    <ReserveTargetLengthRow
                                                        comparePlan1PortfolioReserveTargetLength={comparePlan1PortfolioReserveTargetLength}
                                                        comparePlan2PortfolioReserveTargetLength={comparePlan2PortfolioReserveTargetLength}
                                                        comparePlan2PlanSummaryResponse={comparePlan2SummaryResponse!}
                                                        isComparePlan2={true}
                                                        showPillText={showDeltaBetweenPlans}
                                                        differenceInPortfolioReserveTargetLength={differenceInPortfolioReserveTargetLength}
                                                    />
                                                </Col>
                                            </div>
                                        </Row>
                                        <Row className={"row-styling"}>
                                            <div className={"compare-plan-row-container"}>
                                                <Col className={"col-visual-borders"} width={TITLE_CELL_WIDTH}
                                                     style={{paddingLeft: 0}}>
                                                    <div className="compare-plans-sub-header"
                                                         style={{marginTop: 15}}>Estimated
                                                        Estate Tax
                                                    </div>
                                                </Col>
                                                <span className="divider"></span>
                                                <Col className={"col-visual-borders"} md={CHART_TITLE_CELL_WIDTH}>
                                                    <EstimatedEstateTaxRow
                                                        ComparePlan1EstimatedEstateTax={comparePlan1EstateSummary.estimatedEstateTax}
                                                        ComparePlan2PlanSummaryResponse={comparePlan2SummaryResponse!}
                                                        ComparePlan2EstimatedEstateTax={comparePlan2EstateSummary.estimatedEstateTax}
                                                        DifferenceInEstimatedEstateTax={differenceInEstimatedEstateTax}
                                                        isComparePlan2={false}
                                                    />
                                                </Col>
                                                <span className="divider"></span>
                                                <Col className={!isEmptyState ? "col-visual-borders" : ""}
                                                     md={CHART_TITLE_CELL_WIDTH}>
                                                    <EstimatedEstateTaxRow
                                                        ComparePlan1EstimatedEstateTax={comparePlan1EstateSummary.estimatedEstateTax}
                                                        ComparePlan2PlanSummaryResponse={comparePlan2SummaryResponse!}
                                                        ComparePlan2EstimatedEstateTax={comparePlan2EstateSummary.estimatedEstateTax}
                                                        DifferenceInEstimatedEstateTax={differenceInEstimatedEstateTax}
                                                        isComparePlan2={true}
                                                        showPillText={showDeltaBetweenPlans}
                                                    />
                                                </Col>
                                            </div>
                                        </Row>
                                    </div>
                                }
                            </main>
                        </ScrollableContainer>
                    </PrintViewWrapper>
                )
            })}
        </>
    );
}

export const TITLE_CELL_WIDTH = 374;
export const CHART_CELL_WIDTH = 370;
export const CHART_TITLE_CELL_WIDTH = 4;


export default ComparePlansWrapper;