import React, {useEffect, useMemo, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {GoalModelType} from "../models/GoalModelType";
import {addFamilyGoal, selectGoalModel} from "../controller/GoalsModelSlice";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import FamilyGoal from "./FamilyGoal";
import {ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import {MemberType} from "../../ClientManagement/models/MemberType";
import FamilyGoalUtils from "../Summary/FamilyGoalUtils";
import {assetsApiClient} from "../../Assets/AssetsApiClient";
import {FamilyAssets, FamilyAssetsRequest} from "../../Assets/models/FamilyAssets";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {FamilyGoalRequest, FamilyGoalType} from "../models/FamilyGoalType";
import {RouteWithId} from "../../routes/types";
import {goalsApiClient} from "../GoalsApiClient";
import {GoalSubType} from "../models/GoalType";

const FamilyGoalContainer = () => {

    const history = useHistory();
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const memberSelected = history.location.state.memberSelected
    const {proposal, familyGoals, investorGroup} = useAppSelector<GoalModelType>(selectGoalModel);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const beneficiaryGoals = familyGoals[memberSelected.id];

    const [familyAssets, setFamilyAssets] = useState<FamilyAssets>({
        memberId: memberSelected.id,
        totalAssets: 0
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        assetsApiClient.getFamilyAssets(proposal.id, memberSelected.id)
            .then(response => {
                setFamilyAssets(response);
                setIsLoading(false);
            })
            .catch(error => {
                if (error.status !== 404) {
                    console.error(error);
                }
                setIsLoading(false);
            })
    }, []);

    const handleDone = () => {
        history.push(`/Profile/${proposal.id}/ClientProfile/Goals/Summary`);
    }
    const handleEditGoal = (selectedGoalType: string, savedGoal: FamilyGoalType) => {
        history.push(
            `/Profile/${id}/ClientProfile/Goals/EditFamilyGoal`,
            {
                ...history.location.state,
                selectedGoalSubType: selectedGoalType,
                savedGoal
            }
        );
    }

    const handleSaveFamilyAssets = (updatedFamilyAssets: FamilyAssets) => {
        const familyAssetRequest: FamilyAssetsRequest = {
            memberId: updatedFamilyAssets.memberId,
            totalAssets: updatedFamilyAssets.totalAssets
        };
        assetsApiClient.postFamilyAssets(proposal.id, familyAssetRequest)
            .then(() => setFamilyAssets(updatedFamilyAssets))
            .catch(console.error);
    }

    const handleApplyFamilyAssets = (goal: FamilyGoalType, assetAmountToApply: number, selectedGoalType?: GoalSubType) => {
        const request: FamilyGoalRequest = {
            id: goal.id,
            beneficiaryId: goal.beneficiaryId,
            ownerId: goal.ownerId,
            selectedGoalType: goal.userInputs.selectedGoalSubType ?? selectedGoalType,
            familyAssetsAmount: assetAmountToApply,
            description: goal.userInputs.description,
            annualFlow: goal.userInputs.annualFlow,
            startDate: goal.userInputs.startDate,
            endDate: goal.userInputs.endDate,
            riskAsset: goal.userInputs.riskAsset,
            riskControl: goal.userInputs.riskControl,
            isFundedByNonInvestableAssets: goal.userInputs.isFundedByNonInvestableAssets
        }

        goalsApiClient.postFamilyGoal(proposal.id, request)
            .then((updatedFamilyGoal) => {
                updatedFamilyGoal.userInputs.selectedGoalSubType = updatedFamilyGoal.userInputs.selectedGoalSubType ?? selectedGoalType;

                dispatch(addFamilyGoal({
                    goal: updatedFamilyGoal,
                    member: memberSelected
                }))
            });
    }

    const memoizedAddNewGoalDropdownItems = useMemo(() => {
        return getAddNewGoalDropdownItemsFor(proposal, memberSelected);
    }, [proposal, memberSelected]);

    if (isLoading) {
        return <LoadingIndicator/>
    }
    return (
        <FamilyGoal
            memberSelected={memberSelected}
            memberName={`${(memberSelected.firstName)} ${(memberSelected.lastName)}`}
            onDone={handleDone}
            releaseToggles={releaseToggles}
            beneficiaryGoals={beneficiaryGoals}
            addNewGoalDropdownItems={memoizedAddNewGoalDropdownItems}
            investorGroup={investorGroup}
            familyAssets={familyAssets}
            onChangeFamilyAssets={setFamilyAssets}
            onEditGoal={handleEditGoal}
            onApplyFamilyAssets={handleApplyFamilyAssets}
            onSaveFamilyAssets={handleSaveFamilyAssets}
        />
    )
}

function getAddNewGoalDropdownItemsFor(profile: ProfileResponse, memberSelected: MemberType): JSX.Element[] {
    return FamilyGoalUtils.getFamilyGoalsTypeDropdownItems((goalType: string) => {
        return {
            pathname: `/Profile/${profile.id}/ClientProfile/Goals/AddFamilyGoal`,
            state: {selectedGoalSubType: goalType, memberSelected}
        }
    })
}


export default FamilyGoalContainer
