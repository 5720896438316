import React, {useEffect} from "react";
import {DropdownMultiselectCreatable} from '@nt/dds-multiselect';
import {NO_OP} from "../../constants/common";
import {isValid} from "./validations";

type DropdownMultiselectProps = {
    alignRight?: boolean,
    creatable?: boolean,
    disabled?: boolean,
    dropUp?: boolean,
    dynamicDropAlign?: boolean,
    dynamicDropDirection?: boolean,
    error?: string,
    hideSelectAll?: boolean,
    id?: string,
    label?: string,
    onChange?: Function,
    open?: boolean,
    options?: object,
    panelHeight?: number,
    panelWidth?: string,
    placeholder?: string,
    required?: boolean,
    searchable?: boolean,
    selectAllLabel?: string,
    selected?: object,
    selectedDisplayTextAfter?: string,
    selectedDisplayTextBefore?: string,
    selectedItemsFirst?: boolean,
    showClearAllButton?: boolean,
    showFullSelected?: boolean,
    showTotalSelected?: boolean,
    className?: string,
    helperText?: string
}

const DropdownMultiselect = ({
                                 alignRight = false,
                                 creatable = true,
                                 disabled = false,
                                 dropUp = false,
                                 dynamicDropAlign = false,
                                 dynamicDropDirection = false,
                                 error,
                                 hideSelectAll,
                                 id,
                                 label,
                                 onChange = NO_OP,
                                 open = false,
                                 options = {},
                                 panelHeight = 280,
                                 panelWidth = "100%",
                                 placeholder,
                                 required = false,
                                 searchable = false,
                                 selectAllLabel,
                                 selected = {},
                                 selectedDisplayTextAfter,
                                 selectedDisplayTextBefore,
                                 selectedItemsFirst = false,
                                 showClearAllButton = false,
                                 showFullSelected = false,
                                 showTotalSelected = false,
                                 className = "",
                                 helperText = ""
                             }: DropdownMultiselectProps) => {

    useEffect(() => {
        const handleDocumentKeystroke = (e: any) => {
            if (e.target && e.target.name == 'DDMS-creatable-DropdownField') {
                if (!isValid(e.target.value) && e.key === " ") {
                    e.target.value = "";
                    e.preventDefault();
                }
            }
        };
        document.addEventListener('keydown', handleDocumentKeystroke);
        return () => {
            document.removeEventListener('keydown', handleDocumentKeystroke);
        };
    }, []);

    return (
        <>
            <DropdownMultiselectCreatable
                className={className}
                alignRight={alignRight}
                creatable={creatable}
                disabled={disabled}
                dropUp={dropUp}
                dynamicDropAlign={dynamicDropAlign}
                dynamicDropDirection={dynamicDropDirection}
                error={error}
                hideSelectAll={hideSelectAll}
                id={id}
                label={label}
                onChange={onChange}
                open={open}
                options={options}
                panelHeight={panelHeight}
                panelWidth={panelWidth}
                placeholder={placeholder}
                required={required}
                searchable={searchable}
                selectAllLabel={selectAllLabel}
                selected={selected}
                selectedDisplayTextAfter={selectedDisplayTextAfter}
                selectedDisplayTextBefore={selectedDisplayTextBefore}
                selectedItemsFirst={selectedItemsFirst}
                showClearAllButton={showClearAllButton}
                showFullSelected={showFullSelected}
                showTotalSelected={showTotalSelected}
            />
            {!error && <span className="input__info margintop-sm">{helperText}</span>}
        </>
    )
}

export default DropdownMultiselect;
