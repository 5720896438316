import {AssetsSummary, CurrentNetWorthAsset} from "../../../Assets/models/Assets";
import {COLOR_ASSETS_ACCOUNTS} from "../../../constants/colors";
import {AccordionItemWithActionMenu} from "../../../components";
import React, {useMemo} from "react";
import {
    calculateAllAccountsInEstateTotalMarketValue,
    calculateCNWAssetsJointlyOwnedTotalPresentValue,
    calculateCNWAssetsMemberOwnedTotalPresentValue,
    calculateJointlyOwnedValue,
    calculateMemberOwnedValue
} from "../../../Assets/AssetSummary/common/AssetSummaryCalculator"
import {NO_OP} from "../../../constants/common";
import {TableCell} from "../../../components/Table/TableCell";
import {InvestorGroupMember, InvestorGroupType} from "src/ClientManagement/models/InvestorGroupType";
import {HeldAwayAccountSummary, LegalAgreement} from "../../../Assets/models/InvestmentProgram";
import {StandaloneAccount} from "../../../Assets/models/StandaloneAccount";
import {getOwnershipDescription} from "../../../Assets/CurrentNetWorth/InEstate/ownershipUtils";
import {AssetAccordionHeader} from "../../../Assets/AssetSummary/common/AssetAccordionHeader";
import {AssetAccordionContentHeader} from "../../../Assets/AssetSummary/common/AssetAccordionContentHeader";
import {LegalAgreementRow} from "../../../Assets/AssetSummary/InEstate/LegalAgreementRow";
import {StandaloneAccountRow} from "../../../Assets/AssetSummary/InEstate/StandaloneAccountRow";
import {showOwnershipType} from "../../../Assets/Ownership/helpers";
import {assetListData, sortByOrdinalAndInEstateOrMarketValue} from "../../../Assets/AssetSummary/common/utils";
import {shortenName} from "../../../Assets/common/textUtils";
import {HeldAwayAccountRow} from "../../../Assets/AssetSummary/InEstate/HeldAwayAccountRow";
import {PartiallyOwnedLegalAgreement} from "../../../Assets/models/PartiallyOwnedInvestmentAccount";
import {
    PartiallyOwnedInvestmentAccountRow
} from "../../../Assets/AssetSummary/InEstate/PartiallyOwnedInvestmentAccountRow";
import {BankingAccountsSummary} from "../../../Assets/models/BankingAccounts";
import {BankingAccountRow} from "../../../Assets/AssetSummary/InEstate/BankingAccountRow";
import {StandaloneAccountOrLegalAgreement} from "../../../Assets/AssetSummary/InEstate/AccountTableSection";

export interface InEstateAccountsProps {
    assetsDataForPage: AssetsSummary,
    investorGroup: InvestorGroupType,
    allAssetsCNW: AssetsSummary,
    pageNumber?: number
}


const AccountsCNWReport = ({assetsDataForPage, investorGroup, allAssetsCNW, pageNumber = 0}:
                               InEstateAccountsProps) => {
    const {
        getFormattedTotalPresentValue,
        getFormattedPresentValueForCurrentNetWorth,
        hasInEstateAccount,
        hasInEstateInvestmentProgram,
        hasInEstatePartiallyOwnedInvestmentAccounts,
        inEstateStandaloneAccounts,
        inEstateLegalAgreements,
        inEstatePartiallyOwnedInvestmentAccounts,
        inEstateHeldAwayAccounts,
        hasInEstateInvestmentProgramHeldAA,
        hasInEstateBankingAccount,
        inEstateBankingAccounts
    } = assetListData(assetsDataForPage);

    const allSortedAccounts: any = sortByOrdinalAndInEstateOrMarketValue([
        ...inEstateLegalAgreements,
        ...inEstateHeldAwayAccounts,
        ...inEstateStandaloneAccounts,
        ...inEstatePartiallyOwnedInvestmentAccounts,
        ...inEstateBankingAccounts,
    ]);

    const isAccountsInMultiPage = useMemo(() => pageNumber === 0 ? false : true, [pageNumber]);

    const currentNetWorthStandaloneAccounts: CurrentNetWorthAsset[] = inEstateStandaloneAccounts.map(inEstateStandaloneAccount => ({
        id: inEstateStandaloneAccount.id,
        name: inEstateStandaloneAccount.name,
        presentValue: inEstateStandaloneAccount.marketValue.totalValue,
        assetType: "standaloneAccount",
        ownershipCategory: inEstateStandaloneAccount.ownershipCategory,
        memberOwnerships: inEstateStandaloneAccount.memberOwnerships
    }));

    const currentNetWorthLegalAgreements: CurrentNetWorthAsset[] = inEstateLegalAgreements.map(inEstateLegalAgreement => ({
        id: inEstateLegalAgreement.id,
        name: inEstateLegalAgreement.name,
        presentValue: inEstateLegalAgreement.marketValue,
        assetType: "investmentProgram",
        ownershipCategory: inEstateLegalAgreement.ownershipCategory,
        memberOwnerships: inEstateLegalAgreement.memberOwnerships
    }));

    const currentNetWorthHeldAwayAccounts: CurrentNetWorthAsset[] = inEstateHeldAwayAccounts.map(inEstateHeldAwayAccount => ({
        id: inEstateHeldAwayAccount.id,
        name: inEstateHeldAwayAccount.financialAccountName,
        presentValue: inEstateHeldAwayAccount.holdings.marketValue?.totalValue || 0,
        assetType: "investmentProgramHAA",
        ownershipCategory: inEstateHeldAwayAccount.ownershipCategory,
        memberOwnerships: inEstateHeldAwayAccount.memberOwnerships
    }));

    const currentNetWorthBankingAccounts: CurrentNetWorthAsset[] = inEstateBankingAccounts.map(inEstateBankingAccount => ({
        id: inEstateBankingAccount.id,
        name: inEstateBankingAccount.accountName,
        presentValue: inEstateBankingAccount.holdings ? inEstateBankingAccount.holdings.marketValue?.totalValue || 0 : 0,
        assetType: "bankingAccount",
        ownershipCategory: inEstateBankingAccount.ownershipCategory,
        memberOwnerships: inEstateBankingAccount.memberOwnerships
    }));

    const currentNetWorthPartiallyOwnedInvestmentAccounts: CurrentNetWorthAsset[] = inEstatePartiallyOwnedInvestmentAccounts
        .map(poiaAccount => ({
            id: poiaAccount.id,
            name: poiaAccount.legalAgreementName,
            presentValue: poiaAccount.marketEstateValue.totalValue,
            assetType: "partiallyOwnedInvestmentAccount",
            ownershipCategory: poiaAccount.ownershipCategory,
            memberOwnerships: poiaAccount.memberOwnerships
        }));

    const primaryMember = investorGroup.primaryMember;
    const partnerMember = investorGroup.partnerMember;


    function generateAssetPopOverContent(standaloneAccount: StandaloneAccount) {
        const ownershipDescriptionCNW = getOwnershipDescription(standaloneAccount, primaryMember, partnerMember)
        return ownershipDescriptionCNW ? <>{ownershipDescriptionCNW}</> : undefined;
    }

    function calculateMemberOwnedAccountAndIPValue(investorGroupMember: InvestorGroupMember) {
        return calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroupMember.id, currentNetWorthStandaloneAccounts)
            + calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroupMember.id, currentNetWorthLegalAgreements)
            + calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroupMember.id, currentNetWorthHeldAwayAccounts)
            + calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroupMember.id, currentNetWorthPartiallyOwnedInvestmentAccounts)
            + calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroupMember.id, currentNetWorthBankingAccounts);
    }

    function calculateJointlyOwnedAccountAndIPValue() {
        return calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthStandaloneAccounts)
            + calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthLegalAgreements)
            + calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthHeldAwayAccounts)
            + calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthPartiallyOwnedInvestmentAccounts)
            + calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthBankingAccounts);
    }

    const renderAccountCNWRow = (accountInfo: StandaloneAccountOrLegalAgreement) => {
        const hasOutOfEstateOwnership = accountInfo.memberOwnerships?.filter(x => !x.isInEstateMember)?.length > 0
            || accountInfo.legalEntityOwnerships?.length > 0;
        let assetToRender;
        if ((accountInfo as any).legalAgreementNumber) {
            if (hasOutOfEstateOwnership) {
                assetToRender = renderPartiallyOwnedInvestmentAccountRow(accountInfo);
            } else {
                assetToRender = renderLegalAgreementRow(accountInfo);
            }
        } else if ((accountInfo as any).financialAccountNumber) {
            assetToRender = renderHeldAwayAccountRow(accountInfo);
        } else if ((accountInfo as any).accountPersistentId) {
            assetToRender = renderBankingAccountRow(accountInfo);
        } else {
            assetToRender = renderStandaloneAccountRow(accountInfo);
        }
        return assetToRender;
    }

    const renderLegalAgreementRow = (accountInfo: StandaloneAccountOrLegalAgreement) => {
        const legalAgreementAsset = accountInfo as LegalAgreement;

        return <LegalAgreementRow legalAgreement={legalAgreementAsset}
                                  actionsDisabled={false}
                                  onClickEdit={NO_OP}
                                  key={legalAgreementAsset.id}
                                  gridClassName={"current-net-worth-grid-with-actionmenu"}
                                  renderLegalAgreementDetails={(agreement: LegalAgreement) => {
                                      return <>
                                          <TableCell text={shortenName(agreement.name)}
                                                     className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}/>
                                          {investorGroup.partnerMember && <>
                                              <TableCell
                                                  text={getFormattedPresentValueForCurrentNetWorth(
                                                      calculateMemberOwnedValue(
                                                          investorGroup.primaryMember.id,
                                                          agreement.memberOwnerships,
                                                          agreement.ownershipCategory,
                                                          agreement.marketValue))}
                                                  className="textalign-right"/>
                                              <TableCell
                                                  text={getFormattedPresentValueForCurrentNetWorth(
                                                      calculateMemberOwnedValue(
                                                          investorGroup.partnerMember.id,
                                                          agreement.memberOwnerships,
                                                          agreement.ownershipCategory,
                                                          agreement.marketValue))}
                                                  className="textalign-right"/>
                                              <TableCell
                                                  text={getFormattedPresentValueForCurrentNetWorth(
                                                      calculateJointlyOwnedValue(
                                                          agreement.ownershipCategory,
                                                          agreement.marketValue))}
                                                  className="textalign-right"/>
                                          </>
                                          }
                                      </>
                                  }} onClickViewHoldings={NO_OP}/>;
    }

    const renderHeldAwayAccountRow = (accountInfo: StandaloneAccountOrLegalAgreement) => {
        const heldAwayAccountAsset = accountInfo as HeldAwayAccountSummary;

        return <HeldAwayAccountRow heldAwayAccount={heldAwayAccountAsset}
                                   actionsDisabled={false}
                                   onClickEdit={NO_OP}
                                   onClickViewHoldings={NO_OP}
                                   key={heldAwayAccountAsset?.id}
                                   gridClassName={"current-net-worth-grid-with-actionmenu"}
                                   renderHeldAwayAccountDetails={(heldAwayAccountsReport: HeldAwayAccountSummary) => {
                                       return <>
                                           <TableCell
                                               text={shortenName(heldAwayAccountsReport.financialAccountName)}
                                               subtext={showOwnershipType(heldAwayAccountsReport.ownershipCategory)}
                                               className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                           />
                                           {investorGroup.partnerMember && <>
                                               <TableCell
                                                   text={getFormattedPresentValueForCurrentNetWorth(
                                                       calculateMemberOwnedValue(
                                                           investorGroup.primaryMember.id,
                                                           heldAwayAccountsReport.memberOwnerships,
                                                           heldAwayAccountsReport.ownershipCategory,
                                                           heldAwayAccountsReport.holdings.marketValue?.totalValue || 0))}
                                                   className="textalign-right"/>
                                               <TableCell
                                                   text={getFormattedPresentValueForCurrentNetWorth(
                                                       calculateMemberOwnedValue(
                                                           investorGroup.partnerMember.id,
                                                           heldAwayAccountsReport.memberOwnerships,
                                                           heldAwayAccountsReport.ownershipCategory,
                                                           heldAwayAccountsReport.holdings.marketValue?.totalValue || 0))}
                                                   className="textalign-right"/>
                                               <TableCell
                                                   text={getFormattedPresentValueForCurrentNetWorth(
                                                       calculateJointlyOwnedValue(
                                                           heldAwayAccountsReport.ownershipCategory,
                                                           heldAwayAccountsReport.holdings.marketValue?.totalValue || 0))}
                                                   className="textalign-right"/>
                                           </>
                                           }
                                       </>
                                   }}/>;
    }

    const renderBankingAccountRow = (accountInfo: StandaloneAccountOrLegalAgreement) => {
        const bankingAccountAsset = accountInfo as BankingAccountsSummary;

        return <BankingAccountRow bankingAccount={bankingAccountAsset}
                                  actionsDisabled={false}
                                  onClickEdit={NO_OP}
                                  onClickDelete={NO_OP}
                                  onClickViewHoldings={NO_OP}
                                  key={bankingAccountAsset?.id}
                                  gridClassName={"current-net-worth-grid-with-actionmenu"}
                                  renderBankingAccountDetails={(bankingAccountsReport: BankingAccountsSummary) => {
                                      return <>
                                          <TableCell
                                              text={shortenName(bankingAccountsReport.accountName)}
                                              subtext={showOwnershipType(bankingAccountsReport.ownershipCategory)}
                                              className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                          />
                                          {investorGroup.partnerMember && <>
                                              <TableCell
                                                  text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.primaryMember.id, bankingAccountsReport.memberOwnerships, bankingAccountsReport.ownershipCategory, bankingAccountsReport.holdings ? bankingAccountsReport.holdings.marketValue?.totalValue || 0 : 0))}
                                                  className="textalign-right"/>
                                              <TableCell
                                                  text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.partnerMember.id, bankingAccountsReport.memberOwnerships, bankingAccountsReport.ownershipCategory, bankingAccountsReport.holdings ? bankingAccountsReport.holdings.marketValue?.totalValue || 0 : 0))}
                                                  className="textalign-right"/>
                                              <TableCell
                                                  text={getFormattedPresentValueForCurrentNetWorth(calculateJointlyOwnedValue(bankingAccountsReport.ownershipCategory, bankingAccountsReport.holdings ? bankingAccountsReport.holdings.marketValue?.totalValue || 0 : 0))}
                                                  className="textalign-right"/>
                                          </>
                                          }
                                      </>
                                  }}/>;

    }

    const renderStandaloneAccountRow = (accountInfo: StandaloneAccountOrLegalAgreement) => {
        const standaloneAccount = accountInfo as StandaloneAccount;

        return <StandaloneAccountRow standaloneAccount={standaloneAccount}
                                     actionsDisabled={false}
                                     onClickEdit={NO_OP}
                                     onClickDelete={NO_OP}
                                     onClickViewHoldings={NO_OP}
                                     key={standaloneAccount.id}
                                     gridClassName={"current-net-worth-grid-with-actionmenu"}
                                     renderStandaloneAccountDetails={(account: StandaloneAccount) => {
                                         return <>
                                             <TableCell text={account.name}
                                                        subtext={showOwnershipType(account.ownershipCategory)}
                                                        className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                                        popoverContent={generateAssetPopOverContent(account)}
                                                        popoverWidth={"288px"}
                                             />
                                             {investorGroup.partnerMember && <>
                                                 <TableCell
                                                     text={getFormattedPresentValueForCurrentNetWorth(
                                                         calculateMemberOwnedValue(
                                                             investorGroup.primaryMember.id,
                                                             account.memberOwnerships,
                                                             account.ownershipCategory,
                                                             account.marketValue.totalValue))}
                                                     className="textalign-right"/>
                                                 <TableCell
                                                     text={getFormattedPresentValueForCurrentNetWorth(
                                                         calculateMemberOwnedValue(
                                                             investorGroup.partnerMember.id,
                                                             account.memberOwnerships,
                                                             account.ownershipCategory,
                                                             account.marketValue.totalValue))}
                                                     className="textalign-right"/>
                                                 <TableCell
                                                     text={getFormattedPresentValueForCurrentNetWorth(
                                                         calculateJointlyOwnedValue(
                                                             account.ownershipCategory,
                                                             account.marketValue.totalValue))}
                                                     className="textalign-right"/>
                                             </>
                                             }
                                         </>
                                     }}/>;
    }

    const renderPartiallyOwnedInvestmentAccountRow = (accountInfo: StandaloneAccountOrLegalAgreement) => {
        const partiallyOwnedInvestmentAccount = accountInfo as PartiallyOwnedLegalAgreement;

        return <PartiallyOwnedInvestmentAccountRow
            key={partiallyOwnedInvestmentAccount.id}
            partiallyOwnedInvestmentAccount={partiallyOwnedInvestmentAccount}
            actionsDisabled={false}
            onClickEdit={NO_OP}
            onClickDelete={NO_OP}
            onClickViewHoldings={NO_OP}
            gridClassName={"current-net-worth-grid-with-actionmenu"}
            renderPartiallyOwnedInvestmentAccountDetails={(partiallyOwnedInvestmentAccountReport: PartiallyOwnedLegalAgreement) => {
                return <>
                    <TableCell
                        text={shortenName(partiallyOwnedInvestmentAccountReport.legalAgreementName)}
                        subtext={showOwnershipType(partiallyOwnedInvestmentAccountReport.ownershipCategory)}
                        className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                    />
                    {investorGroup.partnerMember && <>
                        <TableCell
                            text={getFormattedPresentValueForCurrentNetWorth(
                                calculateMemberOwnedValue(
                                    investorGroup.primaryMember.id,
                                    partiallyOwnedInvestmentAccountReport.memberOwnerships,
                                    partiallyOwnedInvestmentAccountReport.ownershipCategory,
                                    partiallyOwnedInvestmentAccountReport.marketEstateValue?.totalValue || 0))}
                            className="textalign-right"/>
                        <TableCell text={
                            getFormattedPresentValueForCurrentNetWorth(
                                calculateMemberOwnedValue(
                                    investorGroup.partnerMember.id,
                                    partiallyOwnedInvestmentAccountReport.memberOwnerships,
                                    partiallyOwnedInvestmentAccountReport.ownershipCategory,
                                    partiallyOwnedInvestmentAccountReport.marketEstateValue?.totalValue || 0))}
                                   className="textalign-right"/>
                        <TableCell
                            text={getFormattedPresentValueForCurrentNetWorth(
                                calculateJointlyOwnedValue(
                                    partiallyOwnedInvestmentAccountReport.ownershipCategory,
                                    partiallyOwnedInvestmentAccountReport.marketEstateValue?.totalValue || 0))}
                            className="textalign-right"/>
                    </>
                    }
                </>
            }}/>

    }

    return (
        <>
            {(hasInEstateAccount || hasInEstateInvestmentProgram || hasInEstateInvestmentProgramHeldAA || hasInEstateBankingAccount || hasInEstatePartiallyOwnedInvestmentAccounts)
                && <AccordionItemWithActionMenu
                    uuid="AccountsCNWReport"
                    accentColor={COLOR_ASSETS_ACCOUNTS}
                    HeaderComponent={({expanded}) => {
                        const totalInEstateMarketValue = calculateAllAccountsInEstateTotalMarketValue(
                            allAssetsCNW.accounts.data,
                            allAssetsCNW.investmentProgram,
                            allAssetsCNW.partiallyOwnedLegalAgreements,
                            allAssetsCNW.bankingAccounts);

                        return <AssetAccordionHeader
                            caption={isAccountsInMultiPage ? "continued" : ""}
                            expanded={expanded}
                            title="Accounts"
                            formattedPrimaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateMemberOwnedAccountAndIPValue(investorGroup.primaryMember)) : undefined}
                            formattedSecondaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateMemberOwnedAccountAndIPValue(investorGroup.partnerMember)) : undefined}
                            formattedJointTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateJointlyOwnedAccountAndIPValue()) : undefined}
                            formattedTotalPresentValue={getFormattedTotalPresentValue(totalInEstateMarketValue)}
                            gridClassName={"current-net-worth-grid"}/>
                    }
                    }>

                    <div role="table" className="current-net-worth-grid-with-actionmenu assets-grid-table"
                         aria-label="accounts-table">
                        <AssetAccordionContentHeader investorGroup={investorGroup}/>
                        {allSortedAccounts.map((sortedAccount: any) => {
                            return renderAccountCNWRow(sortedAccount);
                        })};

                    </div>

                </AccordionItemWithActionMenu>}
        </>
    );
}

export default AccountsCNWReport;
