import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {clientManagementApiClient} from "../ClientManagement/ClientManagementApiClient";
import {ProfileResponse} from "../ClientManagement/models/ProfileResponse";
import {selectProfile, setProfile} from "../ClientManagement/ClientProfile/activeProfileSlice";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {selectProposals, setProposals} from "../ClientManagement/Proposals/proposalsSlice";
import {selectApprovedProfile, setApprovedProfile} from "../ClientManagement/ClientProfile/approvedProfileSlice";
import {isProposal} from "../ClientManagement/models/isProposal";

type UseProfileState = {
    approvedProfile: ProfileResponse,
    isLoading: boolean,
    profile: ProfileResponse,
    proposals: ProfileResponse[]
}

const getApprovedProfile = async (profileOrProposal: ProfileResponse): Promise<ProfileResponse> => {
    if (isProposal(profileOrProposal)) {
        return clientManagementApiClient.getProfile(profileOrProposal.profileIdForProposal);
    }
    return profileOrProposal;
};

const useProfileAndProposals = (profileOrProposalId: string): UseProfileState => {
    const dispatch = useAppDispatch();
    const profileState = useAppSelector(selectProfile);
    const proposalsState = useAppSelector(selectProposals);
    const approvedProfileState = useAppSelector(selectApprovedProfile);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const archivedParam = queryParams.get("archived") ? true : false;
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        let isMounted = true;
            (async () => {
                try {
                    const profileOrProposal = await clientManagementApiClient.getProfile(profileOrProposalId);
                    const approvedProfile = await getApprovedProfile(profileOrProposal);
                    dispatch(setProfile(profileOrProposal));
                    dispatch(setProposals(await clientManagementApiClient.getProposals(approvedProfile.id)));
                    if (!archivedParam) {
                        dispatch(setApprovedProfile(approvedProfile));
                    }
                } catch (error) {
                    console.error('Could not fetch profile/proposals', (error as Error).message);
                } finally {
                    if(isMounted) setIsLoading(false);
                }
            })();
            return () => {isMounted = false};
        },
        [dispatch, profileOrProposalId]
    );
    return {
        approvedProfile: approvedProfileState,
        isLoading,
        profile: profileState,
        proposals: proposalsState
    };
}

export default useProfileAndProposals;
