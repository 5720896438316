import React from "react";
import FormHeader from "./components/FormHeader";
import {MemberGroup} from "../ClientManagement/models/InvestorGroupType";
import LoadingIndicator from "../pages/LoadingIndicator";
import {Input} from "../components";

type ReviewFiduciaryProps = {
    familyMembers: MemberGroup
}

const ReviewFiduciariesForm: React.FC<ReviewFiduciaryProps> = ({familyMembers}: ReviewFiduciaryProps): JSX.Element => {

    if (!familyMembers.primaryMember) {
        return <LoadingIndicator/>;
    }

    return (
        <div className="review-fiduciaries-header">
            <div className="review-fiduciaries-form-container">
                <FormHeader title={"Review Fiduciaries"} formHasBeenEdited={false}/>
                <div className="review-fiduciaries-content">
                    <div className={"fiduciary-column"}>
                        <h4>{familyMembers.primaryMember.firstName} {familyMembers.primaryMember.lastName}</h4>
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"} data-testid={'primary-executor'}>Executor
                            <Input
                                aria-label="Primary Executor"
                                size="medium"
                            />
                            </label>
                        </div>
                    </div>
                    <div className={"fiduciary-column right"}>
                        <h4>{familyMembers.partnerMember?.firstName} {familyMembers.partnerMember?.lastName}</h4>
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"} data-testid={'partner-executor'}>Executor
                            <Input
                                aria-label="Partner Executor"
                                size="medium"
                            />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewFiduciariesForm;