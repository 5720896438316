import React, {ChangeEvent, KeyboardEventHandler, MouseEventHandler} from "react"
import {NumberInput} from "src/components/index"

type YearsInputProps = {
    style: React.CSSProperties,
    name: string,
    className: string,
    value: number | string | null,
    size: 'small' | 'medium' | 'large',
    onBlur: (e: ChangeEvent<HTMLInputElement>) => void,
    readOnly: boolean,
    disabled: boolean,
    onChangeValue: (e: ChangeEvent<HTMLInputElement>, value: number) => void,
    onClick?: MouseEventHandler,
    error?: string,
    inlineLabel?: string,
    inlineLabelAlign?: "left" | "right",
    onKeyDown?: KeyboardEventHandler
}

export const YearsInput = ({
    name,
    className,
    value,
    size,
    onChangeValue,
    inlineLabel = "years",
    inlineLabelAlign = "right",
    ...rest
}: YearsInputProps) => {
    return (
        <NumberInput
            id={name}
            name={name}
            className={className}
            size={size}
            value={value}
            onChangeValue={(e: ChangeEvent<HTMLInputElement>, changedValue: number | string) => {
                onChangeValue(e, typeof changedValue === "number" && Number.isInteger(changedValue) ?
                    changedValue : parseInt(String(changedValue)));
            }}
            inlineLabel={inlineLabel}
            inlineLabelAlign={inlineLabelAlign}
            {...rest}
        />
    )
}