export interface RevocableTrust {
    flowchartName: string,
    legalName: string,
    nickName: string | null,
    trustCreationDate: string,
    recordOfAmendment: string | null
    revTrustId: string,
    grantors: Trustee[],
    trustees: Trustee[],
    successors: Trustee[],
    funding: string | null,
    investmentAdvisor: string | null,
    trustProtector: string | null,
    trustJurisdiction: string,
    beneficiaries?: Beneficiaries | null,
}

export const emptyRevocableTrust: RevocableTrust = {
    flowchartName: "",
    legalName: "",
    nickName: null,
    trustCreationDate: "",
    recordOfAmendment: null,
    revTrustId: "",
    grantors: [],
    trustees: [],
    successors: [],
    funding: null,
    investmentAdvisor: null,
    trustProtector: null,
    trustJurisdiction: "",
    beneficiaries: null,
}

export type Trustee = {
    trustId: string | undefined,
    memberId?: string | null,
    memberType: TrusteeType,
    memberOrder?: number,
    customName?: string,
    familyMember: boolean
}

export type Beneficiaries = {
    firstDeath: FirstDeath
}

export type FirstDeath = {
    trusts?: Trust[],
}

export type Trust = {
    trustType: TrustType
    trustName: string,
    tags: string,
    trustees: Trustee[],
    successors: Trustee[],
    beneficiaries: Trustee[],
    funding: string,
    distributions: string,
    termination: string,
    powersOfAppointment: PowerOfAppointment[],
    beneficiaryId?: string
}

export type PowerOfAppointment = {
    type: PoaType,
    transferType: TransferType,
    powerHolder: string,
    permissibleAppointees: string,
    additionalDetails: string,
    poaOrder: number
}

export enum TrusteeType {
    Grantor = "GRANTOR",
    Trustee = "TRUSTEE",
    Successor = "SUCCESSOR_TRUSTEE",
    Beneficiary = "BENEFICIARY",
    BeneficiaryTrustee = "BENEFICIARY_TRUSTEE",
    BeneficiarySuccessor = "BENEFICIARY_SUCCESSOR",
}

export enum TrustType {
    marital = "MARITAL",
    creditShelter = "CREDIT_SHELTER",
    intrust = "IN_TRUST",
    survivor = "SURVIVOR"
}

export type Member = {
    value: string,
    label: string,
    selected: boolean
}

export type FamilyMembers = {
    value: string,
    label: string,
    familyMember: Member[]
}

export enum PoaType {
    Limited = "Limited",
    General = "General"
}

export enum TransferType {
    Testamentary = "Testamentary",
    Lifetime = "Lifetime"
}


/**
 *There can be more than 1 flowchart per Profile; Each flowchart will have one rev/irrev x
 */
export type EstateFlowchartState = {
    flowchartId: string | undefined
    revocableTrust: RevocableTrust
}

export type TrustValidation = {
    revocableTrust: null | RevocableTrustValidation,
}

export type RevocableTrustValidation = null | {
    flowchartName: string | null,
    legalName: string | null
}