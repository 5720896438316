import {Button} from "../../../components";
import React from "react";

type NonClientOwnershipHeaderProps = {
    onAddButtonClick: () => void,
    title: string,
    buttonText: string,
    buttonDisabled?: boolean,
    subtitle?: string
};

export function SubSectionHeader(props: NonClientOwnershipHeaderProps) {
    return <>
        <div className="sub-section-header h5 margintop-xl">
            {props.title}
            <Button onClick={props.onAddButtonClick}
                    icon="left"
                    iconName="add"
                    kind="borderless"
                    disabled={props.buttonDisabled}
            >
                {props.buttonText}
            </Button>
        </div>
        <span className="sub-section-subtitle sub-section-header">{props.subtitle}</span>
    </>;
}