import React, {useEffect, useState} from 'react';
import {AssetsSummary} from "./models/Assets";
import {AlertBanner} from "../components";
import {assetsApiClient} from "./AssetsApiClient";

export interface InfoBannerForAccountAndPCRProps {
    assets: AssetsSummary;
    profileId: string;
}

const InfoBannerForAccountAndPCR = ({assets, profileId}: InfoBannerForAccountAndPCRProps) => {
    const [showInfoBanner, setShowInfoBanner] = useState<boolean>(false);

    useEffect(() => {
        if (assets.accounts.data.length > 0 && (assets.investmentProgram && assets.investmentProgram.heldAwayAccounts.length > 0)
            && (assets.investmentProgram.heldAwayAccounts.some(account  => account.showBanner))) {
            setShowInfoBanner(true);
        } else {
            setShowInfoBanner(false);
        }
    }, [assets]);

    const closeBanner = () => {
        assetsApiClient.closeBanner(profileId,"heldaway");
        setShowInfoBanner(false);
    }

    return <AlertBanner
        className={'marginbottom-lg'}
        fullWidth={false}
        icon="info"
        type="info"
        showAlert={showInfoBanner}
        showCloseBtn={true}
        message={"Aggregated accounts have been added. Please review standalone accounts to ensure duplicates are removed."}
        onClose={closeBanner}
    />
}

export default InfoBannerForAccountAndPCR;