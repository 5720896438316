import React, {useEffect, useState} from "react";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {
    formatNumberRoundedToOneDecimal,
    formatNumberRoundedToTwoDecimals,
    formatYear
} from "../../utils/format";
import {calculateYearsBetween, getTodayDateAsString} from "../../utils/dateUtils";
import {Container} from "react-grid-system";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectPortfolioReserveModel} from "./PortfolioReserveSlice";
import {wealthManagementApiClient} from "../../ClientManagement/WealthManagementApiClient";
import RiskLegend, {riskLegendColor} from "../../components/Legend/RiskLegend";
import goalUtils from "../Summary/GoalUtils";
import usePageViewTimer from "../../hooks/usePageViewTimer";
import LoadingIndicator from "../../pages/LoadingIndicator";
import SetPortfolioReserveHeader from "./SetPortfolioReserveHeader";
import {handleTogglePortfolioReserve} from "./PortfolioReserveUtil";
import {
    EMPTY_REVISE_ASSET_ALLOCATION_RESPONSE,
    ReviseAssetAllocationResponse
} from "../../ClientManagement/AssetAllocation/ReviseAssetAllocation";
import AssetAllocationRiskDonut from "../../ClientManagement/AssetAllocation/AssetAllocationRiskDonut";

const MonitorPortfolioReserve = () => {
    const history = useHistory();
    const {id} = useParams<RouteWithId>();
    const portfolioReserveModel = useAppSelector(selectPortfolioReserveModel);
    const {portfolioReserve} = portfolioReserveModel;
    const profile = useProfileAndProposals(id).approvedProfile;
    const reserveTargetLength = profile.portfolioReserveTargetLength ? profile.portfolioReserveTargetLength : 0;
    const portfolioReserveActivationDate = profile ? profile.portfolioReserveActivationDate : null;
    const yearsFromPortfolioReserveActivationDate = portfolioReserveActivationDate ? calculateYearsBetween(portfolioReserveActivationDate, getTodayDateAsString()) : 0
    const currentSupportYears = portfolioReserve.preciseCalculatedTargetLength;
    const replenishedAllocationResponse: ReviseAssetAllocationResponse = {
        ...EMPTY_REVISE_ASSET_ALLOCATION_RESPONSE,
        totalProposedRiskAssetsPercent: portfolioReserve.targetLengthAllocation.riskAssetPercent,
        totalProposedRiskControlAssetsPercent: portfolioReserve.targetLengthAllocation.riskControlPercent
    }
    const dispatch = useAppDispatch()
    const [currentAllocation, setCurrentAllocation] = useState<ReviseAssetAllocationResponse>(EMPTY_REVISE_ASSET_ALLOCATION_RESPONSE);
    const [proposedAllocation, setProposedAllocation] = useState<ReviseAssetAllocationResponse>(EMPTY_REVISE_ASSET_ALLOCATION_RESPONSE);
    const remainingReserveYears = goalUtils.getRemainingPortfolioReserveLength(reserveTargetLength, portfolioReserveActivationDate)

    const [isLoading, setIsLoading] = useState<boolean>(true)
    usePageViewTimer('Monitor Portfolio Reserve Page Load Timer (milliseconds)', isLoading);

    useEffect(() => {
        (async () => {
            try{
            if (id) {
                setIsLoading(true)
                const [currentAllocationResponse, proposedAllocationResponse] = await Promise.all([
                    wealthManagementApiClient.getReviseAssetAllocation(id),
                    wealthManagementApiClient.getReviseAssetAllocation(id)]
                );
                setCurrentAllocation(currentAllocationResponse);
                setProposedAllocation(proposedAllocationResponse);
                setIsLoading(false)
            }
        }
        catch (e) {
            setIsLoading(false)
        }

        })();
    }, [id]);

    const handleActivatePRToggle = (activePortfolioReserve: boolean)  => {
        handleTogglePortfolioReserve(activePortfolioReserve, id, dispatch).then(() => {
            history.push(`/Profile/${id}/ClientProfile/PortfolioReserve/SetPortfolioReserve/EditPortfolioReserve`, {forceNavigate: true});
        })
    }

    if (isLoading) {
        return <LoadingIndicator/>
    }
    const totalCurrentRiskAssetsPercentOfTotalPortfolio = Number(formatNumberRoundedToTwoDecimals(currentAllocation.totalCurrentRiskAssetsAmount / (currentAllocation.totalCurrentRiskAssetsAmount + currentAllocation.totalCurrentRiskControlAssetsAmount) * 100));
    const totalCurrentRiskControlAssetsPercentOfTotalPortfolio = Number(formatNumberRoundedToTwoDecimals(currentAllocation.totalCurrentRiskControlAssetsAmount / (currentAllocation.totalCurrentRiskAssetsAmount + currentAllocation.totalCurrentRiskControlAssetsAmount) * 100));

    return (
        <div className="monitor-portfolio-reserve-page">
            <div>
                <SetPortfolioReserveHeader
                    displaySetReserveTargetButton={true}
                    isSetReserve={false}
                    onTogglePortfolioReserve={handleActivatePRToggle} />
            </div>
            <div className="monitor-portfolio-reserve-page__body">
                <div className="monitor-portfolio-reserve-page__body__text font-xl">
                    <div className={"monitor-portfolio-reserve-page__body__text__title-card"}>
                        {"Reserve Target: "}
                    </div>
                    <div className="monitor-portfolio-reserve-page__body__text__title-card__year">
                        {formatYear(reserveTargetLength)}
                    </div>
                </div>
                {
                    portfolioReserveActivationDate &&
                    <div className="monitor-portfolio-reserve-page__body__text font-xl">
                        <div className={"monitor-portfolio-reserve-page__body__text__title-card"}>
                            {"Your reserve has been funding your Lifestyle Spending for "}
                        </div>
                        <div className="monitor-portfolio-reserve-page__body__text__title-card__year">
                            {formatNumberRoundedToOneDecimal(yearsFromPortfolioReserveActivationDate)} Years
                        </div>
                    </div>
                }
                <div className={"monitor-portfolio-reserve-page__body__cards"}>
                    {/*this is the left column of the page*/}
                    <Container data-testid={"current-card"} className='drawdown-card__current'>
                        <AllocationCardContent value={currentSupportYears}
                                               data={currentAllocation}
                                               roundedRiskAssetsPercent={totalCurrentRiskAssetsPercentOfTotalPortfolio}
                                               roundedRiskControlAssetsPercent={totalCurrentRiskControlAssetsPercentOfTotalPortfolio}
                                               allocationType={"current"}
                                               hasSmallHeader={true}
                                               header={"Current Drawdown"}
                                               subheader={"Your current Asset Allocation supports"}
                                               subEnding={"of your Lifestyle Spending"}
                                               donutTitle={"Current Asset Allocation"}
                        />
                    </Container>

                    {/*this is the right column of the page*/}
                    <Container data-testid={"proposed-card"} className="drawdown-card">
                        <AllocationCardContent value={remainingReserveYears}
                                               data={proposedAllocation}
                                               roundedRiskAssetsPercent={proposedAllocation.totalProposedRiskAssetsPercentOfTotalPortfolio}
                                               roundedRiskControlAssetsPercent={proposedAllocation.totalProposedRiskControlAssetsPercentOfTotalPortfolio}
                                               allocationType={"proposed"}
                                               hasSmallHeader={true}
                                               header={"Proposed Allocation"}
                                               subheader={"Your proposed Asset Allocation would support"}
                                               subEnding={"of your Lifestyle Spending"}
                                               donutTitle={"Proposed Asset Allocation"}
                        />
                        <div className="nav-drawer__separator"/>
                        <AllocationCardContent value={reserveTargetLength}
                                               data={replenishedAllocationResponse}
                                               roundedRiskAssetsPercent={portfolioReserve.targetLengthAllocation.riskAssetPercent}
                                               roundedRiskControlAssetsPercent={portfolioReserve.targetLengthAllocation.riskControlPercent}
                                               allocationType={"replenished"}
                                               hasSmallHeader={false}
                                               header={""}
                                               subheader={"Replenishing to your "}
                                               subEnding={" would result in a proposed Asset Allocation: "}
                                               donutTitle={"Proposed Asset Allocation with replenished Risk Control"}
                        />
                    </Container>
                </div>
            </div>
        </div>
    );
}

export const AllocationCardContent = (props: {
    value: number,
    data: ReviseAssetAllocationResponse,
    roundedRiskAssetsPercent: number,
    roundedRiskControlAssetsPercent: number,
    allocationType: string,
    hasSmallHeader: boolean,
    header: string,
    subheader: string,
    subEnding: string,
    donutTitle: string,
}) => {
    function getHeaderContentForAllocationCardType() {
        return props.allocationType === 'current' ?
            <div>
                <span className={"drawdown-card__current__header"}>{props.header}</span>
                <span className="drawdown-card__current__subhead">
                            {props.subheader} <b> {props.value} years </b> {props.subEnding}
                        </span>
            </div>
            :
            <div className={"section"}>
                <span className={"drawdown-card__header"}>{props.header}</span>
                <span className="portfolio-reserve-subhead">
                            {props.subheader} <b> {props.value} years </b> {props.subEnding}
                        </span>
            </div>;
    }

console.log(props.data)
    return (
        <div>
            {props.hasSmallHeader ?
                getHeaderContentForAllocationCardType()
                :
                <div style={{marginTop: "35px"}}>
                    <span className="portfolio-reserve-subhead">
                        {props.subheader} <b> {props.value} year Reserve Target </b> {props.subEnding}
                    </span>
                </div>
            }
            <div className={"risk-assets-and-risk-control"}>
                <div
                    className={"asset-allocation-text"}
                    data-testid={`${props.allocationType}-asset-allocation-text`}
                    tabIndex={0}>
                    <div className={"asset-allocation-text"}>{props.donutTitle}</div>
                    <div className={"total-portfolio"}>Total Portfolio</div>
                </div>
                <div className={"no-hover-donut"} data-testid={`${props.allocationType}-allocation-donut`}>
                    {/*<RiskDonut donutSize={"sm"} data={props.data}/>*/}
                    {<AssetAllocationRiskDonut data={props.data} allocation={props.allocationType} donutSize={'sm'}/>}
                </div>
                <div className={"risk-legend"}>
                    <div className={"risk"} data-testid={`${props.allocationType}-risk-assets`}>
                        <div className={"risk-legend-label"}>
                            <RiskLegend legendColor={riskLegendColor.RISK_ASSETS} label={"Risk Assets"}/>
                        </div>
                        <span
                            className={"risk-percentage"}>{` ${(props.roundedRiskAssetsPercent)}%`}</span>
                    </div>
                    <div className={"risk"} data-testid={`${props.allocationType}-risk-control`}>
                        <div className={"risk-legend-label"}>
                            <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}
                                        label={"Risk Control Assets"}/>
                        </div>
                        <span
                            className={"risk-percentage"}>{` ${(props.roundedRiskControlAssetsPercent)}%`}</span>
                    </div>
                </div>
            </div>
            {!props.hasSmallHeader && <div style={{paddingBottom: "10px"}}/>}
        </div>
    );
}

export default MonitorPortfolioReserve;
