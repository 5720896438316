import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {
    addFamilyGoal,
    deleteFamilyGoal,
    selectGoalModel,
    updateLifestyleSpendingGoal,
    updateNonLifestyleGoalReducer
} from "../../controller/GoalsModelSlice";
import {FamilyGoalsType, FamilyGoalType} from "../../models/FamilyGoalType";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {GoalModelType} from "../../models/GoalModelType";
import FamilyGoalUtils from "../../Summary/FamilyGoalUtils";
import AddNewFamilyGoalBarChartSideBar from "../../BarChartSidebar/AddNewFamilyGoalBarChartSideBar";
import {MemberType} from "../../../ClientManagement/models/MemberType";
import {FamilyGoalService} from "../FamilyGoalService";
import {RouteWithId} from "../../../routes/types";
import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import DataEntryFooter from "../../../components/DataEntry/DataEntryFooter";
import {goalsApiClient} from "../../GoalsApiClient";
import NonlifestyleGoalLivePreview from "../../NonLifestyleGoals/NonlifestyleGoalLivePreview";
import NonLifestyleGoalForm from "../../components/NonLifestyleGoalForm";
import {GoalType, NonLifestyleGoalType} from "../../models/GoalType";
import DeleteGoalModal from "src/Goals/components/DeleteGoalModal";
import {LifestyleSpendingGoal} from "../../models/LifestyleSpendingGoal";
import DiscardModal from "../../../components/Modal/DiscardModal/DiscardModal";
import {NonLifestyleGoalCharacteristics} from "../../components/NonLifestyleGoalCharacteristics";
import {RequiredFieldsBanner} from "../../../components";
import useProfileEditableState from "../../../hooks/useProfileEditableState";
import ScrollableContainer from "../../../components/ScrollableContainer/ScrollableContainer";


const AddFamilyGoal = ({isEditing = false}: { isEditing?: boolean }) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {
        investorGroup,
        proposal,
        discountRateSelection,
        lifestyleSpendingGoal,
    } = useAppSelector<GoalModelType>(selectGoalModel)
    const {id: profileId} = useParams<RouteWithId>();

    const selectedGoalSubType: FamilyGoalsType = history.location.state.selectedGoalSubType
    const memberSelectedInHistory: boolean = !!history.location.state.memberSelected
    const savedGoalInHistory: boolean = !!history.location.state.savedGoal;
    const portfolioReserveIsSet = proposal.portfolioReserveTargetLength !== undefined && proposal.portfolioReserveTargetLength !== null;
    let viewPage = (history.location.state && history.location.state.sourcePage) ? history.location.state.sourcePage : "FamilyGoal";
    let isSourcePageGoalsPrioritization: boolean = (viewPage === "Prioritization")
    let memberSelected: MemberType;
    let familyGoalForState: FamilyGoalType;
    let lifestyleGoalState: LifestyleSpendingGoal = lifestyleSpendingGoal;

    if (savedGoalInHistory) {
        familyGoalForState = history.location.state.savedGoal;
        memberSelected = FamilyGoalUtils.getBeneficiaryFromProfile(proposal, familyGoalForState.beneficiaryId);
    } else if (memberSelectedInHistory && !savedGoalInHistory) {
        familyGoalForState = FamilyGoalUtils.initializeFamilyGoal(profileId, history.location.state.memberSelected.id, selectedGoalSubType, investorGroup)
        memberSelected = history.location.state.memberSelected
    } else {
        throw new Error("Goal Not Found, must pass a Goal or a Beneficiary")
    }

    const [familyGoal, replaceFamilyGoal] = useState<FamilyGoalType>(familyGoalForState)
    const [lifestyleGoal, replaceLifestyleGoal] = useState<LifestyleSpendingGoal>(lifestyleGoalState)
    const [isRequiredFieldsBannerShown, setRequiredFieldsBannerShown] = useState(false);
    const [isSaveButtonDisabled, updateSaveButtonDisabled] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [originalGoalState] = useState(familyGoalForState);

    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    useEffect(() => {
        setRequiredFieldsBannerShown(isRequiredFieldsBannerShown && isAnyRequiredFieldEmpty());
    }, [familyGoal.userInputs.description]);

    function isNonLifestyleGoalFormDataMissing(nonLifestyleGoal: NonLifestyleGoalType) {
        const isDescriptionEmpty = !nonLifestyleGoal.userInputs.description.trim();
        return isDescriptionEmpty;
    }

    const isAnyRequiredFieldEmpty = () => {
        return isNonLifestyleGoalFormDataMissing(familyGoal);
    }

    const handleModalDiscardButton = () => {
        setShowCancelModal(false);
        dispatch(updateNonLifestyleGoalReducer(originalGoalState));
        history.push(`/Profile/${proposal.id}/ClientProfile/Goals/Summary`);
    };

    const handleCancelButtonClick = () => {
        if (isProfileWithProposalsOrArchived || familyGoal === familyGoalForState) {
            history.push(`/Profile/${proposal.id}/ClientProfile/Goals/Summary`);
        } else {
            setShowCancelModal(true);
        }
    }

    const handleSaveButtonClick = async () => {
        const isRequiredFieldEmpty = isAnyRequiredFieldEmpty();
        if (isRequiredFieldEmpty) {
            setRequiredFieldsBannerShown(true);
            return false;
        }
        updateSaveButtonDisabled(true);
        if (familyGoal.userInputs.riskAsset != null && familyGoal.userInputs.riskAsset > 100) {
            familyGoal.userInputs.riskAsset = 100
        } else if (familyGoal.userInputs.riskAsset != null && familyGoal.userInputs.riskAsset < 0) {
            familyGoal.userInputs.riskAsset = 0
        } else if (familyGoal.userInputs.riskControl != null && familyGoal.userInputs.riskControl > 100) {
            familyGoal.userInputs.riskControl = 100
        } else if (familyGoal.userInputs.riskControl != null && familyGoal.userInputs.riskControl < 0) {
            familyGoal.userInputs.riskControl = 0
        }
        FamilyGoalService.saveFamilyGoal(profileId, familyGoal).then(savedFamilyGoalResponse => {
            savedFamilyGoalResponse.userInputs.selectedGoalSubType = savedFamilyGoalResponse.userInputs.selectedGoalSubType ?? selectedGoalSubType;

            dispatch(addFamilyGoal({goal: savedFamilyGoalResponse, member: memberSelected}))
            dispatch(updateLifestyleSpendingGoal(lifestyleGoal));
            if (isSourcePageGoalsPrioritization) {
                document.location.replace(`/Profile/${proposal.id}/ClientProfile/Goals/${viewPage}`);
            } else {
                history.push(`/Profile/${profileId}/ClientProfile/Goals/${viewPage}`, {
                    ...history.location.state,
                    memberSelected
                });
            }
        })
    }

    const [showDeleteGoalModal, setShowDeleteGoalModal] = useState(false);

    const handleOnDeleteClick = async () => {
        const response = await goalsApiClient.deleteFamilyGoal(familyGoal.id!);
        if (response.status === 204) {
            dispatch(deleteFamilyGoal({goal: familyGoal}))
            history.goBack()
        }
    }

    const handleUpdateFamilyGoal = (updatedGoal: NonLifestyleGoalType) => {
        replaceFamilyGoal(FamilyGoalUtils.convertFamilyNonLifestyleGoalTypeToFamilyGoalType(updatedGoal, familyGoalForState.familyAssetsAmount))
    }

    const renderNonLifestyleGoalCharacteristics = () => {
        return <NonLifestyleGoalCharacteristics
            familyGoal={familyGoal}
            replaceFamilyGoal={replaceFamilyGoal}
            isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
        />;
    }

    return (
        <div className="layout-split-sidebar">
            <DiscardModal
                isOpen={showCancelModal}
                onClickKeepEditing={() => setShowCancelModal(false)}
                onClickDiscardChanges={handleModalDiscardButton}
                isEditing={isEditing}/>
            <AddNewFamilyGoalBarChartSideBar familyGoalFromForm={familyGoal}
                                             lifestyleSpendingGoalFromForm={lifestyleGoal}/>
            <ScrollableContainer id={'add-edit-familygoal-scroll-container'}>
                <div className='layout-data-entry-form add-new-goal-entry-form'>
                    <DataEntryHeader
                        className="add-family-goals_title"
                        title={`${savedGoalInHistory ? 'Edit ' : 'Add '}` + familyGoal.userInputs.selectedGoalSubType}
                        primaryButtonText="Save"
                        onPrimaryButtonClick={handleSaveButtonClick}
                        disablePrimaryButton={isSaveButtonDisabled}
                        secondaryButtonText="Cancel"
                        onSecondaryButtonClick={handleCancelButtonClick}
                        isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                    />
                    <RequiredFieldsBanner showAlert={isRequiredFieldsBannerShown} itemType="family goal"/>
                    <div className="family-goal-container">
                        <NonLifestyleGoalForm
                            lifestyleGoal={lifestyleGoal}
                            nonPortfolioFunded={familyGoalForState.familyAssetsAmount}
                            replaceLifestyleGoal={replaceLifestyleGoal}
                            typeDropdownItems={FamilyGoalUtils.getFamilyGoalsTypeDropdownItems()}
                            discountRateSelection={discountRateSelection}
                            nonLifestyleGoal={FamilyGoalUtils.convertFamilyGoalTypeToNonLifestyleGoalType(familyGoal)}
                            replaceNonLifestyleGoal={handleUpdateFamilyGoal}
                            portfolioReserveIsSet={portfolioReserveIsSet}
                            goalCharacteristics={renderNonLifestyleGoalCharacteristics()}
                        />
                        <NonlifestyleGoalLivePreview nonLifestyleGoal={familyGoal}/>
                    </div>
                    {
                        savedGoalInHistory &&
                        <DataEntryFooter
                            className="color-text--error"
                            hideDuplicate={true}
                            hideAddNew={true}
                            sticky={false}
                            onDelete={() => setShowDeleteGoalModal(true)}
                            disableDelete={isProfileWithProposalsOrArchived}
                        />
                    }
                </div>
            </ScrollableContainer>
            <DeleteGoalModal showDeleteGoalModal={showDeleteGoalModal}
                             onCancel={() => setShowDeleteGoalModal(false)}
                             onDelete={handleOnDeleteClick}
                             goalType={GoalType.FAMILY}
                             description={familyGoal.userInputs.description}/>
        </div>
    )
}

export default AddFamilyGoal
