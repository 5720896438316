import React, {ChangeEvent, useState} from "react";
import DataEntryHeader from "../components/DataEntry/DataEntryHeader";
import {AgendaDetails, ServiceTeamMemberAgenda} from "./models/AgendaDetails";
import {AlertBanner, DatePicker, Input, RequiredFieldsSubheader, UnderlinedHeader} from "../components";
import {DISPLAY_DATE_FORMAT} from "../constants/common";
import moment, {Moment} from "moment";
import LoadingIndicator from "../pages/LoadingIndicator";
import Presenters from "./Presenters";
import {HistoryBlockModal} from "../components/HistoryBlockModal/HistoryBlockModal";
import {useHistory} from "react-router-dom";
import DiscardModal from "../components/Modal/DiscardModal/DiscardModal";

type CreateOrEditAgendaProps = {
    partners: ServiceTeamMemberAgenda[]
    agendaDetails: AgendaDetails,
    handleSaveAgenda: (agendaDetails: AgendaDetails, navigate: boolean) => void
    displayName: string
}

const AgendaMeetingDetails: React.FC<CreateOrEditAgendaProps> = ({
                                                                     partners,
                                                                     agendaDetails,
                                                                     handleSaveAgenda,
                                                                     displayName,
                                                                 }: CreateOrEditAgendaProps) => {
    const title = `Client Agenda Settings - ${displayName}`;
    const history = useHistory();
    const [showAlertBanner, setShowAlertBanner] = useState<boolean>(false);
    const [updatedAgendaDetails, setUpdatedAgendaDetails] = useState<AgendaDetails>(agendaDetails);


    const [isMainSaveButton, setIsMainSaveButton] = useState<boolean>(false);
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

    const handleCancelButton = async () => {
        if (JSON.stringify(updatedAgendaDetails) === JSON.stringify(agendaDetails)) {
            history.goBack();
        } else {
            setShowCancelModal(true);
        }
    };

    const handleModalDiscardButton = () => {
        setIsMainSaveButton(true);
        setShowCancelModal(false);
        history.goBack();
    };

    const isNTPresentersEmpty = () => {
        const {presenters} = updatedAgendaDetails;
        const ntPresenters = presenters.filter(({presenterType}) => presenterType === 'NT_PRESENTER');
        if (!ntPresenters.length) return true;
        return ntPresenters.some(({presenterName, presenterId}) => (!presenterName && !presenterId));
    }

    const handleSaveButton = async (navigate: boolean = true) => {
        setIsMainSaveButton(navigate);
        const {meetingTitle, meetingDate, clientProfileName} = updatedAgendaDetails;
        const areRequiredFieldsFilled: boolean = !!(meetingTitle && meetingDate && clientProfileName && !isNTPresentersEmpty());
        if (areRequiredFieldsFilled) {
            setShowAlertBanner(false);
            handleSaveAgenda(updatedAgendaDetails, navigate);
        } else {
            setShowAlertBanner(true);
            setIsMainSaveButton(false);
        }
        return areRequiredFieldsFilled;
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setUpdatedAgendaDetails({
            ...updatedAgendaDetails,
            [name]: value
        })
    };
    const hasUnsavedChanges = () => {
        return  !isMainSaveButton && JSON.stringify(updatedAgendaDetails) !== JSON.stringify(agendaDetails)
    }


    if (!updatedAgendaDetails) {
        return <LoadingIndicator/>
    }

    return (
        <div className="layout-data-entry-form">
            <DataEntryHeader
                className='data-entry-header'
                title={title}
                primaryButtonText={"Save"}
                secondaryButtonText={"Cancel"}
                onPrimaryButtonClick={() => handleSaveButton(true)}
                onSecondaryButtonClick={handleCancelButton}
                disablePrimaryButton={!hasUnsavedChanges()}
            />
            {showAlertBanner && <div className="alert-banner-container width-60p marginbottom-12">
                <AlertBanner
                    fullWidth={false}
                    icon="warning"
                    type="error"
                    showAlert={true}
                    showCloseBtn={false}
                >
                    <span className="banner-text">
                        Required fields missing
                    </span>
                </AlertBanner>
            </div>}
            <article className='client-meeting-agenda'>
                <section>
                    <UnderlinedHeader
                        className="client-meeting-agenda__header marginbottom-20"
                        primaryText="Meeting Details"
                        rightAlignedContent={<RequiredFieldsSubheader/>}
                    />
                    <div className="layout-data-entry-form__field nt-presenter-input">
                        <label id="meetingTitle"><b>Meeting Title</b> <i
                            className="color-text--error">*</i></label>
                        <Input
                            name="meetingTitle"
                            aria-label={"Meeting Title"}
                            aria-labelledby="meetingTitle"
                            onChange={handleInputChange}
                            removeMarginTop
                            size="medium"
                            type="text"
                            value={updatedAgendaDetails.meetingTitle}
                        />
                    </div>
                    <div className="layout-data-entry-form__field nt-presenter-input">
                        <label id="linkedPartnerWorkStationClient">
                            <b>Client Profile</b> <i className="color-text--error">*</i>
                        </label>
                        <Input
                            name="clientProfileName"
                            aria-label={"Client Profile"}
                            aria-labelledby="clientProfileName"
                            onChange={handleInputChange}
                            removeMarginTop
                            size="medium"
                            type="text"
                            value={updatedAgendaDetails.clientProfileName}
                        />
                    </div>
                    <div className="layout-data-entry-form__field nt-presenter-input meetingDateField">
                        <label id="linkedPartnerWorkStationClient"><b>Date</b> <i
                            className="color-text--error">*</i></label>
                        <DatePicker
                            className=""
                            id="meetingDateInput"
                            aria-label="Date"
                            aria-labelledby="meetingDateInput"
                            displayFormat={DISPLAY_DATE_FORMAT}
                            hideKeyboardShortcutsPanel
                            date={updatedAgendaDetails.meetingDate ? moment(updatedAgendaDetails.meetingDate) : undefined}
                            onDateChange={
                                (date: Moment) => {
                                    setUpdatedAgendaDetails({
                                        ...updatedAgendaDetails,
                                        meetingDate: date.toISOString()
                                    })
                                }
                            }
                        />
                    </div>
                    <div className="layout-data-entry-form__field nt-presenter-input">
                        <label id="location"><b>Location</b></label>
                        <Input
                            name="meetingLocation"
                            aria-labelledby="location"
                            onChange={handleInputChange}
                            removeMarginTop
                            size="medium"
                            type="text"
                            value={updatedAgendaDetails.meetingLocation}
                        />
                    </div>
                </section>
                <Presenters
                    partners={partners}
                    updatedAgendaDetails={updatedAgendaDetails}
                    setUpdatedAgendaDetails={setUpdatedAgendaDetails}
                />
            </article>
            <HistoryBlockModal
                when={
                    hasUnsavedChanges()
                }
                itemType={"Agenda"}
                onSave={() => handleSaveButton(false)}
            />
            <DiscardModal
                isOpen={showCancelModal}
                onClickKeepEditing={() => setShowCancelModal(false)}
                onClickDiscardChanges={handleModalDiscardButton}
                isEditing={true}/>
        </div>
    );
};

export default AgendaMeetingDetails;