import React from "react";
import {AssetsSummary, CurrentNetWorthAsset} from "../../../Assets/models/Assets";
import {COLOR_LIFE_INSURANCES} from "../../../constants/colors";
import {AssetAccordionHeader} from "../../../Assets/AssetSummary/common/AssetAccordionHeader";
import {AccordionItemWithActionMenu} from "../../../components";
import {LifeInsurance} from "../../../Assets/models/LifeInsurance";
import {
    calculateCNWAssetsJointlyOwnedTotalPresentValue,
    calculateCNWAssetsMemberOwnedTotalPresentValue,
    calculateJointlyOwnedValue,
    calculateMemberOwnedValue, calculateTotalValueForCNW, isPolicyExpired
} from "../../../Assets/AssetSummary/common/AssetSummaryCalculator";
import {assetListData} from "../../../Assets/AssetSummary/common/utils";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {AssetAccordionContentHeader} from "../../../Assets/AssetSummary/common/AssetAccordionContentHeader";
import {TableCell} from "../../../components/Table/TableCell";
import {LifeInsuranceRow} from "../../../Assets/AssetSummary/InEstate/LifeInsuranceRow";
import {getOwnershipDescription} from "../../../Assets/CurrentNetWorth/InEstate/ownershipUtils";
import {NO_OP} from "../../../constants/common";
import {shortenName} from "../../../Assets/common/textUtils";

export interface InEstateLifeInsuranceProps {
    assetsDataForPage: AssetsSummary,
    investorGroup: InvestorGroupType,
    showDeathBenefit?: boolean,
    caption: string,
    allAssetsCNW: AssetsSummary
}

const LifeInsurancesCNWReport = ({assetsDataForPage, investorGroup, showDeathBenefit = false, caption, allAssetsCNW}:
                                     InEstateLifeInsuranceProps) => {

    const {
        getFormattedTotalPresentValue,
        getFormattedPresentValueForCurrentNetWorth,
        inEstateLifeInsurances
    } = assetListData(assetsDataForPage);

    const inEstateLifeInsurancesForAllAssets = assetListData(allAssetsCNW).inEstateLifeInsurances;
    const primaryMemberForCNW = investorGroup.primaryMember;
    const partnerMemberForCNW = investorGroup.partnerMember;

    const currentNetWorthLifeInsurancesCNW: CurrentNetWorthAsset[] = inEstateLifeInsurancesForAllAssets.map((lifeInsurance) => {
        let insuranceValue = showDeathBenefit && !lifeInsurance.isCashValueWillFundGoals ? lifeInsurance.deathBenefitValue : lifeInsurance.cashValue;
        if (lifeInsurance.type === 'Term' && isPolicyExpired(lifeInsurance)) {
            insuranceValue = 0;
        }
        return {
            id: lifeInsurance.id,
            name: lifeInsurance.description,
            presentValue: insuranceValue,
            assetType: "lifeInsurance",
            ownershipCategory: lifeInsurance.ownershipCategory,
            memberOwnerships: lifeInsurance.memberOwnerships
        }
    })

    function generateAssetPopOverContent(insurance: LifeInsurance) {
        const ownershipDescription = getOwnershipDescription(insurance, primaryMemberForCNW, partnerMemberForCNW)
        return ownershipDescription ? <>{ownershipDescription}</> : undefined;
    }

    function getCaption() {
        if (showDeathBenefit) {
            if (caption.length > 0) {
                return "Death Benefit" + "(" + caption + ")";
            } else {
                return "Death Benefit";
            }
        }

        if (caption.length > 0) {
            return "Cash Value" + "(" + caption + ")";
        } else {
            return "Cash Value";
        }
    }

    return (
        <>
            {inEstateLifeInsurances.length > 0 && <AccordionItemWithActionMenu
                uuid="LifeInsurancesCNWReport"
                accentColor={COLOR_LIFE_INSURANCES}
                HeaderComponent={({expanded}) =>
                    <AssetAccordionHeader
                        expanded={expanded}
                        title={'Life Insurance'}
                        caption={getCaption()}
                        formattedPrimaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroup.primaryMember.id, currentNetWorthLifeInsurancesCNW)) : undefined}
                        formattedSecondaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroup.partnerMember?.id, currentNetWorthLifeInsurancesCNW)) : undefined}
                        formattedJointTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthLifeInsurancesCNW)) : undefined}
                        formattedTotalPresentValue={getFormattedTotalPresentValue(calculateTotalValueForCNW(inEstateLifeInsurancesForAllAssets, showDeathBenefit))}
                        gridClassName={"current-net-worth-grid"}/>
                }>

                <div role="table" className="current-net-worth-grid-with-actionmenu assets-grid-table"
                     aria-label="life-insurance-table">
                    <AssetAccordionContentHeader investorGroup={investorGroup}/>

                    {inEstateLifeInsurances.map((lifeInsurance: LifeInsurance) => {
                        return <LifeInsuranceRow lifeInsurance={lifeInsurance}
                                                 actionsDisabled={false}
                                                 onClickEdit={NO_OP}
                                                 onClickDelete={NO_OP}
                                                 key={lifeInsurance.id}
                                                 gridClassName={"current-net-worth-grid-with-actionmenu"}
                                                 showDeathBenefit={showDeathBenefit}
                                                 renderLifeInsuranceDetails={(insurance: LifeInsurance) => {
                                                     const lifeInsuranceIfNotFundingGoals = showDeathBenefit ? insurance.deathBenefitValue : insurance.cashValue
                                                     let insuranceValue = showDeathBenefit && lifeInsurance.isCashValueWillFundGoals ? 0 : lifeInsuranceIfNotFundingGoals;
                                                     if (insurance.type === 'Term' && isPolicyExpired(insurance)) {
                                                         insuranceValue = 0;
                                                     }

                                                     return <>
                                                         <TableCell text={shortenName(insurance.description)}
                                                                    className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                                                    popoverContent={generateAssetPopOverContent(insurance)}
                                                                    popoverWidth={"288px"}
                                                         />
                                                         {investorGroup.partnerMember && <>
                                                             <TableCell
                                                                 text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.primaryMember.id, insurance.memberOwnerships, insurance.ownershipCategory, insuranceValue))}
                                                                 className="textalign-right"/>
                                                             <TableCell
                                                                 text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.partnerMember.id, insurance.memberOwnerships, insurance.ownershipCategory, insuranceValue))}
                                                                 className="textalign-right"/>
                                                             <TableCell
                                                                 text={getFormattedPresentValueForCurrentNetWorth(calculateJointlyOwnedValue(insurance.ownershipCategory, insuranceValue))}
                                                                 className="textalign-right"/>
                                                         </>
                                                         }
                                                     </>
                                                 }}/>;
                    })}
                </div>

            </AccordionItemWithActionMenu>}
        </>
    );
}

export default LifeInsurancesCNWReport;