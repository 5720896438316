import React, {CSSProperties} from 'react';
import {Loader} from 'xps-react';
import classNames from "classnames";

type LoadingIndicatorProps = {
    displayMessage?: string;
    className?: string;
    displaySubText?: string;
    style?: CSSProperties;
}

export const LoadingIndicator = ({className, displayMessage, displaySubText, style = {}}: LoadingIndicatorProps) => {
    return <div style={style}
                data-testid='LoadingIndicator'
                className={classNames('loading-container', className)}>
        <Loader
            message={displayMessage ? displayMessage : "Loading"}
            size="md"
        />
        {displaySubText && <p>{displaySubText}</p>}
    </div>
}

export default LoadingIndicator;