import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "src/components"
import DiscardModal from "src/components/Modal/DiscardModal/DiscardModal";
import { RouteWithIdAndFlowchartId } from "src/routes/types";

type FormHeaderProps = {
    title: string,
    subheader?: string,
    formHasBeenEdited: boolean
}

/**
 * FormHeader component renders a header section for a form with a title and an optional subheader.
 * It also includes a cancel button that triggers a cancel action when clicked.
 *
 * @param {FormHeaderProps} props - The properties object.
 * @param {string} props.title - The main title to be displayed in the header.
 * @param {string} [props.subheader] - An optional subheader to be displayed above the main title.
 * @param {boolean} [props.formHasBeenEdited] - A flag indicating if the form has been edited to trigger modal when cancel is clicked.
 *
 * @returns {JSX.Element} The rendered FormHeader component.
 */
export default function FormHeader({title, subheader, formHasBeenEdited}: FormHeaderProps) {
    const history = useHistory();
    const {id} = useParams<RouteWithIdAndFlowchartId>();
    const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
    const handleCancel = () => {
        if(formHasBeenEdited){
            setIsDiscardModalOpen(true);
        }else{
            history.push(`/Profile/${id}/ClientProfile/EstateFlowchart`)
        }
    }
    if(isDiscardModalOpen){
        return (
            <div className="add-form-header">
                <div className="form-header-titles">
                    {subheader ? <span>{subheader}</span> : <></>}
                    <h3>{title}</h3>
                </div>
                <div className="form-header-button">
                    <Button
                        className="marginright-sm"
                        disabled={false}
                        icon="none"
                        kind="secondary"
                        onClick={handleCancel}
                        size={"medium"}
                        type="button"
                    >
                        Cancel
                    </Button>
                </div>
                <DiscardModal isOpen={isDiscardModalOpen}
                              onClickKeepEditing={() => setIsDiscardModalOpen(false)}
                              onClickDiscardChanges={() => history.push(`/Profile/${id}/ClientProfile/EstateFlowchart`)}
                              isEditing={true}/>
            </div>
        )
    }else{
        return (
            <div className="add-form-header">
                <div className="form-header-titles">
                    {subheader ? <span>{subheader}</span> : <></>}
                    <h3>{title}</h3>
                </div>
                <div className="form-header-button">
                    <Button
                        className="marginright-sm"
                        disabled={false}
                        icon="none"
                        kind="secondary"
                        onClick={handleCancel}
                        size={"medium"}
                        type="button"
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        )
    }
    
}