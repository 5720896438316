import React from "react";

export const StrategyListHeader = () => {
    return (
        <div className={"strategy-list-header"}>
            <span className={"span-1"}>Name</span>
            <span className={"span-2"}>Strategy</span>
            <span className={"span-3 textalign-right"}>Future Value <br />of Gift</span>
            <span className={"span-4 textalign-right"}>Impact to <br/>Est. Estate Tax</span>
        </div>
    );
}