import {Col} from "react-grid-system";
import React from "react";
import {hasPositiveExcessAssets} from "../../ClientManagement/AssetReliance/AssetRelianceUtil";
import ChartStatusLine from "../../ClientManagement/AssetReliance/ChartStatusLine";
import {COLOR_GOALS, COLOR_NT_GREY, COLOR_NT_ORANGE_800} from "../../constants/colors";
import {StackedBarChart} from "../../components";
import ExcessAssetsVerticalLine from "../../ClientManagement/AssetReliance/ExcessAssetsVerticalLine";
import _ from "lodash";
import ComparePlanAssetBarChartColumn
    from "../../ClientManagement/AssetReliance/BarChart/ComparePlanAssetBarChartColumn";
import {CHART_CELL_WIDTH} from "./ComparePlansContent";

type ComparePlansBarchartProps = {
    totalStacks: number,
    netAssets: number,
    totalGoals: number,
    excessAssets: number,
    chartUnderlayMaxValue: number,
}
export const ComparePlansBarchart: React.FC<ComparePlansBarchartProps> = ({
                                                                         totalStacks,
                                                                         netAssets,
                                                                         totalGoals,
                                                                         excessAssets,
                                                                         chartUnderlayMaxValue,
                                                                     }) => {
    return (
        <>
            {_.range(6).map((index) => {
                const verticalOffset = `${(index * 20)}`;
                const lineWidth = (2 <= 1 ? 6 : 2) * 1;
                return <div key={index}>
                    <ChartStatusLine
                        id={'barChartUnderlay'}
                        label={''}
                        height={'1px'}
                        zIndex={0}
                        width={`calc(${lineWidth}px + 100%)`}
                        lineColor={COLOR_NT_GREY}
                        verticalOffset={verticalOffset}
                        marginLeft={0}
                        textColor={''}
                        isAssetReliance={true}
                    />
                </div>
            })}
            {_.range(Math.ceil(totalStacks / 2)).map((index) => {
                const isAssetSufficient: boolean = hasPositiveExcessAssets(excessAssets);
                const assetBarChart = new ComparePlanAssetBarChartColumn(netAssets, totalGoals, excessAssets, chartUnderlayMaxValue, isAssetSufficient)
                const columnHeightPercentage = (assetBarChart.getColumnMaxValue() / chartUnderlayMaxValue * 100).toFixed(2);
                return <React.Fragment key={`ExcludedAssetBarChart` + index}>
                    <Col style={{
                        width: `${CHART_CELL_WIDTH}px`,
                        height: `${columnHeightPercentage}%`
                    }} className={"compare-plan-barchart-column"}
                    >
                        <ChartStatusLine label=''
                                         width={`${100}%`}
                                         verticalOffset={assetBarChart.getGoalsPositionVerticalOffset()}
                                         lineColor={COLOR_GOALS}
                                         marginLeft={0}
                                         textColor={COLOR_NT_ORANGE_800}
                                         id={"barChartOverlay"}
                                         isAssetReliance={true}
                        />
                        <div className="compare-plan-barchart-container" style={{width: '100%'}}>
                            <StackedBarChart
                                showLabels={false}
                                height={columnHeightPercentage}
                                top={`${100 - parseFloat(columnHeightPercentage)}`}
                                className="compare-plans-asset-bar-chart"
                                data={assetBarChart.getChartItems()}
                            />
                            <ExcessAssetsVerticalLine color={assetBarChart.getPillColor()}
                                                      bottomPercentage={assetBarChart.getVerticalLineMargins().bottomMargin}
                                                      excessAssets={excessAssets}
                                                      topPercentage={assetBarChart.getVerticalLineMargins().topMargin}
                                                      classname={"compare-plans-asset-line"}
                            />
                        </div>
                    </Col>
                </React.Fragment>
            })}
        </>
    )
}