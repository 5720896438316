import classNames from 'classnames';
import React, {ReactNode} from 'react';
import {SubMenuProps} from "../../models/routeData/RouteParamTypes";
import {NavLink} from "react-router-dom";
import {Icon} from "../Icon";

export type DataEntrySummaryItem = {
    label?: string | ReactNode,
    value?: string | ReactNode,
    subValue?: string | ReactNode,
    className?: string,
    testId?: string,
}

export type DataEntrySummaryProps = {
    children?: ReactNode,
    className?: string,
    items?: DataEntrySummaryItem[],
    resourcesItems?: SubMenuProps[],
    title?: string,
    strategyName?: string
}

export default function DataEntrySummary(
    {children, className, items = [], title,resourcesItems = [],strategyName}: Readonly<DataEntrySummaryProps>
) {
    return (
        <div className={classNames('data-entry-summary', className)}>
            <div className="data-entry-summary__title" data-theme="aqua">
                <div className="margin-none h4">{title}</div>
            </div>
            <div className="data-entry-summary__items" >
                {items.map((item, index) => {
                    const empty = item.value === undefined || item.value === false || item.value === '' || Number.isNaN(item.value);
                    return (
                        <div key={index} className={classNames('data-entry-summary__item', item.className)} data-testid={item.testId}>
                            <span className="data-entry-summary__item__label">
                                <b>{item.label}</b>
                            </span>
                            <span
                                data-testid={"summary item - " + item.label?.toString()}
                                aria-label={item.label?.toString() ?? undefined}
                                className={classNames('data-entry-summary__item__value', {
                                    'data-entry-summary__item__value--empty': empty,
                                })}
                            >
                                {empty ? '—' : item.value}
                            </span>
                            {item.subValue &&
                                <span className="data-entry-summary__item__subvalue">{item.subValue}</span>}
                        </div>
                    );
                })}

                <ul className="nav-drawer-side-menu-list__items">
                    {resourcesItems && resourcesItems?.length > 0 && strategyName && resourcesItems.find(ele => ele.title === strategyName)!.children.map((child, childIndex) => {
                        const extension =  "";
                        const pathURL =  "/Profile/ProfileId/ClientProfile/Resources/AdviceLibrary" + "/" ;
                        return (
                            <li className="nav-drawer-side-menu-list__item" key={`${child.label}-${childIndex}`}>
                                <NavLink
                                    className="gray-link"
                                    to={(pathURL + child.subMenuLabel + "/" + `${child.link}${extension}/1`)}
                                >
                                    <Icon
                                        ariaHidden
                                        name="file"
                                        type="info"
                                    />
                                    <div className="doc-name__custom-alignment">{child.label}</div>
                                </NavLink>
                            </li>
                        )}
                    )}
                </ul>
            </div>
            {children && <div className="data-entry-summary__custom-content">{children}</div>}
        </div>
    );
}



