import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {RouteWithIdAndStrategyId} from "../routes/types";
import {
    AnnualGiftingStrategy,
    EstimatedImpact,
    FailedAPICall,
    GrowthRates,
    initialAnnualGiftingStrategy,
    StrategyType
} from "./WealthPOTypes";
import {wealthPOApiClient} from "./WealthPOApiClient";
import {
    DateAndAgeValues,
    getDateAndAgeValues,
    PlanningPeriodValues,
    processInvestorGroupResponse, validateDescription,
    validateEndAge,
    validateEndYear,
    validateStartAge,
    validateStartYear,
    validateYearsOfFlow,
    validateYearsUntilFlow
} from "./WealthPOUtils";
import {HandleSaveResponse, StrategyHeader} from "./components/StrategyHeader";
import {
    CurrencyInput,
    EmptyStateContainer,
    Icon,
    Input,
    RedAsterisk,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from "../components";
import {formatCurrency} from "../utils/format";
import {StrategySidebar} from "./components/StrategySidebar";
import useProfileEditableState from "../hooks/useProfileEditableState";
import {YearsInput} from "../components/YearsInput/YearsInput";
import RangeInputs, {RangeInputProp} from "../components/RangeInputs/RangeInputs";
import {DataEntrySummaryItem} from "../components/DataEntry/DataEntrySummary";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../components/Modal/Error/GenericErrorModal";
import {InvestorGroupType} from "../ClientManagement/models/InvestorGroupType";
import {clientManagementApiClient} from "../ClientManagement/ClientManagementApiClient";
import {selectAnnualGiftingStrategies} from "./WealthPlanOptimizerSlice";
import {useAppSelector} from "../store/hooks";
import ScrollableContainer from "../components/ScrollableContainer/ScrollableContainer";
import {SubMenuProps} from "../models/routeData/RouteParamTypes";

export interface AddEditAnnualGiftingProps {
    header: string,
    refreshStrategiesSummary: () => void,
    items: SubMenuProps[]
}

export const enum AnnualGiftInputs {
    YEARS_UNTIL_FLOW = "yearsUntilFlow",
    YEARS_OF_FLOW = "yearsOfFlow",
    STARTING_STRATEGY_YEAR = "startingStrategyYear",
    ENDING_STRATEGY_YEAR = "endingStrategyYear",
    STARTING_AGE = "startingClientAge",
    ENDING_AGE = "endingClientAge"
}

const AddEditAnnualGifting: React.FC<AddEditAnnualGiftingProps> = ({
                                                                       header,
                                                                       refreshStrategiesSummary,
                                                                       items
                                                                   }: AddEditAnnualGiftingProps) => {
    const {id, strategyId} = useParams<RouteWithIdAndStrategyId>();

    const strategies = useAppSelector(selectAnnualGiftingStrategies);
    let strategy: AnnualGiftingStrategy = initialAnnualGiftingStrategy;

    if (strategyId !== undefined) {
        strategy = strategies.find((s => s.strategyId === strategyId))!
    }

    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const [description, setDescription] = useState<string>(strategy.description);
    const [contributionAmountDisplayValue, setContributionAmountDisplayValue] = useState<number>(strategy.contributionAmount);
    const [contributionAmount, setContributionAmount] = useState<number>(strategy.contributionAmount);
    const [yearsUntilFlow, setYearsUntilFlow] = useState<number>(strategy.yearsUntilFlow);
    const [yearsUntilFlowDisplayValue, setYearsUntilFlowDisplayValue] = useState<number>(strategy.yearsUntilFlow);
    const [yearsOfFlow, setYearsOfFlow] = useState<number>(strategy.yearsOfFlow);
    const [yearsOfFlowDisplayValue, setYearsOfFlowDisplayValue] = useState<number>(strategy.yearsOfFlow);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [startYearDisplayValue, setStartYearDisplayValue] = useState<number | string>("");
    const [endYearDisplayValue, setEndYearDisplayValue] = useState<number | string>("");
    const [startAgeDisplayValue, setStartAgeDisplayValue] = useState<number | string>("");
    const [endAgeDisplayValue, setEndAgeDisplayValue] = useState<number | string>("");

    const [riskAssetGrowthRate, setRiskAssetGrowthRate] = useState<string>("");
    const [estimatedImpact, setEstimatedImpact] = useState<EstimatedImpact>(strategy.estimatedImpact)
    const [initialFormUpdated, setInitialFormUpdated] = useState<boolean>(false);

    const [error, setError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [validationError, setValidationError] = useState<boolean>(false);
    const [errorFieldToRecalculateTimeFrame, setErrorFieldToRecalculateTimeFrame] = useState<AnnualGiftInputs | undefined>(undefined);
    const [failedApiCall, setFailedApiCall] = useState<FailedAPICall>();
    const [showTempErrorMessage, setShowTempErrorMessage] = useState(false);

    const estimatedImpactAbortControllerRef = useRef<AbortController | undefined>(undefined);

    const [planningPeriodValues, setPlanningPeriodValues] = useState<PlanningPeriodValues>({
        birthdate: "",
        firstName: "",
        planningPeriod: undefined
    })

    const [initialValues, setInitialValues] = useState({
        startYear: "",
        endYear: "",
        startAge: "",
        endAge: "",
    })

    const [dateAndAgeValues, setDateAndAgeValues] = useState<DateAndAgeValues>({
        startDate: "",
        endDate: "",
        startYear: "",
        endYear: "",
        startAge: "",
        endAge: ""
    })

    const getEstimatedImpact = (requestBody: AnnualGiftingStrategy, signal?: AbortSignal): Promise<EstimatedImpact> => {
        return wealthPOApiClient.getEstimatedImpactForAnnualGifting(id, requestBody, signal);
    }

    const fetchEstimatedImpact = async () => {
        estimatedImpactAbortControllerRef.current = undefined;

        if (initialFormUpdated) {
            estimatedImpactAbortControllerRef.current = new AbortController();
            const {signal} = estimatedImpactAbortControllerRef.current;

            const requestBody = {
                strategyId: undefined,
                strategyType: StrategyType.ANNUAL_GIFTING,
                description: "Annual Gifting",
                contributionAmount: contributionAmount,
                yearsUntilFlow,
                yearsOfFlow
            } as AnnualGiftingStrategy

            getEstimatedImpact(requestBody, signal)
                .then(estimatedImpactResponse => {
                    setEstimatedImpact(estimatedImpactResponse);
                })
                .catch((err) => {
                    setFailedApiCall(FailedAPICall.ESTIMATED_IMPACT);
                    openErrorModal(err);
                });
        }
    }

    const fetchReferenceDataAndInvestorGroup = async () => {
        const apiRequests: [Promise<GrowthRates>, Promise<InvestorGroupType>] = [
            wealthPOApiClient.getGrowthRatesReferenceData(id),
            clientManagementApiClient.getInvestorGroup(id)
        ]

        try {
            let [growthRates, investorGroup] = await Promise.all(apiRequests);

            const riskAssetRateDto = growthRates.rateDtos.find(rateDto => rateDto.id === "RiskAsset");
            if (riskAssetRateDto) {
                const growthRate = riskAssetRateDto.value * 100;
                const s = growthRate.toPrecision(3);
                setRiskAssetGrowthRate(s)
            }

            const {
                planningPeriodComputedValues,
                dateAndAgeComputedValues
            } = processInvestorGroupResponse(investorGroup, yearsUntilFlow, yearsOfFlow);

            setPlanningPeriodValues(planningPeriodComputedValues);
            setDisplayAndRecomputedValues(dateAndAgeComputedValues);

            setInitialValues({
                startYear: dateAndAgeComputedValues.startYear.toString(),
                endYear: dateAndAgeComputedValues.endYear.toString(),
                startAge: dateAndAgeComputedValues.startAge.toString(),
                endAge: dateAndAgeComputedValues.endAge.toString(),
            })
        } catch (err) {
            openErrorModal(err);
        }
    }

    const handleSave = async (): Promise<HandleSaveResponse> => {
        setValidationError(false);

        const valid = validateDescription(description);

        let response = {
            isSuccess: valid,
            errors: {
                hasValidationError: false,
                hasCommunicationError: false,
                traceId: ""
            }
        } as HandleSaveResponse

        if (!valid) {
            setValidationError(true)

            response.isSuccess = false
            response.errors.hasValidationError = true
            return response;
        } else {
            let requestBody = {
                strategyType: StrategyType.ANNUAL_GIFTING,
                contributionAmount,
                description,
                yearsUntilFlow,
                yearsOfFlow
            } as AnnualGiftingStrategy

            try {
                if (strategyId) {
                    requestBody = {
                        ...requestBody,
                        strategyId,
                    };

                    await wealthPOApiClient.editAnnualGiftingStrategy(id, requestBody);
                } else {
                    await wealthPOApiClient.createAnnualGiftingStrategy(id, requestBody);
                }
            } catch (saveError: Error | any) {
                response.isSuccess = false;
                response.errors.hasCommunicationError = true;
                response.errors.traceId = saveError.headers.get('trace-id');
            }
        }

        return response;
    }

    const validateAndRecomputeValues = (changedInputField: AnnualGiftInputs, inputValue: any, originalValue: number | string, setDisplayValue: (value: number) => void) => {
        setErrorFieldToRecalculateTimeFrame(undefined)

        if (isNaN(parseInt(inputValue))) {
            setDisplayValue(Number(originalValue));
            return;
        }

        let newValue: number = Number(inputValue);

        let validationResponse = {
            errorPresent: false,
            newYearsUntilFlow: yearsUntilFlow,
            newYearsOfFlow: yearsOfFlow
        }

        switch (changedInputField) {
            case AnnualGiftInputs.YEARS_UNTIL_FLOW:
                validationResponse = validateYearsUntilFlow(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues);
                break;
            case AnnualGiftInputs.YEARS_OF_FLOW:
                validationResponse = validateYearsOfFlow(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues)
                break;
            case AnnualGiftInputs.STARTING_STRATEGY_YEAR:
                validationResponse = validateStartYear(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues, dateAndAgeValues)
                break;
            case AnnualGiftInputs.ENDING_STRATEGY_YEAR:
                validationResponse = validateEndYear(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues, dateAndAgeValues)
                break;
            case AnnualGiftInputs.STARTING_AGE:
                validationResponse = validateStartAge(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues, dateAndAgeValues)
                break;
            case AnnualGiftInputs.ENDING_AGE:
                validationResponse = validateEndAge(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues, dateAndAgeValues)
                break;
            default:
                setErrorFieldToRecalculateTimeFrame(undefined)
                break;
        }

        if (validationResponse.errorPresent) {
            setErrorFieldToRecalculateTimeFrame(changedInputField)

            if (changedInputField === AnnualGiftInputs.YEARS_UNTIL_FLOW) {
                setDisplayValue(yearsUntilFlow);
            } else if (changedInputField === AnnualGiftInputs.YEARS_OF_FLOW) {
                setDisplayValue(yearsOfFlow);
            } else {
                setDisplayAndRecomputedValues(dateAndAgeValues);
            }
        }

        if (validationResponse.newYearsUntilFlow !== yearsUntilFlow) {
            setYearsUntilFlowDisplayValue(validationResponse.newYearsUntilFlow)
            setYearsUntilFlow(validationResponse.newYearsUntilFlow)
            return;
        }

        if (validationResponse.newYearsOfFlow !== yearsOfFlow) {
            setYearsOfFlowDisplayValue(validationResponse.newYearsOfFlow)
            setYearsOfFlow(validationResponse.newYearsOfFlow)
        }
    }

    const setDisplayAndRecomputedValues = (dateAndAgeComputedValues: DateAndAgeValues) => {
        setStartDate(dateAndAgeComputedValues.startDate)
        setEndDate(dateAndAgeComputedValues.endDate)
        setStartYearDisplayValue(dateAndAgeComputedValues.startYear)
        setEndYearDisplayValue(dateAndAgeComputedValues.endYear)
        setStartAgeDisplayValue(dateAndAgeComputedValues.startAge)
        setEndAgeDisplayValue(dateAndAgeComputedValues.endAge)
        setDateAndAgeValues(dateAndAgeComputedValues);
    }

    const handleCancel = () => {
        return description !== strategy.description
            || contributionAmount !== strategy.contributionAmount
            || yearsUntilFlow !== strategy.yearsUntilFlow
            || yearsOfFlow !== strategy.yearsOfFlow
            || dateAndAgeValues.startYear !== initialValues.startYear
            || dateAndAgeValues.endYear !== initialValues.endYear
            || dateAndAgeValues.startAge !== initialValues.startAge
            || dateAndAgeValues.endAge !== initialValues.endAge
    }

    const openErrorModal = (e: Error | any) => {
        setError({
            isOpen: true,
            header: "Unable to Load This Page",
            message: (
                <>
                    <p>Check your VPN connection and retry.</p>
                    <p>If the problem persists, contact <a href="mailto:GPIITSupport@ntrs.com">GPS Technical
                        Support</a> at GPIITSupport@ntrs.com and provide the following details:</p>
                </>
            ),
            profileId: id,
            time: new Date(),
            errorDetail: `Failed to load ${header} page (${e.status})`,
            operationId: e.headers.get('trace-id')
        })
    }

    const closeErrorModal = () => {
        setError({...error, isOpen: false});
    };

    const handleRetryClickInCommunicationErrorModal = () => {
        closeErrorModal();

        if (failedApiCall === FailedAPICall.ESTIMATED_IMPACT) {
            fetchEstimatedImpact().then();
        } else {
            fetchReferenceDataAndInvestorGroup().then();
        }
    }

    const handleCloseClickInCommunicationErrorModal = () => {
        closeErrorModal();
        setShowTempErrorMessage(true);
    }

    useEffect(() => {
        fetchReferenceDataAndInvestorGroup().then();
    }, [id])

    useEffect(() => {
        let dateAndAgeComputedValues: DateAndAgeValues = getDateAndAgeValues(planningPeriodValues.birthdate, yearsUntilFlow, yearsOfFlow);
        setDisplayAndRecomputedValues(dateAndAgeComputedValues);
    }, [yearsUntilFlow, yearsOfFlow])

    useEffect(() => {
        fetchEstimatedImpact().then();

        return () => {
            if (estimatedImpactAbortControllerRef.current) {
                estimatedImpactAbortControllerRef.current.abort();
            }
        }
    }, [contributionAmount, yearsOfFlow, yearsUntilFlow, initialFormUpdated])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setErrorFieldToRecalculateTimeFrame(undefined);
        }, 6000);
        return () => {
            clearTimeout(timeout);
        }
    }, [errorFieldToRecalculateTimeFrame]);


    const memoizedErrorMessage = useMemo(() => {
        return errorFieldToRecalculateTimeFrame && (
            <>
                <div></div>
                <div data-testid={`error-message-${errorFieldToRecalculateTimeFrame}`}
                     className="strategy-time-frame-error-msg">
                    Strategy Time Frame cannot exceed the Planning Period.
                </div>
            </>
        )
    }, [errorFieldToRecalculateTimeFrame])

    const memoizedEstimatedImpact: DataEntrySummaryItem[] = useMemo(() => {
        return [
            {
                label: "Future Value of Gift",
                value: (estimatedImpact.amountToBeneficiaries !== undefined) ? formatCurrency(estimatedImpact.amountToBeneficiaries) : undefined,
                testId: "amountToBeneficiaries",
            } as DataEntrySummaryItem,
            {
                label: "Est. Estate Tax",
                value: (estimatedImpact.impactToEstEstateTax !== undefined) ? formatCurrency(estimatedImpact.impactToEstEstateTax) : undefined,
                testId: "estEstateTax",
            } as DataEntrySummaryItem,
        ]
    }, [estimatedImpact]);

    const memoizedStrategySummary: DataEntrySummaryItem[] = useMemo(() => {
        return [
            {
                label: "Annual Flow",
                value: formatCurrency(contributionAmount),
                testId: "strategySummaryContributionAmount"
            } as DataEntrySummaryItem,
            ...([{
                    label: "Years until Flow",
                    value: yearsUntilFlow,
                    testId: "strategySummaryYearsUntilFlow"
                } as DataEntrySummaryItem]
            ),
            {
                label: "Years of Flow",
                value: yearsOfFlow,
                testId: "strategySummaryYearsOfFlow"
            }
        ]
    }, [contributionAmount, yearsUntilFlow, yearsOfFlow])

    const renderPlanningPeriodRow = (testId: string) => {
        return (
            <div className="layout-data-entry-form__field"
                 data-testid={testId}>
                <label className={"h5"}>Planning Period</label>
                <span
                    className={errorFieldToRecalculateTimeFrame && 'error-color'}
                    role="planningPeriod"
                    aria-label="planningPeriod">{`${planningPeriodValues.planningPeriod?.numberOfYears} years (${planningPeriodValues.firstName})`}</span>
            </div>
        )
    }

    if (error.isOpen) {
        return (
            <GenericErrorModal
                errorModalData={error}
                onClickButton={handleRetryClickInCommunicationErrorModal}
                onRequestClose={handleCloseClickInCommunicationErrorModal}
                buttonText="Retry"
                buttonProps={
                    {
                        primary: true,
                        className: 'full-width center-align',
                        iconPosition: 'left',
                        iconName: 'refresh'
                    }
                }
                showAlertIcon={false}
            />
        )
    }

    if (showTempErrorMessage) {
        return (
            <EmptyStateContainer
                className="no-wpo-summary-placeholder"
                title="Unable to Load This Page"
                size="large"
                description="Try again later."
            />
        )

    }

    return (
        <ScrollableContainer id={"add-edit-annual-gifting-scroll-container"} className="wealth-plan-optimizer">
            <StrategyHeader
                header={header}
                handleSave={handleSave}
                handleCancel={handleCancel}
                refreshStrategySummary={refreshStrategiesSummary}
            />

            <div className="wealthpo-strategy__form">
                <article>
                    <section aria-label="Strategy Details">
                        <UnderlinedHeader
                            className={"wealthpo-strategy__underlined-header"}
                            primaryText="Strategy Details"
                            rightAlignedContent={<RequiredFieldsSubheader/>}
                        />
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"} data-testid={'description'}>Description<RedAsterisk/></label>
                            <Input
                                name="descriptionField"
                                aria-label="description"
                                aria-labelledby="descriptionFieldInput-label"
                                id="descriptionFieldInput"
                                removeMarginTop
                                size="medium"
                                type="text"
                                value={description}
                                readOnly={isProfileWithProposalsOrArchived}
                                error={validationError ? "Description is required." : undefined}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                            />
                        </div>
                        <div className="layout-data-entry-form__field" style={{alignItems: "start"}}>
                            <label className={"h5"} data-testid={'contributionAmountInput'}>Annual Flow</label>
                            <div className="annual-flow-input-field">
                                <CurrencyInput
                                    name="contributionAmountField"
                                    aria-label="contributionAmountInput"
                                    id="contributionAmountFieldInput"
                                    size="medium"
                                    value={contributionAmountDisplayValue}
                                    readOnly={isProfileWithProposalsOrArchived}
                                    textAlign='left'
                                    onChangeValue={e => isNaN(parseInt(e.target.value)) ? setContributionAmountDisplayValue(0) : setContributionAmountDisplayValue(parseInt(e.target.value))}
                                    onBlur={() => {
                                        setContributionAmount(contributionAmountDisplayValue);
                                        if (!initialFormUpdated) {
                                            setInitialFormUpdated(true);
                                        }
                                    }}
                                />
                                <span className={"subheader"}>The annual exclusion amount per beneficiary for 2025 is $19,000.</span>
                            </div>
                        </div>
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"}>Growth Rate</label>
                            <div className="growth-rate-value">
                                <span aria-label={'growthRate'}>Triple Net Risk Asset ({riskAssetGrowthRate}%)</span>
                            </div>
                        </div>
                    </section>
                    <section aria-label="Strategy Time Frame">
                        <UnderlinedHeader
                            className={"wealthpo-strategy__underlined-header"}
                            primaryText="Strategy Time Frame"
                            secondaryContent={
                                <div data-testid="strategyTimeFrameInfoIcon">
                                    <Icon className="info-icon popover-trigger popoverContainer" ariaHidden={false}
                                          name="status_info_outline" type="info"/>
                                </div>
                            }
                        />
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"} data-testid={"yearsUntilFlow"}>Years until Flow</label>
                            <div className="display-flex" data-testid="strategyTimeFrameYearsUntilFlow">
                                <YearsInput style={{width: "134px"}}
                                            name={"yearsUntilFlow"}
                                            aria-label={"yearsUntilFlowInput"}
                                            className={"StrategyWithTimeFrameYearsUntilFlow"}
                                            value={yearsUntilFlowDisplayValue}
                                            size={"medium"}
                                            onBlur={() => {
                                                validateAndRecomputeValues(AnnualGiftInputs.YEARS_UNTIL_FLOW, yearsUntilFlowDisplayValue, yearsUntilFlow, setYearsUntilFlowDisplayValue);
                                                if (!initialFormUpdated) {
                                                    setInitialFormUpdated(true);
                                                }
                                            }}
                                            readOnly={isProfileWithProposalsOrArchived ? isProfileWithProposalsOrArchived : false}
                                            disabled={false}
                                            onChangeValue={(_e, value) => {
                                                setYearsUntilFlowDisplayValue(value)
                                            }}
                                />
                                <div className="strategyTimeFrameDate"
                                     data-testid="strategyTimeFrameYearsUntilFlowStartDate">
                                    <label>
                                        <b>Start Date</b>
                                    </label>
                                    <span role="startDate"
                                          aria-label="start-date">{startDate}</span>
                                </div>
                            </div>
                        </div>
                        {(errorFieldToRecalculateTimeFrame && errorFieldToRecalculateTimeFrame === AnnualGiftInputs.YEARS_UNTIL_FLOW) && memoizedErrorMessage}
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"} data-testid={"yearsOfFlow"}>Years of Flow</label>
                            <div className="display-flex" data-testid="strategyTimeFrameYearsOfFlow">
                                <YearsInput style={{width: "134px"}}
                                            name={"yearsOfFlow"}
                                            aria-label={"yearsOfFlowInput"}
                                            className={"StrategyWithTimeFrameYearsOfFlow"}
                                            value={yearsOfFlowDisplayValue}
                                            size={"medium"}
                                            onBlur={() => {
                                                validateAndRecomputeValues(AnnualGiftInputs.YEARS_OF_FLOW, yearsOfFlowDisplayValue, yearsOfFlow, setYearsOfFlowDisplayValue);
                                                if (!initialFormUpdated) {
                                                    setInitialFormUpdated(true);
                                                }
                                            }}
                                            readOnly={isProfileWithProposalsOrArchived ? isProfileWithProposalsOrArchived : false}
                                            disabled={false}
                                            onChangeValue={(_e, value) => {
                                                setYearsOfFlowDisplayValue(value)
                                            }}
                                />
                                <div className="strategyTimeFrameDate"
                                     data-testid="strategyTimeFrameYearsOfFlowEndDate">
                                    <label>
                                        <b>End Date</b>
                                    </label>
                                    <span role="endDate"
                                          aria-label="end-date">{endDate}</span>
                                </div>
                            </div>
                        </div>
                        {(errorFieldToRecalculateTimeFrame && errorFieldToRecalculateTimeFrame === AnnualGiftInputs.YEARS_OF_FLOW) && memoizedErrorMessage}
                        <div className="layout-data-entry-form__field" data-testid="strategyTimeFrameStrategyYears">
                            <label className={"h5"}>Strategy Years</label>
                            <RangeInputs
                                left={{
                                    value: startYearDisplayValue,
                                    ariaLabel: "startYear",
                                    onChange: (e => setStartYearDisplayValue(isNaN(Number(e.target.value)) ? "" : Number(e.target.value))),
                                    readOnly: isProfileWithProposalsOrArchived,
                                    onBlur: (() => validateAndRecomputeValues(AnnualGiftInputs.STARTING_STRATEGY_YEAR, startYearDisplayValue, dateAndAgeValues.startYear, setStartYearDisplayValue))
                                } as RangeInputProp}
                                right={{
                                    value: endYearDisplayValue,
                                    ariaLabel: "endYear",
                                    onChange: (e => setEndYearDisplayValue(isNaN(Number(e.target.value)) ? "" : Number(e.target.value))),
                                    readOnly: isProfileWithProposalsOrArchived,
                                    onBlur: (() => validateAndRecomputeValues(AnnualGiftInputs.ENDING_STRATEGY_YEAR, endYearDisplayValue, dateAndAgeValues.endYear, setEndYearDisplayValue))
                                } as RangeInputProp}
                            />
                        </div>
                        {(errorFieldToRecalculateTimeFrame && errorFieldToRecalculateTimeFrame === AnnualGiftInputs.STARTING_STRATEGY_YEAR) && memoizedErrorMessage}
                        {(errorFieldToRecalculateTimeFrame && errorFieldToRecalculateTimeFrame === AnnualGiftInputs.ENDING_STRATEGY_YEAR) && memoizedErrorMessage}
                        <div className="layout-data-entry-form__field" data-testid="strategyTimeFrameAgeRange">
                            <label className={"h5"}>Client's Age Range</label>
                            <RangeInputs
                                left={{
                                    value: startAgeDisplayValue,
                                    ariaLabel: "startAge",
                                    onChange: (e => setStartAgeDisplayValue(isNaN(Number(e.target.value)) ? "" : Number(e.target.value))),
                                    readOnly: isProfileWithProposalsOrArchived,
                                    onBlur: (() => validateAndRecomputeValues(AnnualGiftInputs.STARTING_AGE, startAgeDisplayValue, dateAndAgeValues.startAge, setStartAgeDisplayValue))
                                } as RangeInputProp}
                                right={{
                                    value: endAgeDisplayValue,
                                    ariaLabel: "endAge",
                                    onChange: (e => setEndAgeDisplayValue(isNaN(Number(e.target.value)) ? "" : Number(e.target.value))),
                                    readOnly: isProfileWithProposalsOrArchived,
                                    onBlur: (() => validateAndRecomputeValues(AnnualGiftInputs.ENDING_AGE, endAgeDisplayValue, dateAndAgeValues.endAge, setEndAgeDisplayValue)
                                    )
                                } as RangeInputProp}
                            />
                        </div>
                        {(errorFieldToRecalculateTimeFrame && errorFieldToRecalculateTimeFrame === AnnualGiftInputs.STARTING_AGE) && memoizedErrorMessage}
                        {(errorFieldToRecalculateTimeFrame && errorFieldToRecalculateTimeFrame === AnnualGiftInputs.ENDING_AGE) && memoizedErrorMessage}
                        {renderPlanningPeriodRow("strategyTimeFramePlanningPeriod")}
                    </section>
                </article>

                <StrategySidebar
                    strategySummary={memoizedStrategySummary}
                    estimatedImpact={memoizedEstimatedImpact}
                    items={items}
                    strategyName={"Annual Gifting"}
                />
            </div>
        </ScrollableContainer>
    )
}

export default AddEditAnnualGifting;