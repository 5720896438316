import React from "react";
import {Col, Row} from "react-grid-system";
import _ from "lodash";
import {truncateCurrency} from "../../../utils/format";
import ChartStatusLine from "../ChartStatusLine";
import {COLOR_NT_GREY} from "../../../constants/colors";

export type BarchartUnderlayProps = {
    totalStacksCount: number;
    width: number;
    maxValue: number;
    titleCellWidth: number;
    stackContainerWidth: number;
    numberOfColumns: number;
    minimumColumn: number;
    rightBorderDivider: boolean;
    className: string;
}

export const BarchartUnderlay: React.FC<BarchartUnderlayProps> = ({
                                                                      totalStacksCount,
                                                                      width,
                                                                      maxValue,
                                                                      titleCellWidth,
                                                                      stackContainerWidth,
                                                                      numberOfColumns,
                                                                      minimumColumn,
                                                                      rightBorderDivider,
                                                                      className
                                                                  }) => {
    return (
        <>
            <Row className={className}>
                <Col width={titleCellWidth} style={{minWidth: titleCellWidth}}>
                    {_.range(numberOfColumns).map((index) => {
                        const verticalOffset = `${(index * 20)}`;
                        const lineWidth = (totalStacksCount <= minimumColumn ? 6 : totalStacksCount) * width;
                        const labelNumber = truncateCurrency((maxValue * ((5 - index) * 0.2)), {
                            formatAsCurrency: false,
                            includeUnits: true,
                            maxValue: maxValue
                        });
                        return <div key={index}>
                            <ChartStatusLine
                                id={'barChartUnderlay'}
                                label={`${labelNumber}`}
                                height={'1px'}
                                zIndex={0}
                                width={`calc(${lineWidth}px + 100%)`}
                                lineColor={COLOR_NT_GREY}
                                verticalOffset={verticalOffset}
                                marginLeft={0}
                                textColor={''}
                                isAssetReliance={true}
                            />
                        </div>
                    })}
                </Col>
                {_.range(totalStacksCount).map((index) => {
                    const isStackForProfile = totalStacksCount == 1 && rightBorderDivider;
                    return <React.Fragment key={`UnderlayColumn` + index}>
                        <span className="divider"></span>
                        <Col md={stackContainerWidth}></Col>
                        {isStackForProfile ? <span className="divider"></span> : <span/>}
                    </React.Fragment>
                })}
            </Row>
        </>
    );
}