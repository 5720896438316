import {ProfileType} from "../ClientManagement/models/ProfileResponse";
import {selectApprovedProfile, selectProfile} from "../ClientManagement/ClientProfile/activeProfileSlice";
import {useAppSelector} from "../store/hooks";
import {selectProposals} from "../ClientManagement/Proposals/proposalsSlice";
import {selectReleaseToggles} from "../ReleaseToggles/releaseTogglesSlice";


const useProfileEditableState = () => {
    const profileState = useAppSelector(selectProfile);
    const proposalsState = useAppSelector(selectProposals);
    const releaseToggles = useAppSelector(selectReleaseToggles)
    const approvedProfileState = useAppSelector(selectApprovedProfile);

    // Read Only Flags
    const isArchiveReadOnly = profileState?.archived || !profileState.activeClient;
    const isProfileWithProposalsReadOnly = releaseToggles?.enableBlockProfileEditsWithActiveProposals
        && profileState.type === ProfileType.PROFILE
        && proposalsState.length > 0;

    // Archived Flags
    const isApprovedProfileArchived = approvedProfileState?.archived || !approvedProfileState.activeClient;
    const isProfileWithProposalsOrArchived = profileState?.archived || !profileState.activeClient || isProfileWithProposalsReadOnly;
    const isArchivedActiveClient = profileState?.archived && profileState?.activeClient;

    return {
        isArchiveReadOnly,
        isProfileWithProposalsReadOnly,
        isProfileWithProposalsOrArchived,
        isArchivedActiveClient,
        isApprovedProfileArchived
    };
}

export default useProfileEditableState;
