import {useHistory, useParams} from "react-router-dom";
import {AddDistributionButtonDropdown} from "./AddDistributionButtonDropdown";
import {RouteWithIdAndFlowchartId} from "src/routes/types";
import {useAppSelector} from "src/store/hooks";
import {getEstateFlowcharts} from "../EstateFlowChartSlice";
import {Beneficiaries, RevocableTrust, Trust} from "../EstateFlowchartTypes";
import React, {useEffect, useState} from "react";
import {isValid} from "./validations";
import CopyButtonDropdown from "./CopyButtonDropdown";
import TrustForm from "./TrustForm";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";

type BeneficiariesFirstDeathContentProps = {
    updateRevocableTrust: Function;
    saveHasBeenClicked: boolean;
    familyMembersResponse: MemberGroup
};

/**
 * Component for displaying and managing the beneficiaries at the first death in an estate flowchart.
 *
 * @component
 * @param {BeneficiariesFirstDeathContentProps} props - The properties for the component.
 * @param {Function} props.updatedRevocableTrust - Function to update the revocable trust with new beneficiary information.
 * @param {boolean} props.saveHasBeenClicked - Flag indicating if the save button has been clicked.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <BeneficiariesFirstDeathContent
 *   updatedRevocableTrust={updatedRevocableTrustFunction}
 *   saveHasBeenClicked={true}
 * />
 *
 * @remarks
 * This component uses the `useParams` hook to extract route parameters and the `useAppSelector` hook to access the estate flowcharts from the state.
 * It initializes the beneficiary information based on the revocable trust data and updates the state accordingly.
 * The component conditionally renders either a `MaritalTrustForm` or an `AddDistributionButtonDropdown` based on the presence of beneficiary information.
 *
 */
const BeneficiariesFirstDeathContent: React.FC<BeneficiariesFirstDeathContentProps> =
    ({
         updateRevocableTrust: setRevocableTrust,
         saveHasBeenClicked,
         familyMembersResponse
     }: BeneficiariesFirstDeathContentProps): JSX.Element => {

        const {flowchartId} = useParams<RouteWithIdAndFlowchartId>();
        const history = useHistory();
        const estateFlowchartsInState = useAppSelector(getEstateFlowcharts);
        const [beneficiaryInformation, setBeneficiaryInformation] = useState<Beneficiaries>({} as Beneficiaries);

        const buildInitialForm = () => {
            const initializeTrust = (revocableTrust: RevocableTrust | undefined) => {
                if (revocableTrust?.beneficiaries) {
                    setBeneficiaryInformation(revocableTrust.beneficiaries);
                }
            };

            if (estateFlowchartsInState.length > 0) {
                const revocableTrust = estateFlowchartsInState.find(
                    (flowchart) => flowchart.flowchartId === flowchartId)?.revocableTrust;
                initializeTrust(revocableTrust);
            }
        };

        useEffect(() => {
            const id = history.location.hash?.slice(1);
            const container = document.getElementById(
                "client-profile-scroll-container"
            );
            if (id) {
                setTimeout(() => {
                    const element = document.getElementById(id);
                    if (element) {
                        const headerOffset = 97;
                        const elementPosition = element.offsetTop;
                        const offsetPosition = elementPosition - headerOffset;
                        container?.scrollTo({top: offsetPosition, behavior: 'smooth'})
                    }
                }, 500); // instead of a delay scroll after complete render of page
            } else {
                container?.scrollTo(0, 0);
            }
        }, []);

        useEffect(() => {
            buildInitialForm();
        }, [estateFlowchartsInState]);

        useEffect(() => {
            const revocableTrust = estateFlowchartsInState.find(
                (flowchart) => flowchart.flowchartId === flowchartId)?.revocableTrust;
            setRevocableTrust({
                ...revocableTrust,
                beneficiaries: JSON.stringify(beneficiaryInformation) === JSON.stringify({}) ? null : beneficiaryInformation,
            } as RevocableTrust);
        }, [beneficiaryInformation]);

        const isThereAnyTrustWithValidTrustName = (trusts: Trust[], key: keyof Trust) => {
            return trusts.some(trust => trust[key] !== null && trust[key] !== undefined && trust[key] !== '');
        }

        const content = () => {
            const revocableTrust = estateFlowchartsInState
                .find(flowchart => flowchart.flowchartId === flowchartId)?.revocableTrust;

            if (revocableTrust && beneficiaryInformation.firstDeath?.trusts?.length) {
                const trustForms =
                    <>
                        {beneficiaryInformation.firstDeath.trusts.map((trust, index) => (
                            <div id={trust.trustName} key={"trust-container-" + index}>
                                <TrustForm
                                    key={index}
                                    index={index}
                                    trustInfo={trust}
                                    saveHasBeenClicked={saveHasBeenClicked}
                                    isValid={isValid}
                                    familyMembersRes={familyMembersResponse}
                                    updateBeneficiaryInformation={(updatedTrust: Trust | undefined) => {
                                        const trusts = [...(beneficiaryInformation.firstDeath.trusts || [])];
                                        if (updatedTrust) {
                                            trusts[index] = updatedTrust;
                                            setBeneficiaryInformation({
                                                ...beneficiaryInformation,
                                                firstDeath: {trusts: trusts}
                                            });
                                        } else {
                                            trusts.splice(index, 1);
                                            setBeneficiaryInformation({
                                                ...beneficiaryInformation,
                                                firstDeath: {trusts: trusts}
                                            });
                                        }
                                    }
                                    }
                                />
                            </div>
                        ))}

                        <div className="add-or-copy-distribution-container">
                            <h2 className={"add-copy-trust-heading"}>
                                Continue to add or copy distributions
                            </h2>
                            <div>
                                <AddDistributionButtonDropdown
                                    className={"add-distribution-button"}
                                    beneficiariesInformation={beneficiaryInformation}
                                    updateBeneficiaryInformation={setBeneficiaryInformation}
                                />
                                <CopyButtonDropdown
                                    className="copy-distribution-button"
                                    beneficiaryInfo={beneficiaryInformation}
                                    disabled={!isThereAnyTrustWithValidTrustName(beneficiaryInformation.firstDeath.trusts, "trustName")}
                                    updateBeneficiaryInformation={setBeneficiaryInformation}
                                />
                            </div>
                        </div>
                    </>
                return (
                    trustForms
                );

            } else {
                return (
                    <AddDistributionButtonDropdown
                        className={"add-distribution-button"}
                        beneficiariesInformation={beneficiaryInformation}
                        updateBeneficiaryInformation={setBeneficiaryInformation}
                    />
                );
            }
        };

        return (
            <div className="beneficiary-first-death-form">
                <div className="header" id="root">
                    <h1>Beneficiaries at First Death</h1>
                    <h6>
                        Begin by adding new trusts or distributions to be created upon death
                        of first grantor.
                    </h6>
                </div>
                {content()}
            </div>
        );
    };
export default BeneficiariesFirstDeathContent;