export enum RiskAssetClass {
    US_EQUITY = "US Equity",
    DEVELOPED_EX_US_EQUITY = "Developed Ex-US Equity",
    DEVELOPED_EQUITY = "Developed Equity",
    EMERGING_MARKETS = "Emerging Markets",
    GLOBAL_EQUITY = "Global Equity",
    PRIVATE_INVESTMENTS = "Private Investments",
    HEDGE_FUNDS = "Hedge Funds",
    GLOBAL_REAL_ESTATE = "Global Real Estate",
    GLOBAL_INFRASTRUCTURE = "Global Infrastructure",
    GLOBAL_NATURAL_RESOURCES = "Global Natural Resources",
    HIGH_YIELD_FIXED_INCOME = "High Yield Fixed Income",
    OTHER_RISK_ASSET = "Other Risk Assets",
    MULTI_ASSET_CLASS = "Multi Asset Class",
    OTHER_ASSET = "Other Assets"
}

export const riskAssetClasses: string[] = Object.values(RiskAssetClass);

export enum RiskControlClass {
    CONVENTIONAL_FIXED_INCOME = "Conventional Fixed Income",
    INFLATION_PROTECTED_FIXED_INCOME = "Inflation Protected Fixed Income",
    OTHER_RISK_CONTROL_ASSET = "Other Risk Control Assets",
    CASH_AND_CASH_EQUIVALENTS = "Cash & Cash Equivalents"
}

export enum AssetClassifications {
    PUBLIC_EQUITY = "Public Equity",
    PUBLIC_HIGH_YIELD = "Public High Yield",
    PUBLIC_REAL_ASSETS = "Public Real Assets",
    INVESTMENT_GRADE_FIXED_INCOME = "Investment Grade Fixed Income",
    CASH = "Cash",
    OTHER_RISK_ASSET = "Other Risk Asset",
    OTHER_ASSET = "Other Asset",
    OTHER_RISK_CONTROL_ASSET = "Other Risk Control Asset"
}

export const riskControlClasses: string[] = Object.values(RiskControlClass);