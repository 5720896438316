import {useHistory, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import AssetsViewContext from "../common/AssetsViewContext";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {useAppInsights} from "../../AppInsights";
import {HeldAwayAccount, HeldAwayAccountHoldingsRequest} from "../models/InvestmentProgram";
import {HoldingAssetSubclassDetails} from "../models/Holding";
import {RouteWithAssetId} from "../../routes/types";
import {AssetClassifications} from "../models/AssetClassifications";
import {assetsApiClient} from "../AssetsApiClient";
import {IPAccountsHoldingSummarySubclassDetailsRow} from "./IPAccountsHoldingSummarySubclassDetailsRow";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import HoldingsInfo from "../StandaloneAccount/Holdings/HoldingsInfo";
import {Icon} from "../../components";
import {HoldingsScrollableContainer} from "../StandaloneAccount/Holdings/HoldingsScrollableContainer";
import {HoldingSummaryAccordion} from "../StandaloneAccount/Holdings/HoldingSummaryAccordion";
import {HoldingSummaryAssetSubclass} from "../StandaloneAccount/Holdings/HoldingSummaryAssetSubclass";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import useProfileEditableState from "../../hooks/useProfileEditableState";

export default function HeldAwayAccountHoldings() {
    const history = useHistory();
    const viewType = useContext(AssetsViewContext);
    const profile = useAppSelector(selectProfile);
    const appInsights = useAppInsights();
    const [heldAwayAccount, setHeldAwayAccount] = useState<HeldAwayAccount>();
    const [pcrHoldings, setPcrHoldings] = useState<HoldingAssetSubclassDetails[]>([]);
    const [asOfDate, setAsOfDate] = useState<string>("");
    const {assetId: heldAwayAccountId} = useParams<RouteWithAssetId>();
    const [classifications, setClassifications] = useState<AssetClassifications>();
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>();
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    useEffect(() => {
        appInsights.startTrackPage("ViewHeldAwayAccountHoldings")
        Promise.all([
            assetsApiClient.getHoldingsForHeldAwayAccounts(profile.id, heldAwayAccountId),
            assetsApiClient.getHeldAwayAccount(profile.id, heldAwayAccountId),
            assetsApiClient.getAssetClassifications(),
            clientManagementApiClient.getInvestorGroup(profile.id)
        ])
            .then(([holdingsResponse, heldAwayAccountResponse, assetClassificationResponse, investorGroupResponse]) => {
                setPcrHoldings(holdingsResponse.allHoldings);
                setAsOfDate(holdingsResponse.asOfDate || heldAwayAccountResponse.valueAsOfDate);
                setHeldAwayAccount(heldAwayAccountResponse);
                setClassifications(assetClassificationResponse);
                setInvestorGroup(investorGroupResponse);
            })
            .catch(reason => {
                console.log(reason)

            })
            .finally(() => {
                appInsights.stopTrackPage("ViewHeldAwayAccountHoldings", window.location.href, {
                    profileId: profile.id,
                    heldAwayAccountId: heldAwayAccountId
                });
            });
    }, [profile.id, heldAwayAccountId]);

    const handleLockHolding = async (index: number, checked: boolean) => {
        await handleAssetSubclassDetailUpdate(index, (assetSubclassDetail: HoldingAssetSubclassDetails) => {
            assetSubclassDetail.locked = checked
        })
    }

    const handleConcentrateHolding = async (index: number, checked: boolean) => {
        await handleAssetSubclassDetailUpdate(index, (assetSubclassDetail: HoldingAssetSubclassDetails) => {
            assetSubclassDetail.concentrated = checked
        })
    }

    const handleAssetSubclassDetailUpdate = async (index: number, changeFunction: (assetSubclassDetail: HoldingAssetSubclassDetails) => void) => {
        if (index >= 0) {
            const holdingToUpdate = pcrHoldings[index];
            changeFunction(holdingToUpdate);

            setPcrHoldings([
                ...pcrHoldings.slice(0, index),
                holdingToUpdate,
                ...pcrHoldings.slice(index + 1, pcrHoldings.length),
            ])
        }
    }

    const handleDone = async () => {

        const holdingsRequest: HeldAwayAccountHoldingsRequest = {
            asOfDate: asOfDate,
            allHoldings: pcrHoldings
        }
        assetsApiClient.putHeldAwayAccountHoldings(profile.id, heldAwayAccountId, holdingsRequest).then(response => {
            if (response.status === 200) {
                history.push(`/Profile/${profile.id}/ClientProfile/${viewType}/EditHeldAwayAccount/${heldAwayAccountId}`);
            }
        }).catch(error => {
            console.log(error);
        });

    }

    if (!pcrHoldings || !heldAwayAccount || !classifications || !investorGroup) {
        return <LoadingIndicator/>
    }

    const primaryMember = investorGroup.primaryMember;
    const partnerMember = investorGroup.partnerMember;

    const assetSubclassDetailsRowRenderer = (assetSubclassDetail: HoldingAssetSubclassDetails, index: number) =>
        <IPAccountsHoldingSummarySubclassDetailsRow
            key={`asset-subclass-details-${assetSubclassDetail.id}-${assetSubclassDetail.productName}`}
            index={index}
            assetSubclassDetail={assetSubclassDetail}
            handleLockHolding={handleLockHolding}
            handleConcentrateHolding={handleConcentrateHolding}
            disableCheckboxes={isProfileWithProposalsOrArchived}
        />;

    return (
        <div className="held-away-holdings asset-form holdings-container">
            <div className="layout-data-entry-form">
                <DataEntryHeader
                    className='dataEntryHeader'
                    title={heldAwayAccount?.financialAccountName}
                    onPrimaryButtonClick={handleDone}
                    disablePrimaryButton={isProfileWithProposalsOrArchived}
                    primaryButtonText="Done"
                    hideSecondaryButton={true}
                />
            </div>
            <HoldingsInfo
                account={{
                    institution: heldAwayAccount?.institution,
                    accountNumber: heldAwayAccount?.financialAccountNumber,
                    taxStatus: heldAwayAccount?.taxStatus,
                    memberOwnerships: heldAwayAccount?.memberOwnerships,
                    legalEntityOwnerships: heldAwayAccount?.legalEntityOwnerships,
                    ownershipCategory: heldAwayAccount?.ownershipCategory
                }}
                primaryMember={primaryMember}
                partnerMember={partnerMember}
                allHoldings={pcrHoldings}
                classifications={classifications}
            />
            <div className="holding-grid holding-grid-container">
                <div className="grid-display-contents" role="row">
                        <span className="display-flex align-items-center paddingbottom-md"
                              role="cell">
                            <Icon name="chevron_double_right"/>
                            <span className="condensed-subtitle paddingleft-md">Asset Class / Subclass</span>
                        </span>
                    <span className="condensed-subtitle" role="cell">Product</span>
                    <span className="textalign-right condensed-subtitle" role="cell">Account</span>
                    <span className="condensed-subtitle textalign-right" role="cell">Tax Cost</span>
                    <span className="condensed-subtitle textalign-right" role="cell">Market Value</span>
                    <span className="condensed-subtitle textalign-right" role="cell">Investable Value</span>
                    <span className="condensed-subtitle textalign-center" role="cell">Locked</span>
                    <span className="condensed-subtitle textalign-left" role="cell">Concen</span>
                </div>
                <div className="empty-grid-row"/>
                <HoldingsScrollableContainer>
                    <HoldingSummaryAccordion
                        riskAssetClasses={classifications?.riskAssetClasses}
                        riskControlAssetClasses={classifications?.riskControlAssetClasses}
                        assetSubclassRowRenderer={(assetSubclass, holdingsWithIndex, subclassIndex) => {
                            return <HoldingSummaryAssetSubclass
                                key={subclassIndex}
                                assetSubclass={assetSubclass}
                                holdingsWithIndex={holdingsWithIndex}
                                subclassIndex={subclassIndex}
                                assetSubclassDetailsRowRenderer={assetSubclassDetailsRowRenderer}/>
                        }}
                        selectedHoldings={pcrHoldings}
                    />
                </HoldingsScrollableContainer>
            </div>
        </div>
    )
}