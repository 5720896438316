import {ProposalRequest} from "./models/ProposalRequest";
import {get, post, put, remove} from "../core/api";
import {PlanSummaryResponse} from "../Plans/models/PlanSummaryResponse";
import {AssetRelianceResponse} from "./models/AssetRelianceResponse";
import {CurrentAllocationResponse} from "./AssetAllocation/CurrentAllocationResponse";
import {ProposedAllocationResponse} from "./AssetAllocation/ProposedAllocationResponse";
import {SaveAssetRelianceRequest} from "./models/AssetRelianceRequest";
import {HeaderTickerTabsResponse} from "./ClientProfile/HeaderTickerTabType";
import {
    AllocateRemainingAssetsProRataRequest,
    ExcludeSubclassRequest,
    ReviseAssetAllocationResponse,
    RevisedProposedAllocation
} from "./AssetAllocation/ReviseAssetAllocation";
import {DurationResponse} from "../Assets/models/Duration";

const isSuccess: (response: Response) => boolean = ({status}) => status === 200 || status === 201;

const isNoContent: (response: Response) => boolean = ({status}) => status === 204;

export interface ResponseStatus {
    traceId: string;
    isSuccessStatusCode: boolean;
}

function mapResponseStatus(response: Response, statusCodeValidator?: (response: Response) => boolean) {
    const responseStatus: ResponseStatus = {
        traceId: response.headers.get('trace-id')!,
        isSuccessStatusCode: statusCodeValidator ? statusCodeValidator(response) : false,
    }
    return responseStatus;
}

export const postProposal = (profileId: string, proposalRequest: ProposalRequest): Promise<ResponseStatus> => {
    return post(`/wealth/profiles/${profileId}/proposals`, proposalRequest)
        .then((response: Response) => mapResponseStatus(response, isSuccess))
        .catch((response: Response) => mapResponseStatus(response));
}

export const cloneProposal = (proposalId: string): Promise<ResponseStatus> => {
    return post(`/wealth/proposals/${proposalId}/clone`, null)
        .then((response: Response) => mapResponseStatus(response, isSuccess))
        .catch((response: Response) => mapResponseStatus(response));
}

export const cloneArchivedProposal = (proposalId: string, profileIdForProposal: string): Promise<ResponseStatus> => {
    return post(`/wealth/proposals/${proposalId}/cloneArchived/${profileIdForProposal}`, null)
        .then((response: Response) => mapResponseStatus(response, isSuccess))
        .catch((response: Response) => mapResponseStatus(response));
}

export const deleteProposal = (proposalId: string): Promise<boolean> => {
    return remove(`/wealth/proposals/${proposalId}`).then(isNoContent)
}

export const getPlanSummary = (profileId: string): Promise<PlanSummaryResponse> => {
    return get(`/wealth/planSummary/${profileId}`)
        .then((res: Response) => res.json())
}

export const postAssetReliance = (profileId: string, assetRelianceRequest: SaveAssetRelianceRequest): Promise<Response> => {
    return post(`/wealth/assetreliance/${profileId}`, assetRelianceRequest);
}

export const getAssetReliance = (profileId: string, abortSignal?: AbortSignal): Promise<AssetRelianceResponse> => {
    return get(`/wealth/assetreliance/${profileId}`, {signal: abortSignal})
        .then((res: Response) => res.json())
}

export const calculateDuration = async (profileId: string): Promise<DurationResponse> => {
    return get(`/wealth/duration/${profileId}`)
        .then((res: Response) => res.json())
}

export const getCurrentAllocation = (profileId: string): Promise<CurrentAllocationResponse> => {
    return get(`/wealth/assetAllocation/currentAllocation/${profileId}`)
        .then((res: Response) => res.json())
}

export const getProposedAllocation = (profileId: string, abortSignal?: AbortSignal): Promise<ProposedAllocationResponse> => {
    return get(`/wealth/assetAllocation/proposedAllocation/${profileId}`, {signal: abortSignal})
        .then((res: Response) => (res.json()))
}

export const getReviseAssetAllocation = (profileId: string, abortSignal?: AbortSignal): Promise<ReviseAssetAllocationResponse> => {
    return get(`/wealth/assetAllocation/reviseAssetAllocation/${profileId}`, {signal: abortSignal})
        .then((res: Response) => (res.json()))
}

export const getHeaderTickerTabs = (profileId: string, abortSignal?: AbortSignal): Promise<HeaderTickerTabsResponse> => {
    return get(`/wealth/headerTickerTabs/${profileId}`, {signal: abortSignal})
        .then((res: Response) => (res.json()))
}

export const deleteAssetRelianceStack = (profileId: string, stackAssetId: string | undefined): Promise<boolean> => {
    return remove(`/wealth/assetreliance/${profileId}/${stackAssetId}`).then(isNoContent)
}

export const saveRevisedAssetAllocation =  (profileId: string, updatedRevisedAssetAllocation: RevisedProposedAllocation): Promise<ReviseAssetAllocationResponse> => {
    return post(`/wealth/assetAllocation/reviseProposedAssetAllocation/${profileId}`, updatedRevisedAssetAllocation)
        .then((res: Response) => (res.json()));
}

export const excludeSubclass =  (profileId: string, excludeSubclassRequest: ExcludeSubclassRequest): Promise<ReviseAssetAllocationResponse> => {
    return post(`/wealth/assetAllocation/excludeSubclass/${profileId}`, excludeSubclassRequest)
        .then((res: Response) => (res.json()));
}

export const allocateRemainingAssetsProRata =  (profileId: string, allocateRemainingAssetsProRataRequest: AllocateRemainingAssetsProRataRequest): Promise<ReviseAssetAllocationResponse> => {
    return post(`/wealth/assetAllocation/allocateRemainingAssetsProRata/${profileId}`, allocateRemainingAssetsProRataRequest)
        .then((res: Response) => (res.json()));
}

const getAverageTargetLength = async (profileId: string, portfolioReserveTargetLength?: number): Promise<number> => {
    return get(`/wealth/portfolioReserve/averageTargetMaturity/${profileId}`, {
        queryParams: portfolioReserveTargetLength ? {
            portfolioReserveTargetLength: portfolioReserveTargetLength.toString()
        } : { }
    }).then((res: Response) => (res.json()));
}

export const promoteProposal =  (proposalId: string, profileId: string): Promise<boolean> => {
    return post(`/wealth/proposals/${proposalId}/promote/${profileId}`, null).then(isNoContent);
}

export const toggleActivatePortfolioReserve = async (profileId: string, activePortfolioReserve: boolean): Promise<void> => {
    await put(`/wealth/activatePortfolioReserve/${profileId}`, {
        activePortfolioReserve
    });
}

export const wealthManagementApiClient = {
    postProposal,
    getPlanSummary,
    cloneProposal,
    cloneArchivedProposal,
    getAssetReliance,
    postAssetReliance,
    deleteProposal,
    calculateDuration,
    getCurrentAllocation,
    getProposedAllocation,
    getHeaderTickerTabs,
    getReviseAssetAllocation,
    saveRevisedAssetAllocation,
    excludeSubclass,
    allocateRemainingAssetsProRata,
    promoteProposal,
    toggleActivatePortfolioReserve,
    getAverageTargetLength,
    deleteAssetRelianceStack
}