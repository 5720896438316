import React, {ChangeEvent, useEffect, useState} from 'react'
import {
    Button,
    Dropdown,
    DropdownItem,
    Icon,
    Name,
    RadioGroup,
    RedAsterisk,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from 'src/components'
import {
    FamilyMembers,
    Member,
    PowerOfAppointment,
    Trust,
    Trustee,
    TrusteeType,
    TrustType
} from '../EstateFlowchartTypes'
import useProfileEditableState from 'src/hooks/useProfileEditableState'
import {isCustomMember, parseFamilyMembers} from './validations'
import {MemberGroup} from 'src/ClientManagement/models/InvestorGroupType'
import {useParams} from 'react-router-dom'
import {RouteWithId} from 'src/routes/types'
import SuccessorTable from './SuccessorTable'
import MemberDropdownMultiselect from './MemberDropdownMultiselect'
import PowersOfAppointmentSection from './PowersOfAppointmentSection';
import LoadingIndicator from "../../pages/LoadingIndicator";
import DiscardModal from "../../components/Modal/DiscardModal/DiscardModal";

type TrustFormProps = {
    trustInfo: Trust
    saveHasBeenClicked: boolean,
    isValid: any,
    updateBeneficiaryInformation: Function,
    index: number,
    familyMembersRes: MemberGroup
}

/**
 * TrustForm component is responsible for rendering a form to manage the details of a trust.
 * It allows users to input and update information about the trust, including trustees, beneficiaries, funding, distributions, and termination details.
 *
 * @component
 * @param {TrustFormProps} props - The properties passed to the component.
 * @param {Trust} props.trustInfo - Information about the trust.
 * @param {boolean} props.saveHasBeenClicked - Indicates if the save button has been clicked.
 * @param {Function} props.isValid - Function to validate form fields.
 * @param {Function} props.updateBeneficiaryInformation - Function to update beneficiary information.
 * @param {number} props.index - The index of the trust form.
 *
 * @returns {JSX.Element} The rendered TrustForm component.
 *
 * @example
 * <TrustForm
 *   trustInfo={trustInfo}
 *   saveHasBeenClicked={saveHasBeenClicked}
 *   isValid={isValid}
 *   updateBeneficiaryInformation={updateBeneficiaryInformation}
 *   index={index}
 * />
 */
function TrustForm({
                       trustInfo,
                       saveHasBeenClicked,
                       isValid,
                       updateBeneficiaryInformation,
                       index,
                       familyMembersRes
                   }: Readonly<TrustFormProps>): JSX.Element {
    const [trust, setTrust] = useState<Trust>(
        {
            trustType: trustInfo.trustType,
            trustName: trustInfo.trustName,
            tags: trustInfo.tags,
            trustees: trustInfo.trustees,
            successors: trustInfo.successors,
            beneficiaries: trustInfo.beneficiaries,
            funding: trustInfo.funding,
            distributions: trustInfo.distributions,
            termination: trustInfo.termination,
            powersOfAppointment: trustInfo.powersOfAppointment,
        })
    const [trustees, setTrustees] = useState([{label: "Northern Trust", selected: false, value: ""}] as Member[])
    const [familyMembersResponse, setFamilyMembersResponse] = useState({} as FamilyMembers)
    const [beneficiaryOptions, setBeneficiaryOptions] = useState([] as Member[])
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const {id} = useParams<RouteWithId>();
    const [familyMembers, setFamilyMembers] = useState({} as MemberGroup)
    const [showCancelModal, setShowCancelModal] = useState(false);
    const listHeight = (object: any) => {
        return object.length * 34 < 280 ? (object.length * 34) + 20 : 280
    }

    const textareaLength = (text: string) => {
        return text ? text.length : 0;
    }

    const onChangeDropdown = (selectedOptions: any, type: TrusteeType) => {
        const members: Trustee[] = [];
        for (const element of selectedOptions) {
            const member: Trustee = {
                trustId: trust.beneficiaryId ?? "",
                memberType: type,
                familyMember: !isCustomMember(element.value, familyMembersResponse),
                customName: isCustomMember(element.value, familyMembersResponse) ? element.label : "",
                memberId: !isCustomMember(element.value, familyMembersResponse) ? element.value : undefined,
            };
            members.push(member);
        }

        switch (type) {
            case TrusteeType.Beneficiary:
                setTrust({...trust, beneficiaries: members} as Trust);
                break;
            case TrusteeType.BeneficiaryTrustee:
                setTrust({...trust, trustees: members} as Trust);
                break;
            default:
        }
    }

    const sortedPowersOfAppointments = (): PowerOfAppointment[] => {
        return [...trustInfo.powersOfAppointment].sort((a, b) => a.poaOrder - b.poaOrder)
    }

    const updatePowersOfAppointments = (newPowersOfAppointments: PowerOfAppointment[]) => {
        setTrust({...trust, powersOfAppointment: newPowersOfAppointments});
    }

    const createTrustHeading = (): string => {
        switch (trustInfo.trustType) {
            case TrustType.marital:
                return "Marital Trust"
            case TrustType.creditShelter:
                return "Credit Shelter Trust"
            case TrustType.intrust:
                return "In Trust"
            case TrustType.survivor:
                return "Survivor's Trust"
            default:
                return ""
        }
    }

    useEffect(() => {
        let isMounted = true;
        const parsedFamilyMembersResponse: FamilyMembers = parseFamilyMembers(familyMembersRes);
        let item = {label: "", value: "", selected: false};
        item.label = parsedFamilyMembersResponse.label;
        item.value = parsedFamilyMembersResponse.value;
        parsedFamilyMembersResponse.familyMember.push(item);
            // Set default beneficiary if not already set
            if ((!trustInfo.beneficiaries || trustInfo.beneficiaries.length === 0) &&
            (trustInfo.trustType === TrustType.marital && familyMembersRes.partnerMember?.relationshipType == "SPOUSE")) {
                trustInfo.beneficiaries = [{
                    trustId: undefined,
                    memberId: familyMembersRes.partnerMember?.id,
                    memberType: TrusteeType.Beneficiary,
                    familyMember: true,
                    customName: "",
                    memberOrder: undefined
                }];
            }

        if (isMounted) { // Do not change order of sets
            setBeneficiaryOptions(parsedFamilyMembersResponse.familyMember);
            setTrust(trustInfo);
            setFamilyMembers(familyMembersRes);
            setFamilyMembersResponse(parsedFamilyMembersResponse);
            setTrustees(prevArray => [...prevArray, ...parsedFamilyMembersResponse.familyMember]);
        }
        return () => {
            isMounted = false
        };
    }, [id]);

    useEffect(() => {
        updateBeneficiaryInformation(trust)
    }, [trust]);

    if (beneficiaryOptions.length === 0) {
        return <LoadingIndicator/>
    }

    return (
        <section aria-label="Trust Form"
                 className="trust-form"
                 data-testid={'trust-form-' + index}>
            <UnderlinedHeader className={"trust-form-header"}
                leftAlignedContent={
                    <div className="required-header" data-testid={'basicInfo-container'}>
                        <h4>{createTrustHeading()}</h4>
                        <Icon name="bank" className={"bank-icon"}/>
                        <RequiredFieldsSubheader/>
                    </div>
                }
                rightAlignedContent={
                    <Button
                        data-testid={"delete-button-" + index}
                        className={"trust-delete-button"}
                        onClick={() => setShowCancelModal(true)}
                        icon="left"
                        iconName="delete"
                        kind="borderless"/>
                }
            />
            <DiscardModal
                isOpen={showCancelModal}
                onClickKeepEditing={() => setShowCancelModal(false)}
                onClickDiscardChanges={() => {
                    updateBeneficiaryInformation(undefined)
                    setShowCancelModal(false)
                }}
                isEditing={true}/>

            <div className="textalign-right form-main">
                <div>
                    <Name
                        name={trust.trustName}
                        label={"Trust Name"}
                        aria-label="Trust Name"
                        className="layout-data-entry-form__field"
                        required={true}
                        forceShowErrors={true}
                        error={saveHasBeenClicked && !isValid(trust.trustName) ? "Trust Name is required." : undefined}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setTrust({...trust, trustName: e.target.value})}
                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <label className={"h5 marginbottom-0"}>Tags<RedAsterisk/></label>
                    <RadioGroup
                        name={`tags-${index}`}
                        label=""
                        className='vertical-radiogroup'
                        layout='vertical'
                        values={["Exempt", "Non-Exempt", "Both", "Unknown/Inapplicable"]}
                        disabled={isProfileWithProposalsOrArchived}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const {value} = e.target;
                            setTrust({...trust, tags: value})
                        }}
                        required={true}
                        selected={trust.tags}
                    />
                    {saveHasBeenClicked && !isValid(trust.tags) ?
                        <div className='input__info color-text--error'>Tags are required.</div> : undefined}
                </div>
                <MemberDropdownMultiselect
                    label="Trustees"
                    required={true}
                    options={familyMembers}
                    selectedOptions={trust.trustees ?? []}
                    includeNT={true}
                    onChange={(options: any) => onChangeDropdown(options, TrusteeType.BeneficiaryTrustee)}
                    onError={saveHasBeenClicked &&
                    (!trust.trustees?.length) ? "Trustees are required." : undefined}
                />
                {
                    familyMembersResponse.familyMember && familyMembersResponse.familyMember.length > 0 &&
                    <SuccessorTable formData={trust}
                                    successorType={TrusteeType.BeneficiarySuccessor}
                                    dropdownHeight={listHeight(trustees)}
                                    familyMembersResponse={familyMembersResponse}
                                    updateData={setTrust}/>
                }
                <div className="layout-data-entry-form__field" data-testid={'beneficiary-dropdown'}>
                    {trust.trustType === TrustType.marital &&
                        <>
                            <label id="beneficiaryDropdown" className={"h5"} aria-label={'beneficiary'}
                                   htmlFor="beneficiaryDropdown">Beneficiary</label>

                            <Dropdown
                                aria-labelledby="beneficiaryDropdown"
                                className='beneficiaryDropdown'
                                onChange={(e) => {
                                    const updatedBeneficiary = [{
                                        trustId: undefined,
                                        memberId: e.value,
                                        memberType: TrusteeType.Beneficiary,
                                        familyMember: true,
                                        customName: "",
                                        memberOrder: undefined
                                    } as Trustee]
                                    setTrust({...trust, beneficiaries: updatedBeneficiary} as Trust)
                                }}
                                size='medium'
                                iconNameClose="arrow_drop_up"
                                iconNameOpen="arrow_drop_down"
                                id="beneficiaryDropdown"
                                name="beneficiary"
                                value={trust.beneficiaries?.[0]?.memberId ?? ""}
                                alignRight={false}
                                nativeOnMobile={false}
                                dropUp={false}
                                virtualScroll={false}
                                panelHeight={listHeight(beneficiaryOptions)}
                            >

                                {beneficiaryOptions.map((member: Member) => (
                                    <DropdownItem
                                        key={member.value}
                                        value={member.value}
                                        itemText={member.label}
                                    />
                                ))}
                            </Dropdown>
                        </>
                    }
                </div>
                {trust.trustType !== TrustType.marital &&
                    <MemberDropdownMultiselect
                        label="Beneficiaries"
                        required={false}
                        options={familyMembers}
                        selectedOptions={trust.beneficiaries}
                        includeNT={true}
                        onChange={(options: any) => onChangeDropdown(options, TrusteeType.Beneficiary)}
                        onError={undefined}
                    />
                }
                <div className="layout-data-entry-form__field basic-information__funding">
                    <div className={"textarea-label"}><label className={"h5"} htmlFor="funding">Funding</label>
                        <span className='textarea-limit-count'>{textareaLength(trust.funding)}/500</span>
                    </div>
                    <textarea
                        id="funding"
                        name="Funding"
                        className="textarea-500-limit input-skin"
                        data-testid='funding-field'
                        autoFocus={false}
                        rows={5}
                        cols={50}
                        maxLength={500}
                        defaultValue={trust.funding}
                        onChange={(e) => setTrust({...trust, funding: e.target.value})}
                    />
                </div>
                <div className="layout-data-entry-form__field basic-information__distribution">
                    <div className={"textarea-label"}><label className={"h5"}>Distributions<RedAsterisk/></label>
                        <span className='textarea-limit-count'>{textareaLength(trust.distributions)}/500</span>
                    </div>
                    <textarea
                        name="Distributions"
                        className={saveHasBeenClicked && !isValid(trust.distributions) ? "textarea-500-limit input-skin error" : "textarea-500-limit input-skin"}
                        data-testid='distribution-field'
                        autoFocus={false}
                        rows={5}
                        cols={50}
                        maxLength={500}
                        defaultValue={trust.distributions}
                        onChange={(e) => setTrust({...trust, distributions: e.target.value})}
                    />
                    {saveHasBeenClicked && !isValid(trust.distributions) ?
                        <div className='input__info color-text--error'>Distributions are required.</div> : undefined}
                    <span className="distributions-sub-text input__info margintop-sm">
                        {"Categories can include: emergencies, medical needs, health, education, " +
                            "support & maintenance, comfort, best interest & welfare, pleasure/happiness, " +
                            "trustee's sole discretion, etc." + "for e.g., Mandatory income and discretionary principal " +
                            "in the trustee's discretion for support in reasonable comfort and health"}
                    </span>
                </div>
                <div className="layout-data-entry-form__field basic-information__termination">
                    <div className={"textarea-label"}><label className={"h5"} htmlFor="termination">Termination</label>
                        <span className='textarea-limit-count'>{textareaLength(trust.termination)}/500</span>
                    </div>
                    <textarea
                        name="Termination"
                        className="textarea-500-limit input-skin"
                        data-testid='termination-field'
                        autoFocus={false}
                        rows={5}
                        cols={50}
                        maxLength={500}
                        defaultValue={trust.termination}
                        onChange={(e) => {
                            setTrust({
                                ...trust, termination: e.target.value
                            })
                        }
                        }
                    />
                </div>

                {<PowersOfAppointmentSection powersOfAppointment={sortedPowersOfAppointments()}
                                             familyMembers={beneficiaryOptions}
                                             updateTrust={updatePowersOfAppointments}
                                             trustIndex={index}
                                             saveHasBeenClicked={saveHasBeenClicked}
                                             isValid={isValid}
                />}
            </div>

        </section>
    );
}

export default TrustForm