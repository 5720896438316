import {
    COLOR_ASSETS_ACCOUNTS,
    COLOR_EXCESS_ASSETS,
    COLOR_NT_GREY_050,
    COLOR_PROJECTED_ASSETS
} from "../../../constants/colors";

export default class ComparePlanAssetBarChartColumn {
    protected readonly totalNetAssetsPresentValue: number;
    protected readonly totalGoalsPresentValue: number;
    protected readonly excessAssets: number;
    protected readonly chartUnderlayMaxValue: number;
    protected readonly hasExcessAssets: boolean;

    constructor(totalNetAssetsPresentValue: number, totalGoalsPresentValue: number, excessAssets: number,
                chartUnderlayMaxValue: number, hasExcessAssets: boolean) {
        this.totalNetAssetsPresentValue = totalNetAssetsPresentValue;
        this.totalGoalsPresentValue = totalGoalsPresentValue;
        this.excessAssets = excessAssets;
        this.chartUnderlayMaxValue = chartUnderlayMaxValue;
        this.hasExcessAssets = hasExcessAssets
    }

    getColumnMaxValue(): number {
        return Math.max(this.totalNetAssetsPresentValue, this.totalGoalsPresentValue);
    }

    getGoalsPositionVerticalOffset() {
        return (100 - this.totalGoalsPresentValue/ this.chartUnderlayMaxValue * 100).toFixed(2);
    }

    getChartItems() {
        let chartItems = [];
        if (!this.hasExcessAssets) {
            chartItems.push({
                label: "Asset Shortfall",
                total: Math.abs(this.excessAssets),
                color: COLOR_NT_GREY_050,
                highlight: 0
            });
            if (this.totalNetAssetsPresentValue >= 0) {
                chartItems.push({
                    label: "Net Assets",
                    total: this.totalNetAssetsPresentValue,
                    color: COLOR_ASSETS_ACCOUNTS,
                    highlight: this.totalNetAssetsPresentValue
                });
            }
        } else {
            if (this.excessAssets < 0) {
                chartItems.push({
                    label: "Net Assets",
                    total: this.totalNetAssetsPresentValue,
                    color: COLOR_ASSETS_ACCOUNTS,
                    highlight: this.totalNetAssetsPresentValue
                });
            } else {
                chartItems.push({
                    label: "Excess Assets",
                    total: this.excessAssets,
                    color: COLOR_ASSETS_ACCOUNTS,
                    highlight: 0
                }, {
                    label: "Goals",
                    total: this.totalNetAssetsPresentValue - this.excessAssets,
                    color: COLOR_ASSETS_ACCOUNTS,
                    highlight: this.totalNetAssetsPresentValue - this.excessAssets
                });
            }
        }
        return chartItems;
    }

    getVerticalLineMargins() {
        let topMargin;
        let bottomMargin;

        if(this.hasExcessAssets) {
            topMargin = 100 - ((this.totalNetAssetsPresentValue / this.chartUnderlayMaxValue) * 100);
            bottomMargin = (this.totalGoalsPresentValue / this.chartUnderlayMaxValue) * 100;
        } else {
            topMargin = 100 - ((this.totalGoalsPresentValue / this.chartUnderlayMaxValue) * 100);
            bottomMargin = (this.totalNetAssetsPresentValue / this.chartUnderlayMaxValue) * 100;
        }

        return {
            topMargin: Math.max(topMargin, 0).toFixed(2),
            bottomMargin: Math.max(bottomMargin, 0).toFixed(2)
        };
    }

    getPillColor() {
        return this.excessAssets >= 0 ? COLOR_EXCESS_ASSETS : COLOR_PROJECTED_ASSETS;
    }
}