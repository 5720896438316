import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {RouteWithIdAndStrategyId} from "../routes/types";
import {
    EstimatedImpact,
    FailedAPICall, GiftToGrantorTrustEstimatedImpact,
    GiftToGrantorTrustStrategy,
    GrowthRates,
    initialGiftToGrantorTrustStrategy,
    StrategyType
} from "./WealthPOTypes";
import {wealthPOApiClient} from "./WealthPOApiClient";
import ScrollableContainer from "../components/ScrollableContainer/ScrollableContainer";
import {HandleSaveResponse, StrategyHeader} from "./components/StrategyHeader";
import {
    CurrencyInput,
    EmptyStateContainer,
    Input,
    RedAsterisk,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from "../components";
import useProfileEditableState from "../hooks/useProfileEditableState";
import {YearsInput} from "../components/YearsInput/YearsInput";
import {PlanningPeriodValues, processInvestorGroupResponse, validateDescription} from "./WealthPOUtils";
import {StrategySidebar} from "./components/StrategySidebar";
import {DataEntrySummaryItem} from "../components/DataEntry/DataEntrySummary";
import {formatCurrency} from "../utils/format";
import {InvestorGroupType} from "../ClientManagement/models/InvestorGroupType";
import {clientManagementApiClient} from "../ClientManagement/ClientManagementApiClient";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../components/Modal/Error/GenericErrorModal";
import {useAppSelector} from "../store/hooks";
import {selectGiftToGrantorTrustStrategies} from "./WealthPlanOptimizerSlice";
import {EstateTaxExemptionsReadModel} from "../WealthTransfer/models/ui";
import {wealthTransferApiClient} from "../WealthTransfer/WealthTransferApiClient";
import {selectReleaseToggles} from "../ReleaseToggles/releaseTogglesSlice";
import {SubMenuProps} from "../models/routeData/RouteParamTypes";

export interface AddEditGiftToGrantorProps {
    header: string,
    refreshStrategiesSummary: () => void;
    items: SubMenuProps[]
}

const AddEditGiftToGrantorTrust = ({
                                       header,
                                       refreshStrategiesSummary,
                                       items
                                   }: AddEditGiftToGrantorProps) => {
    const {id, strategyId} = useParams<RouteWithIdAndStrategyId>();

    const strategies = useAppSelector(selectGiftToGrantorTrustStrategies);
    let strategy: GiftToGrantorTrustStrategy = initialGiftToGrantorTrustStrategy;

    if (strategyId !== undefined) {
        strategy = strategies.find((s => s.strategyId === strategyId))!
    }

    const releaseToggles = useAppSelector(selectReleaseToggles);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const [description, setDescription] = useState<string>(strategy.description);
    const [contributionAmountDisplayValue, setContributionAmountDisplayValue] = useState<number>(strategy.contributionAmount);
    const [contributionAmount, setContributionAmount] = useState<number>(strategy.contributionAmount);
    const [riskAssetGrowthRate, setRiskAssetGrowthRate] = useState<string>("");
    const [yearsOfGrantorStatusDisplayValue, setYearsOfGrantorStatusDisplayValue] = useState<number>(strategy.yearsOfGrantorStatus || 0);
    const [yearsOfGrantorStatus, setYearsOfGrantorStatus] = useState<number>(strategy.yearsOfGrantorStatus || 0);
    const [planningPeriodValues, setPlanningPeriodValues] = useState<PlanningPeriodValues>({
        birthdate: "",
        firstName: "",
        planningPeriod: undefined
    })

    const [estimatedImpact, setEstimatedImpact] = useState<GiftToGrantorTrustEstimatedImpact>(strategy.estimatedImpact);
    const [initialFormUpdated, setInitialFormUpdated] = useState<boolean>(false);

    const [error, setError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [validationError, setValidationError] = useState<boolean>(false);
    const [showYearsOfGrantorStatusError, setShowYearsOfGrantorStatusError] = useState<boolean>(false);
    const [failedApiCall, setFailedApiCall] = useState<FailedAPICall>();
    const [showTempErrorMessage, setShowTempErrorMessage] = useState(false);

    const [taxExemptions, setTaxExemptions] = useState<EstateTaxExemptionsReadModel>();

    const estimatedImpactAbortControllerRef = useRef<AbortController | undefined>(undefined);

    const getEstimatedImpact = (requestBody: GiftToGrantorTrustStrategy, signal?: AbortSignal): Promise<GiftToGrantorTrustEstimatedImpact> => {
        return wealthPOApiClient.getEstimatedImpactForGiftToGrantorTrust(id, requestBody, signal);
    }

    const fetchEstimatedImpact = async () => {
        estimatedImpactAbortControllerRef.current = undefined;

        if (initialFormUpdated) {
            estimatedImpactAbortControllerRef.current = new AbortController();
            const {signal} = estimatedImpactAbortControllerRef.current;

            const requestBody = {
                strategyId: undefined,
                strategyType: StrategyType.GIFT_TO_GRANTOR_TRUST,
                description: "Gift to Grantor Trust",
                contributionAmount: contributionAmount,
                yearsOfGrantorStatus: yearsOfGrantorStatus
            } as GiftToGrantorTrustStrategy

            getEstimatedImpact(requestBody, signal)
                .then(estimatedImpactResponse => {
                    setEstimatedImpact(estimatedImpactResponse);
                })
                .catch((err) => {
                    setFailedApiCall(FailedAPICall.ESTIMATED_IMPACT);
                    openErrorModal(err);
                });
        }
    }

    const fetchReferenceDataAndInvestorGroup = async () => {
        const apiRequests: [Promise<GrowthRates>, Promise<InvestorGroupType>] = [
            wealthPOApiClient.getGrowthRatesReferenceData(id),
            clientManagementApiClient.getInvestorGroup(id)
        ]

        try {
            let [growthRates, investorGroup] = await Promise.all(apiRequests);

            const riskAssetRateDto = growthRates.rateDtos.find(rateDto => rateDto.id === "RiskAsset");
            if (riskAssetRateDto) {
                const growthRate = riskAssetRateDto.value * 100;
                const s = growthRate.toPrecision(3);
                setRiskAssetGrowthRate(s)
            }

            const {
                planningPeriodComputedValues
            } = processInvestorGroupResponse(investorGroup, 0, 1);

            setPlanningPeriodValues(planningPeriodComputedValues);

            if (strategyId === undefined) {
                setYearsOfGrantorStatusDisplayValue(planningPeriodComputedValues.planningPeriod!.numberOfYears)
                setYearsOfGrantorStatus(planningPeriodComputedValues.planningPeriod!.numberOfYears)
            }

        } catch (err) {
            openErrorModal(err);
        }
    }

    const validateYearsOfGrantorStatus = (inputValue: any, originalValue: number | string, setDisplayValue: (value: number) => void): void => {
        if (isNaN(parseInt(inputValue))) {
            setDisplayValue(Number(originalValue));
            return;
        }

        let newValue: number = Number(inputValue);

        if (newValue > planningPeriodValues.planningPeriod!.numberOfYears) {
            setShowYearsOfGrantorStatusError(true)
            setDisplayValue(planningPeriodValues.planningPeriod!.numberOfYears)
            setYearsOfGrantorStatus(planningPeriodValues.planningPeriod!.numberOfYears)
        } else {
            setYearsOfGrantorStatus(newValue)
        }
    }

    const handleSave = async (): Promise<HandleSaveResponse> => {
        setValidationError(false);

        const valid = validateDescription(description);

        let response = {
            isSuccess: valid,
            errors: {
                hasValidationError: false,
                hasCommunicationError: false,
                traceId: ""
            }
        } as HandleSaveResponse

        if (!valid) {
            setValidationError(true)

            response.isSuccess = false
            response.errors.hasValidationError = true
            return response;
        } else {
            let requestBody = {
                strategyType: StrategyType.GIFT_TO_GRANTOR_TRUST,
                contributionAmount,
                description,
                yearsOfGrantorStatus
            } as GiftToGrantorTrustStrategy

            try {
                if (strategyId) {
                    requestBody = {
                        ...requestBody,
                        strategyId,
                    };

                    await wealthPOApiClient.editGiftToGrantorTrustStrategy(id, requestBody);
                } else {
                    await wealthPOApiClient.createGiftToGrantorTrustStrategy(id, requestBody);
                }
            } catch (saveError: Error | any) {
                response.isSuccess = false;
                response.errors.hasCommunicationError = true;
                response.errors.traceId = saveError.headers.get('trace-id');
            }
        }

        return response;
    }

    const handleCancel = () => {
        return description !== strategy.description
            || contributionAmount !== strategy.contributionAmount
            || (strategy.yearsOfGrantorStatus === undefined && yearsOfGrantorStatus !== planningPeriodValues.planningPeriod?.numberOfYears)
            || (strategy.yearsOfGrantorStatus !== undefined && yearsOfGrantorStatus !== strategy.yearsOfGrantorStatus);
    }

    const openErrorModal = (e: Error | any) => {
        setError({
            isOpen: true,
            header: "Unable to Load This Page",
            message: (
                <>
                    <p>Check your VPN connection and retry.</p>
                    <p>If the problem persists, contact <a href="mailto:GPIITSupport@ntrs.com">GPS Technical
                        Support</a> at GPIITSupport@ntrs.com and provide the following details:</p>
                </>
            ),
            profileId: id,
            time: new Date(),
            errorDetail: `Failed to load ${header} page (${e.status})`,
            operationId: e.headers.get('trace-id')
        })
    }

    const closeErrorModal = () => {
        setError({...error, isOpen: false});
    };

    const handleRetryClickInCommunicationErrorModal = () => {
        closeErrorModal();

        if (failedApiCall === FailedAPICall.ESTIMATED_IMPACT) {
            fetchEstimatedImpact().then();
        } else {
            fetchReferenceDataAndInvestorGroup().then();
        }
    }

    const handleCloseClickInCommunicationErrorModal = () => {
        closeErrorModal();
        setShowTempErrorMessage(true);
    }

    useEffect(() => {
        fetchReferenceDataAndInvestorGroup().then();
        wealthTransferApiClient.getExemptions(id).then(setTaxExemptions)
    }, [id]);

    useEffect(() => {
        fetchEstimatedImpact().then();

        return () => {
            if (estimatedImpactAbortControllerRef.current) {
                estimatedImpactAbortControllerRef.current.abort();
            }
        }
    }, [contributionAmount, yearsOfGrantorStatus, initialFormUpdated])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowYearsOfGrantorStatusError(false);
        }, 6000);
        return () => {
            clearTimeout(timeout);
        }
    }, [showYearsOfGrantorStatusError]);

    const memoizedErrorMessage = useMemo(() => {
        return showYearsOfGrantorStatusError && (
            <>
                <div></div>
                <div data-testid={`error-message-yearsOfGrantorStatusInput`}
                     className="strategy-time-frame-error-msg">
                    Years of Grantor Status was adjusted to match Planning Period.
                </div>
            </>
        )
    }, [showYearsOfGrantorStatusError])

    const memoizedEstimatedImpact: DataEntrySummaryItem[] = useMemo(() => {
        let estimatedImpactSummaryItems = [
            {
                label: "Future Value of Gift",
                value: (estimatedImpact.amountToBeneficiaries !== undefined) ? formatCurrency(estimatedImpact.amountToBeneficiaries) : undefined,
                testId: "amountToBeneficiaries",
            } as DataEntrySummaryItem,
            {
                label: "Est. Estate Tax",
                value: (estimatedImpact.impactToEstEstateTax !== undefined) ? formatCurrency(estimatedImpact.impactToEstEstateTax) : undefined,
                testId: "estEstateTax",
            } as DataEntrySummaryItem,
        ]
        if(releaseToggles?.enableGTGTChanges){
            estimatedImpactSummaryItems.push({
                label: "Est. Federal Gift Tax",
                value: (estimatedImpact.giftAndExemptionSummary.estFederalGiftTax !== undefined) ?
                    formatCurrency(estimatedImpact.giftAndExemptionSummary.estFederalGiftTax)
                    : undefined,
                testId: "estFederalGiftTax",
            } as DataEntrySummaryItem)
        }

        return estimatedImpactSummaryItems;
    }, [estimatedImpact, releaseToggles]);

    function getTotalAvailableExemption() {
        if(!taxExemptions) {
            return undefined;
        }
        let totalAvailableExemption = 0;
        totalAvailableExemption += taxExemptions.primaryMemberExemption.availableExemption ?? 0;
        totalAvailableExemption += taxExemptions.partnerMemberExemption.availableExemption ?? 0;
        return formatCurrency(totalAvailableExemption);
    }

    const memoizedExemption: DataEntrySummaryItem[] = useMemo(() => {
        return [
            {
                label: "Total Available Exemption",
                value: getTotalAvailableExemption(),
                testId: "totalAvailableExemption",
            } as DataEntrySummaryItem,
            {
                label: "Federal Exemption Used",
                value: (estimatedImpact.giftAndExemptionSummary?.federalExemptionUsed !== undefined)
                    ? formatCurrency(estimatedImpact.giftAndExemptionSummary?.federalExemptionUsed)
                    : undefined,
                testId: "federalExemptionUsed",
            } as DataEntrySummaryItem,
            {
                label: "Federal Exemption Left",
                value: (estimatedImpact.giftAndExemptionSummary?.federalExemptionLeft !== undefined)
                    ? formatCurrency(estimatedImpact.giftAndExemptionSummary?.federalExemptionLeft)
                    : undefined,
                testId: "federalExemptionLeft",
            } as DataEntrySummaryItem,
        ]
    }, [taxExemptions, estimatedImpact.giftAndExemptionSummary]);

    const memoizedStrategySummary: DataEntrySummaryItem[] = useMemo(() => {
        return [
            {
                label: "One-Time Gift",
                value: formatCurrency(contributionAmount),
                testId: "strategySummaryContributionAmount"
            } as DataEntrySummaryItem,
            {
                label: "Years of Grantor Status",
                value: yearsOfGrantorStatus,
                testId: "strategySummaryYearsOfGrantorStatus"
            }
        ]
    }, [contributionAmount, yearsOfGrantorStatus])

    if (error.isOpen) {
        return (
            <GenericErrorModal
                errorModalData={error}
                onClickButton={handleRetryClickInCommunicationErrorModal}
                onRequestClose={handleCloseClickInCommunicationErrorModal}
                buttonText="Retry"
                buttonProps={
                    {
                        primary: true,
                        className: 'full-width center-align',
                        iconPosition: 'left',
                        iconName: 'refresh'
                    }
                }
                showAlertIcon={false}
            />
        )
    }

    if (showTempErrorMessage) {
        return (
            <EmptyStateContainer
                className="no-wpo-summary-placeholder"
                title="Unable to Load This Page"
                size="large"
                description="Try again later."
            />
        )

    }

    return (
        <ScrollableContainer id={"add-edit-gift-to-grantor-scroll-container"} className="wealth-plan-optimizer">
            <StrategyHeader
                header={header}
                handleSave={handleSave}
                handleCancel={handleCancel}
                refreshStrategySummary={refreshStrategiesSummary}
            />

            <div className="wealthpo-strategy__form">
                <article>
                    <section aria-label="Strategy Details">
                        <UnderlinedHeader
                            className={"wealthpo-strategy__underlined-header"}
                            primaryText="Strategy Details"
                            rightAlignedContent={<RequiredFieldsSubheader/>}
                        />
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"} data-testid={'description'}>Description<RedAsterisk/></label>
                            <Input
                                name="descriptionField"
                                aria-label="description"
                                aria-labelledby="descriptionFieldInput-label"
                                id="descriptionFieldInput"
                                removeMarginTop
                                size="medium"
                                type="text"
                                value={description}
                                readOnly={isProfileWithProposalsOrArchived}
                                error={validationError ? "Description is required." : undefined}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                            />
                        </div>
                        <div className="layout-data-entry-form__field" style={{alignItems: "start"}}>
                            <label className={"h5"} data-testid={'contributionAmountInput'}>One-Time Gift</label>
                            <div className="annual-flow-input-field">
                                <CurrencyInput
                                    name="contributionAmountField"
                                    aria-label="contributionAmountInput"
                                    id="contributionAmountFieldInput"
                                    size="medium"
                                    value={contributionAmountDisplayValue}
                                    readOnly={isProfileWithProposalsOrArchived}
                                    textAlign='left'
                                    onChangeValue={e => isNaN(parseInt(e.target.value)) ? setContributionAmountDisplayValue(0) : setContributionAmountDisplayValue(parseInt(e.target.value))}
                                    onBlur={() => {
                                        setContributionAmount(contributionAmountDisplayValue);
                                        if (!initialFormUpdated) {
                                            setInitialFormUpdated(true);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"} data-testid={"yearsOfGrantorStatus"}>Years of Grantor
                                Status</label>
                            <div data-testid={"yearsOfGrantorStatusInput"}>
                                <YearsInput style={{width: "90px"}}
                                            name={"yearsOfGrantorStatusInput"}
                                            className={"years-of-grantor-status"}
                                            value={yearsOfGrantorStatusDisplayValue}
                                            size={"medium"}
                                            onBlur={() => {
                                                validateYearsOfGrantorStatus(yearsOfGrantorStatusDisplayValue, yearsOfGrantorStatus, setYearsOfGrantorStatusDisplayValue)
                                                if (!initialFormUpdated) {
                                                    setInitialFormUpdated(true);
                                                }
                                            }}
                                            readOnly={isProfileWithProposalsOrArchived ? isProfileWithProposalsOrArchived : false}
                                            disabled={false}
                                            onChangeValue={(_e, value) => {
                                                setYearsOfGrantorStatusDisplayValue(value)
                                            }}
                                            aria-label={"yearsOfGrantorStatusInput"}
                                />
                            </div>
                        </div>
                        {showYearsOfGrantorStatusError && memoizedErrorMessage}
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"}>Growth Rate</label>
                            <div className="growth-rate-value">
                                <span aria-label={'growthRate'}>Triple Net Risk Asset ({riskAssetGrowthRate}%)</span>
                            </div>
                        </div>
                        <div className="layout-data-entry-form__field"
                             data-testid={"strategyDetailsPlanningPeriod"}>
                            <label className={"h5"}>Planning Period</label>
                            <span
                                className={showYearsOfGrantorStatusError ? 'error-color' : ''}
                                role="planningPeriod"
                                aria-label="planningPeriod">{`${planningPeriodValues.planningPeriod?.numberOfYears} years (${planningPeriodValues.firstName})`}</span>
                        </div>
                    </section>
                </article>
                <StrategySidebar
                    strategySummary={memoizedStrategySummary}
                    estimatedImpact={memoizedEstimatedImpact}
                    exemption={memoizedExemption}
                    enableExemptionSection={releaseToggles?.enableGTGTChanges}
                    items={items}
                    strategyName={"Gift to Grantor Trust"}
                />
            </div>
        </ScrollableContainer>
    )
}

export default AddEditGiftToGrantorTrust