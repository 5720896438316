import React from "react";
import AccordionWithHeader from "../../components/Accordion/AccordionWithHeader";
import {Container, Row} from "react-grid-system";
import {AccordionItemWithActionMenu, DropdownItem, Icon} from "../../components";
import {StateOfResidencyAccordionItem} from "./StateOfResidencyAccordionItem";
import {StateInputDto, StateOfResidencySectionInfo} from "../WealthPOTypes";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {getStateNameAndRateFromStateAbbreviation, getStateNamesForStateOfResidencyDropdown} from "../WealthPOUtils";
import {StateOfResidencyAccordionHeader} from "./StateOfResidencyAccordionHeader";
import {NO_OP} from "../../constants/common";
import {TableActionDropdownMenu} from "../../components/Table/TableActionDropdownMenu";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import useProfileEditableState from "../../hooks/useProfileEditableState";

interface StateOfResidencyOverviewProps {
    isInReportPreview: boolean;
    listOfStates: StateInputDto[];
    selectedStateOfResidencyInfo: StateOfResidencySectionInfo | undefined
}

const StateOfResidencyOverview: React.FC<StateOfResidencyOverviewProps> = ({
                                                                               isInReportPreview,
                                                                               listOfStates,
                                                                               selectedStateOfResidencyInfo
                                                                           }: StateOfResidencyOverviewProps) => {
    const profileStateAbbr = useAppSelector(selectProfile).primaryContact.stateAbbr;
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const profileSOR = getStateNameAndRateFromStateAbbreviation(listOfStates, profileStateAbbr!)

    const profileStateOfResidency: StateOfResidencySectionInfo = {
        id: undefined,
        description: profileStateAbbr ? "Profile State of Residency" : "––",
        selectedStateOfResidency: (profileSOR === "" || profileSOR === undefined) ? "––" : profileSOR,
        estimatedImpact: {
            amountToBeneficiaries: 0,
            impactToEstEstateTax: 0,
            strategyPayoutSchedule: []
        },
        enabled: true,
        effectiveRate: undefined
    }
    const selectedStateOfResidency: StateOfResidencySectionInfo = {
        id: undefined,
        description: selectedStateOfResidencyInfo?.description ? selectedStateOfResidencyInfo.description : "--",
        selectedStateOfResidency: (
            selectedStateOfResidencyInfo?.selectedStateOfResidency === "" ||
            selectedStateOfResidencyInfo?.selectedStateOfResidency === undefined
        ) ? "––" : selectedStateOfResidencyInfo?.selectedStateOfResidency,
        estimatedImpact: {
            amountToBeneficiaries: 0,
            impactToEstEstateTax: 0,
            strategyPayoutSchedule: []
        },
        enabled: true,
        effectiveRate: undefined
    }
    const strategySOR = getStateNameAndRateFromStateAbbreviation(listOfStates, selectedStateOfResidency.selectedStateOfResidency)

    return (
        <Container className="state-of-residency-overview" data-testid="state-of-residency-overview">
            <h4>State of Residency</h4>
            <AccordionWithHeader
                accordionId={'state-of-residency-accordion'}
                allItems={[]}
                columnTitles={['Name', 'State', 'Effective Rate %']}
                renderHeader={true}
                HeaderComponent={() => StateOfResidencyAccordionHeader(isInReportPreview)}
                ignoreExpandedItems={true}
            >
                <Row className="state-of-residency-overview__accordion-row" data-testid="profile-SOR-row">
                    <AccordionItemWithActionMenu
                        key={"profile-state-of-residency"}
                        uuid={"profile-state-of-residency"}
                        expandable={false}
                        HeaderComponent={() => StateOfResidencyAccordionItem(
                            {
                                description: profileStateOfResidency.description,
                                isProfileSOR: true,
                                stateOfResidency: profileStateOfResidency.selectedStateOfResidency,
                                effectiveRate: profileStateOfResidency.effectiveRate,
                                enabled: profileStateOfResidency.enabled,
                                isInReportPreview: isInReportPreview,
                                listOfStates: []
                            }
                        )}
                    />
                </Row>
                {selectedStateOfResidencyInfo?.id &&
                    <Row className="state-of-residency-overview__accordion-row" data-testid="strategy-SOR-row">
                        <AccordionItemWithActionMenu
                            key={"selected-state-of-residency"}
                            uuid={"selected-state-of-residency"}
                            expandable={false}
                            HeaderComponent={() => StateOfResidencyAccordionItem(
                                {
                                    description: selectedStateOfResidency.description,
                                    isProfileSOR: false,
                                    stateOfResidency: strategySOR ?? "",
                                    effectiveRate: selectedStateOfResidency.effectiveRate,
                                    enabled: selectedStateOfResidency.enabled,
                                    isInReportPreview: isInReportPreview,
                                    listOfStates: getStateNamesForStateOfResidencyDropdown(listOfStates)
                                }
                            )}
                            actionMenu={
                                <div data-testid="sor-action-menu" className={"sor-action-menu"}>
                                    <TableActionDropdownMenu
                                        ariaLabel={'SOR Action Menu'}
                                    >
                                        {releaseToggles!.enableWPOStrategyEnabledStatusChange &&
                                            <DropdownItem
                                                itemText={"Enable Strategy"}
                                                value={"Disable"}
                                                onClick={NO_OP}
                                            />
                                        }
                                        <DropdownItem
                                            itemText="Edit Strategy"
                                            value="Edit Strategy"
                                            onClick={NO_OP}
                                        />
                                        <DropdownItem
                                            itemText="Delete Strategy"
                                            value="Delete Strategy"
                                            disabled={isProfileWithProposalsOrArchived}
                                            onClick={NO_OP}
                                        >
                                            <Icon name="delete" className="delete-trash-icon"
                                                  data-testid="delete-trash-icon"/>
                                        </DropdownItem>
                                    </TableActionDropdownMenu>
                                </div>
                            }
                        />
                    </Row>
                }
            </AccordionWithHeader>
        </Container>
    );
}

export default StateOfResidencyOverview;