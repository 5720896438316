import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {ActiveFormAsset, InEstateAssetTotals} from "../../../Assets/clientAssetsSlice";
import {AssetsSummary} from "../../../Assets/models/Assets";
import React, {useEffect, useMemo, useState} from "react";
import {formatCurrency} from "../../../utils/format";
import {
    calculateCNWInEstateTotalValue, calculateOutOfEstateTotalValue
} from "../../../Assets/AssetSummary/common/AssetSummaryCalculator";
import {
    assetListData,
    hasAssets,
    hasInEstateAssets,
    hasOutOfEstateAssets,
    hasOutOfEstateLiabilities,
    sortByOrdinalAndCashValue,
    sortByOrdinalAndInEstateOrMarketValue, sortByOrdinalAndLoanBalance,
    sortByOrdinalAndPresentValue
} from "../../../Assets/AssetSummary/common/utils";
import {BarChartSidebar} from "../../../components";
import {createAssetBarChartData} from "../../../Assets/AssetSummary/BarChart/AssetBarChartUtils";
import {AssetBarChartFooter} from "../../../Assets/AssetSummary/BarChart/AssetBarChartFooter";
import CurrentNetWorthAssetListReport from "./CurrentNetWorthAssetListReport";
import {
    hasOutOfEstateNonClientSummaries,
    InAndOutEstateAssets,
    splitNonClientMemberSummaries,
    splitOutOfEstateLegalEntities
} from "../AssetSummaryDetailReport/AssetsReportUtils";
import PrintViewWrapper from "../PrintViewWrapper";
import {useAppDispatch} from "../../../store/hooks";
import {LegalEntityReadModel} from "../../../Assets/models/Ownership";
import {
    createLegalEntityTypeSummariesWithBankingDetails,
    createNonClientMemberSummaries, HeldAwayAndBankingDetails,
    LegalEntityTypeSummary,
    NonClientMemberSummary
} from "../../../Assets/mappers";
import {setAccordionPreferencesState} from "../../../Assets/common/accordionPreferencesSlice";
import {splitCNWAssets} from "./CurrentNWUtil";
import {PersonalAsset} from "../../../Assets/models/PersonalAsset";
import {showOwnershipType} from "../../../Assets/Ownership/helpers";
import {StandaloneAccount} from "../../../Assets/models/StandaloneAccount";
import {HeldAwayAccount} from "../../../Assets/models/InvestmentProgram";
import {OutOfEstateReportView} from "../AssetSummaryDetailReport/AssetSummaryReportView";
import {BankingAccountsSummary} from "../../../Assets/models/BankingAccounts";


export type CurrentNetWorthReportViewProps = {
    investorGroup?: InvestorGroupType,
    clientAssets: AssetsSummary,
    showDeathBenefit: boolean,
    displayName: string,
    inEstateTotals: InEstateAssetTotals,
    activeFormAsset: ActiveFormAsset,
    legalEntities: LegalEntityReadModel[],
    isDetailed: boolean,
    profileId: string
}

export function CurrentNetWorthReportView({
                                              investorGroup,
                                              clientAssets,
                                              showDeathBenefit,
                                              displayName,
                                              inEstateTotals,
                                              legalEntities,
                                              isDetailed,
                                              profileId
                                          }: CurrentNetWorthReportViewProps) {

    const clientAssetsCopy = useMemo(() =>
        JSON.parse(JSON.stringify(clientAssets)), [clientAssets]);

    const dispatch = useAppDispatch();
    const [CurrentNetWorthReportPages, setCurrentNetWorthReportPages] = useState<InAndOutEstateAssets[]>([]);

    const [legalEntityTypeSummaries, setLegalEntityTypeSummaries] = useState<LegalEntityTypeSummary[]>([]);
    const [nonClientMemberSummaries, setNonClientMemberSummaries] = useState<NonClientMemberSummary[]>([]);
    const [allItems, setAllItems] = useState<any[]>([]);
    const [firstLegalEntityPageIndex, setFirstLegalEntityPageIndex] = useState<number>();

    function expandNonClientAccordions(nonClientNamesToExpand: string[]) {
        const nonClientAccordionNames = nonClientNamesToExpand.map((accordionName) => accordionName + "-report");
        dispatch(setAccordionPreferencesState({
            accordionId: "CurrentNetWorthOutOfEstateNonClientAccordionReport",
            state: {
                expandedItems: [...nonClientAccordionNames]
            }
        }));
    }

    useEffect(() => {
        if (!isDetailed) {
            setCurrentNetWorthReportPages([{
                assetsSummary: clientAssets,
                legalEntitySummary: undefined,
                nonClientMemberSummary: undefined
            }]);
        } else {

            if (clientAssetsCopy.personalAssets.data && clientAssetsCopy.personalAssets.data.length > 1) {
                clientAssetsCopy.personalAssets.data = sortByOrdinalAndPresentValue(clientAssets.personalAssets.data) as PersonalAsset[];
            }

            if (clientAssetsCopy.lifeInsurances.data && clientAssetsCopy.lifeInsurances.data.length > 1) {
                clientAssetsCopy.lifeInsurances.data = sortByOrdinalAndCashValue(clientAssets.lifeInsurances.data);
            }

            if (clientAssetsCopy.personalLiabilities && clientAssetsCopy.personalLiabilities.length > 1) {
                clientAssetsCopy.personalLiabilities = sortByOrdinalAndLoanBalance(clientAssets.personalLiabilities);
            }

            const {assetSummariesData, remainingSpaceAfterSplittingAssets} = splitCNWAssets(clientAssetsCopy);
            const assetSummariesPages: InAndOutEstateAssets[] = [];
            assetSummariesData.forEach(summary => {
                assetSummariesPages.push({
                    assetsSummary: summary,
                    legalEntitySummary: undefined,
                    nonClientMemberSummary: undefined
                });
            });

            setFirstLegalEntityPageIndex(assetSummariesPages.length);

            const getPersonalAssetDetailsFn = (asset: PersonalAsset) => showOwnershipType(asset.ownershipCategory);
            const getStandaloneAccountDetailsFn = (asset: StandaloneAccount) => showOwnershipType(asset.ownershipCategory);
            const getHeldAwayAccountDetailsFn = (asset: HeldAwayAccount) => showOwnershipType(asset.ownershipCategory);
            const getBankingAccountDetailsFn = (asset: BankingAccountsSummary) => showOwnershipType(asset.ownershipCategory);

            const heldAwayAndBankingDetails: HeldAwayAndBankingDetails = {
                heldAwayAccountDetails: getHeldAwayAccountDetailsFn,
                bankingAccountDetails: getBankingAccountDetailsFn
            }


            let tempLegalEntityTypeSummaries = createLegalEntityTypeSummariesWithBankingDetails('CurrentNetWorth',
                clientAssets,
                legalEntities,
                getPersonalAssetDetailsFn,
                getStandaloneAccountDetailsFn,
                showDeathBenefit,
                heldAwayAndBankingDetails);

            const {
                legalEntityTypeSummariesArray,
                remainingSpaceAfterLegalEntities
            } = splitOutOfEstateLegalEntities(tempLegalEntityTypeSummaries, remainingSpaceAfterSplittingAssets);
            const legalEntitySummariesPages: InAndOutEstateAssets[] = [];
            legalEntityTypeSummariesArray.forEach((summary) => {
                legalEntitySummariesPages.push({
                    assetsSummary: undefined,
                    legalEntitySummary: summary,
                    nonClientMemberSummary: undefined
                })
            })
            const legalEntityNames = [...tempLegalEntityTypeSummaries.map((summary: { entityType: any; }) => summary.entityType)];
            setLegalEntityTypeSummaries(tempLegalEntityTypeSummaries);
            //non client pages
            let tempNonClientMemberSummaries = createNonClientMemberSummaries(
                'CurrentNetWorth',
                clientAssets,
                getPersonalAssetDetailsFn,
                getStandaloneAccountDetailsFn,
                showDeathBenefit,
                getHeldAwayAccountDetailsFn,
                getBankingAccountDetailsFn);
            const nonClientMemberSummariesArray: Array<NonClientMemberSummary[]> = splitNonClientMemberSummaries(tempNonClientMemberSummaries, remainingSpaceAfterLegalEntities);
            const nonClientMemberSummariesPages: InAndOutEstateAssets[] = [];
            nonClientMemberSummariesArray.forEach((summary) => {
                nonClientMemberSummariesPages.push({
                    assetsSummary: undefined,
                    legalEntitySummary: undefined,
                    nonClientMemberSummary: summary
                })
            })
            const nonClientNames = [
                ...tempNonClientMemberSummaries.map((summary: { memberName: any; }) => `${summary.memberName}-assets-summary`)
            ];
            setNonClientMemberSummaries(tempNonClientMemberSummaries);
            setAllItems([...legalEntityNames, ...nonClientNames]);

            const allAssetsPages: InAndOutEstateAssets[] = [...assetSummariesPages, ...legalEntitySummariesPages, ...nonClientMemberSummariesPages];

            if (CurrentNetWorthReportPages.length === 0) {
                setCurrentNetWorthReportPages(allAssetsPages);
                expandNonClientAccordions(nonClientNames);
            }
        }
    }, [])

    let OutOfEstateAccordionReport = "CurrentNetWorthOutOfEstateAccordionReport";
    let OutOfEstateCollapsedReport = "CurrentNetWorthOutOfEstateCollapsedReport";
    let OutOfEstateNonClientAccordionReport = "CurrentNetWorthOutOfEstateNonClientAccordionReport";
    let OutOfEstateNonClientCollapsedReport = "CurrentNetWorthOutOfEstateNonClientCollapsedReport";
    return (
        <>
            {CurrentNetWorthReportPages.map((clientAssetsPage, pageIndex) => (

                <PrintViewWrapper pageNumber={pageIndex} displayName={displayName} key={pageIndex}>
                    <article className="asset-summary-page layout-split-left">
                        <BarChartSidebar
                            data={createAssetBarChartData(inEstateTotals, null)}
                            noDataText='No assets have been captured yet.'
                            displayName={displayName}
                            title={`Current Net Worth${pageIndex > 0 ? ' (continued)' : ''}`}>
                            <AssetBarChartFooter
                                clientAssets={clientAssets}
                                inEstateAssetsTotals={inEstateTotals}
                                activeFormAsset={null}
                                investorGroup={investorGroup!}
                                title={'Current Net Worth'}
                            />
                        </BarChartSidebar>
                        <section>
                            <div data-testid="current-networth-content">

                                {clientAssetsPage && <div className="asset-summary-content">
                                    {clientAssetsPage.assetsSummary && (hasAssets(clientAssetsPage.assetsSummary) || assetListData(clientAssetsPage.assetsSummary).hasInEstatePersonalLiability) &&
                                        <>
                                            <div className="section-header">
                                                <h3>
                                                    {pageIndex === 0 && <div>Your current net worth
                                                        is <b>{formatCurrency(calculateCNWInEstateTotalValue(clientAssets, showDeathBenefit))}</b>:
                                                    </div>
                                                    }
                                                    {!hasInEstateAssets(clientAssetsPage.assetsSummary) &&
                                                        <div className="condensed-subtitle">Begin by entering assets or
                                                            entities</div>}
                                                </h3>
                                            </div>
                                            <CurrentNetWorthAssetListReport
                                                profileId={profileId}
                                                isDetailed={isDetailed}
                                                assetsDataForPage={clientAssetsPage.assetsSummary}
                                                investorGroup={investorGroup!}
                                                showDeathBenefit={showDeathBenefit}
                                                allAssets={clientAssetsCopy}
                                                pageNumber={pageIndex}
                                            />
                                        </>
                                    }
                                    {
                                        pageIndex === firstLegalEntityPageIndex && (hasOutOfEstateAssets(clientAssets) || hasOutOfEstateLiabilities(clientAssets) || hasOutOfEstateNonClientSummaries(clientAssetsPage)) &&
                                        <div className="section-header">
                                            <h3>
                                                Out of estate assets
                                                total <b>{formatCurrency(calculateOutOfEstateTotalValue(clientAssets)).trim()}</b>:
                                            </h3>
                                        </div>
                                    }
                                    {
                                        (hasOutOfEstateAssets(clientAssets) || hasOutOfEstateLiabilities(clientAssets)) &&
                                        (clientAssetsPage.legalEntitySummary || clientAssetsPage.nonClientMemberSummary) &&
                                        <OutOfEstateReportView
                                            assets={clientAssets} allItems={allItems}
                                            clientAssetsPage={clientAssetsPage}
                                            allLegalEntityTypeSummaries={legalEntityTypeSummaries}
                                            detailed={isDetailed}
                                            outOfEstateAccordionReport={OutOfEstateAccordionReport}
                                            outOfEstateCollapsedReport={OutOfEstateCollapsedReport}
                                            allNonClientMemberSummaries={nonClientMemberSummaries}
                                            outOfEstateNonClientAccordionReport={OutOfEstateNonClientAccordionReport}
                                            outOfEstateNonClientCollapsedReport={OutOfEstateNonClientCollapsedReport}/>
                                    }
                                </div>
                                }
                            </div>
                        </section>
                    </article>
                </PrintViewWrapper>
            ))}
        </>
    )
}