import React, {useEffect, useState} from "react";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import GoalUtils from "../Summary/GoalUtils";
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {GoalModelType} from "../models/GoalModelType";
import {
    addDiscretionaryGoal,
    deleteNonLifestyleGoal,
    selectGoalModel,
    updateLifestyleSpendingGoal,
    updateNonLifestyleGoalReducer
} from "../controller/GoalsModelSlice";
import {DiscretionaryGoalsType, GoalType, NonLifestyleGoalType} from "../models/GoalType";
import NonLifestyleGoalForm from "../components/NonLifestyleGoalForm";
import NonlifestyleGoalLivePreview from "../NonLifestyleGoals/NonlifestyleGoalLivePreview";
import AddNewDiscretionaryGoalBarChartSideBar from "../BarChartSidebar/AddNewDiscretionaryGoalBarChartSideBar";
import {goalsApiClient} from "../GoalsApiClient";
import DataEntryFooter from "../../components/DataEntry/DataEntryFooter";
import DeleteGoalModal from "../components/DeleteGoalModal";
import {LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";
import DiscardModal from "../../components/Modal/DiscardModal/DiscardModal";
import {GoalCharacteristics} from "../components/GoalCharacteristics";
import {RequiredFieldsBanner} from "../../components";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {wealthManagementApiClient} from "../../ClientManagement/WealthManagementApiClient";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import ScrollableContainer from "src/components/ScrollableContainer/ScrollableContainer";

const AddDiscretionaryGoal = ({isEditing = false}: { isEditing?: boolean }) => {
    const history = useHistory();
    const {
        investorGroup,
        discountRateSelection,
        proposal,
        lifestyleSpendingGoal
    } = useAppSelector<GoalModelType>(selectGoalModel)
    const dispatch = useAppDispatch();
    const profile = useAppSelector(selectProfile)!;
    let discretionaryGoalState: NonLifestyleGoalType;
    const [isRequiredFieldsBannerShown, setRequiredFieldsBannerShown] = useState(false);
    let lifestyleGoalState: LifestyleSpendingGoal = lifestyleSpendingGoal;
    const savedGoalInHistory: boolean = (history.location.state && !!history.location.state.savedGoal);
    let viewPage = (history.location.state && history.location.state.sourcePage) ? history.location.state.sourcePage : "Summary";
    let isSourcePageGoalsPrioritization: boolean = (viewPage === "Prioritization")
    if (savedGoalInHistory) {
        discretionaryGoalState = history.location.state.savedGoal;
    } else {
        discretionaryGoalState = GoalUtils.initializeNonLifestyleGoal(proposal.id, GoalType.DISCRETIONARY, DiscretionaryGoalsType.DISCRETIONARY, investorGroup);
    }
    const [discretionaryGoal, replaceDiscretionaryGoal] = useState<NonLifestyleGoalType>(discretionaryGoalState)
    const [lifestyleGoal, replaceLifestyleGoal] = useState<LifestyleSpendingGoal>(lifestyleGoalState)
    const portfolioReserveIsSet = proposal.portfolioReserveTargetLength !== undefined && proposal.portfolioReserveTargetLength !== null;

    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [isSaveButtonDisabled, updateSaveButtonDisabled] = useState(false);
    const [originalGoalState] = useState(discretionaryGoalState);

    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    useEffect(() => {
        setRequiredFieldsBannerShown(isRequiredFieldsBannerShown && isAnyRequiredFieldEmpty());
    }, [discretionaryGoal.userInputs.description]);

    function isNonLifestyleGoalFormDataMissing(nonLifestyleGoal: NonLifestyleGoalType) {
        const isDescriptionEmpty = !nonLifestyleGoal.userInputs.description.trim();
        return isDescriptionEmpty;
    }

    const handleModalDiscardButton = () => {
        setShowCancelModal(false);
        dispatch(updateNonLifestyleGoalReducer(originalGoalState));
        history.push(`/Profile/${proposal.id}/ClientProfile/Goals/${viewPage}`, {forceNavigate: true});
    };

    const isAnyRequiredFieldEmpty = () => {
        return isNonLifestyleGoalFormDataMissing(discretionaryGoal);
    }

    const handleCancelButtonClick = () => {
        if (discretionaryGoal === discretionaryGoalState || isProfileWithProposalsOrArchived) {
            history.push(`/Profile/${proposal.id}/ClientProfile/Goals/${viewPage}`, {forceNavigate: true});
        } else {
            setShowCancelModal(true);
        }
    }

    const handleSaveButtonClick = async () => {
        const isRequiredFieldEmpty = isAnyRequiredFieldEmpty();
        if (isRequiredFieldEmpty) {
            setRequiredFieldsBannerShown(true);
            return false;
        }
        updateSaveButtonDisabled(true);
        if (discretionaryGoal.userInputs.riskAsset != null && discretionaryGoal.userInputs.riskAsset > 100) {
            discretionaryGoal.userInputs.riskAsset = 100
        } else if (discretionaryGoal.userInputs.riskAsset != null && discretionaryGoal.userInputs.riskAsset < 0) {
            discretionaryGoal.userInputs.riskAsset = 0
        } else if (discretionaryGoal.userInputs.riskControl != null && discretionaryGoal.userInputs.riskControl > 100) {
            discretionaryGoal.userInputs.riskControl = 100
        } else if (discretionaryGoal.userInputs.riskControl != null && discretionaryGoal.userInputs.riskControl < 0) {
            discretionaryGoal.userInputs.riskControl = 0
        }
        const savedGoal = await goalsApiClient.postNonLifestyleGoal(proposal.id, GoalUtils.toNonLifestyleGoalRequest(discretionaryGoal));
        if (savedGoalInHistory && !discretionaryGoal.userInputs.isAssetPurchase) {
            await wealthManagementApiClient.deleteAssetRelianceStack(profile.id, discretionaryGoal.id);
        }
        dispatch(addDiscretionaryGoal({goal: savedGoal}))
        dispatch(updateLifestyleSpendingGoal(lifestyleGoal));
        if (isSourcePageGoalsPrioritization) {
            document.location.replace(`/Profile/${proposal.id}/ClientProfile/Goals/${viewPage}`);
        } else {
            history.push(`/Profile/${proposal.id}/ClientProfile/Goals/${viewPage}`);
        }
    }

    const [showDeleteGoalModal, setShowDeleteGoalModal] = useState(false);

    const handleOnDeleteClick = async () => {
        const response = await goalsApiClient.deleteNonLifestyleGoal(discretionaryGoal.id!);
        const wealthResponse = await wealthManagementApiClient.deleteAssetRelianceStack(profile.id, discretionaryGoal.id);
        if (response.status === 204 && wealthResponse) {
            dispatch(deleteNonLifestyleGoal({goal: discretionaryGoal}))
            history.goBack()
        }
    }

    const renderGoalCharacteristics = () => {
        return <GoalCharacteristics
            nonLifestyleGoal={discretionaryGoal}
            replaceNonLifestyleGoal={replaceDiscretionaryGoal}
            isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
        />;
    }

    return (
        <div className="layout-split-sidebar">
            <DiscardModal
                isOpen={showCancelModal}
                onClickKeepEditing={() => setShowCancelModal(false)}
                onClickDiscardChanges={handleModalDiscardButton}
                isEditing={isEditing}/>

            <AddNewDiscretionaryGoalBarChartSideBar nonLifestyleGoalFromForm={discretionaryGoal}
                                                    lifestyleSpendingGoalFromForm={lifestyleGoal}/>

            <ScrollableContainer id={'add-edit-discretionary-scroll-container'}>
                <div className='layout-data-entry-form add-new-goal-entry-form'>
                    <DataEntryHeader
                        className="add-discretionary-goals_title"
                        title={`${savedGoalInHistory ? 'Edit ' : 'Add '}` + discretionaryGoal.userInputs.selectedGoalSubType}
                        primaryButtonText="Save"
                        onPrimaryButtonClick={handleSaveButtonClick}
                        disablePrimaryButton={isSaveButtonDisabled}
                        secondaryButtonText="Cancel"
                        onSecondaryButtonClick={handleCancelButtonClick}
                        isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                    />
                    <RequiredFieldsBanner showAlert={isRequiredFieldsBannerShown} itemType="discretionary goal"/>
                    <div className="family-goal-container">
                        <NonLifestyleGoalForm
                            nonLifestyleGoal={discretionaryGoal}
                            replaceNonLifestyleGoal={replaceDiscretionaryGoal}
                            typeDropdownItems={GoalUtils.getGoalSubTypeDropdownItems(Object.values(DiscretionaryGoalsType))}
                            discountRateSelection={discountRateSelection}
                            lifestyleGoal={lifestyleGoal}
                            replaceLifestyleGoal={replaceLifestyleGoal}
                            portfolioReserveIsSet={portfolioReserveIsSet}
                            goalCharacteristics={renderGoalCharacteristics()}
                        />
                        <NonlifestyleGoalLivePreview nonLifestyleGoal={discretionaryGoal}/>
                    </div>
                    {
                        savedGoalInHistory &&
                        <DataEntryFooter
                            className="color-text--error"
                            hideDuplicate={true}
                            hideAddNew={true}
                            sticky={false}
                            onDelete={() => setShowDeleteGoalModal(true)}
                            disableDelete={isProfileWithProposalsOrArchived}
                        />
                    }
                    <DeleteGoalModal showDeleteGoalModal={showDeleteGoalModal}
                                     onCancel={() => setShowDeleteGoalModal(false)}
                                     onDelete={handleOnDeleteClick}
                                     goalType={GoalType.DISCRETIONARY}
                                     description={discretionaryGoal.userInputs.description}/>
                </div>
            </ScrollableContainer>
        </div>
    )
}

export default AddDiscretionaryGoal