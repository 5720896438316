import DataEntrySummary, {DataEntrySummaryItem} from "../../components/DataEntry/DataEntrySummary";
import {Icon} from "../../components";
import React from "react";
import {SubMenuProps} from "../../models/routeData/RouteParamTypes";
import {useAppSelector} from "../../store/hooks";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";

export interface StrategySidebarProps {
    estimatedImpact: DataEntrySummaryItem[],
    strategySummary?: DataEntrySummaryItem[],
    exemption?: DataEntrySummaryItem[],
    items?: SubMenuProps[],
    strategyName?: string,
    enableExemptionSection?: boolean
}

export const StrategySidebar: React.FC<StrategySidebarProps> = ({
                                                                    estimatedImpact,
                                                                    strategySummary,
                                                                    exemption,
                                                                    items= [],
                                                                    strategyName,
                                                                    enableExemptionSection
                                                                }) => {
    const {enableResourcesFolderOnWPO} = useAppSelector(selectReleaseToggles)!;
    return (
        <aside data-testid="strategySidebar">
            <div id="strategySummary">
                {strategySummary &&
                    <DataEntrySummary
                        title="Strategy Summary"
                        items={strategySummary}
                    />
                }
            </div>
            <div id="estimatedImpact" data-testid="estimatedImpactContainer">
                <DataEntrySummary
                    title="Estimated Impact"
                    items={estimatedImpact}
                >
                    <div id="estimatedInfoTooltip">
                        <Icon name="status_info_outline" type="info" className='paddingright-sm'
                              data-testid="estimatedImpactInfoIcon"/>
                        <div style={{fontStyle: "italic"}}>These values are estimates.</div>
                    </div>
                </DataEntrySummary>
            </div>
            {exemption && enableExemptionSection &&
                <div id="strategyExemption" data-testid="exemptionContainer">
                    <DataEntrySummary
                        title="Exemption"
                        items={exemption}
                    >
                    </DataEntrySummary>
                </div>
            }
            {
                enableResourcesFolderOnWPO && <div id="resources" data-testid="resourcesContainer">
                    <DataEntrySummary
                        title="Resources"
                        resourcesItems={items}
                        strategyName={strategyName}
                    />
                </div>
            }
        </aside>
    );
}