import React from "react";
import {Dropdown, DropdownItem} from "src/components";
import {Beneficiaries, Trust} from "../EstateFlowchartTypes";
import {DropdownOnChangeData} from "../../components/Dropdown/Dropdown";

type CopyButtonProps = {
    className: string;
    beneficiaryInfo: Beneficiaries;
    disabled: boolean;
    updateBeneficiaryInformation: (beneficiaries: Beneficiaries) => void;
};

function CopyButtonDropdown({className, beneficiaryInfo, disabled, updateBeneficiaryInformation}: Readonly<CopyButtonProps>): JSX.Element {

    const dropdownItemArray = beneficiaryInfo.firstDeath.trusts?.map((trust, index) => (
        {
            itemText: trust.trustName,
            value: trust.trustName,
            onClick: () => {},
            disabled: false,
        })) || [];

    const buildDropdownItemArray = (array: any) => {
        return array
            .filter((obj: any) => obj["itemText"] !== "")
            .sort((a: any, b: any) => a.itemText.localeCompare(b.itemText));
    }

    const onCopyDistribution = (e: DropdownOnChangeData<string>) => {
        if (beneficiaryInfo.firstDeath.trusts) {
            const selectedTrust = beneficiaryInfo.firstDeath.trusts.find(trust => trust.trustName === e.value);
            if (selectedTrust) {
                const newTrust = {
                    ...selectedTrust,
                    trustId: undefined,
                    trustName: "",
                } as Trust;
                let trusts = [...beneficiaryInfo.firstDeath.trusts]
                trusts.push(newTrust);
                updateBeneficiaryInformation({
                    ...beneficiaryInfo,
                    firstDeath: {trusts: trusts}
                });
            }
        }
    };

    return (
        <Dropdown
            className={className}
            alignRight={false}
            buttonKind="borderless"
            dropdownKind="menu"
            defaultText="COPY"
            panelWidth={245}
            disabled={disabled}
            onChange={onCopyDistribution}
        >
            {buildDropdownItemArray(dropdownItemArray).map((item: any, i: number) => (
                <DropdownItem
                    key={i}
                    itemText={item.itemText}
                    value={item.value}
                    onClick={item.onClick}
                    disabled={item.disabled}
                />
            ))}
        </Dropdown>
    );
}

export default CopyButtonDropdown;
