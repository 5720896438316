import React, {useEffect, useState} from "react";
import {Route, Switch, useParams} from "react-router-dom";
import {RouteWithId} from "../routes/types";
import ReviewFiduciariesForm from "./ReviewFiduciariesForm";
import EstateFlowchart from "./EstateFlowchart";
import RevocableTrustForm from "./RevocableTrustForm";
import {MemberGroup} from "../ClientManagement/models/InvestorGroupType";
import {clientManagementApiClient} from "../ClientManagement/ClientManagementApiClient";
import LoadingIndicator from "../pages/LoadingIndicator";

const EstateFlowchartMain = () => {
    const {id} = useParams<RouteWithId>();

    const getFamilyMembers = async (): Promise<MemberGroup> => {
        return clientManagementApiClient.getMemberGroup(id);
    }

    const [familyMembers, setFamilyMembers] = useState({} as MemberGroup);

    useEffect(() => {
        let isMounted = true;
        getFamilyMembers().then((res) => {
            if (isMounted) setFamilyMembers(res)
        })
        return () => {
            isMounted = false;
        }
    }, []);

    if (!familyMembers.primaryMember) {
        return <LoadingIndicator/>;
    }

    return (
        <Switch>
            <Route exact path="/Profile/:id/ClientProfile/EstateFlowchart">
                <EstateFlowchart id={id}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/EstateFlowchart/ReviewFiduciaries">
                <ReviewFiduciariesForm familyMembers={familyMembers}/>
            </Route>
            <Route
                path="/Profile/:id/ClientProfile/EstateFlowchart/:flowchartId/RevocableTrust/:revTrustId/:revTrustSubPage">
                <RevocableTrustForm familyMembersResponse={familyMembers}/>
            </Route>
        </Switch>
    )
}

export default EstateFlowchartMain;