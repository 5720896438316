import {AccountDetailsType, StandaloneAccountFormData, StandaloneLiabilityType} from "../models/StandaloneAccount";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {LegalEntityFormData, OwnershipDetailsFormData} from "../models/Ownership";
import {AlertBanner, RequiredFieldsBanner} from "../../components";
import AccountDetails from "./AccountDetails";
import TaxDetails from "../TaxDetails/TaxDetails";
import Ownership from "../Ownership";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {assetsApiClient} from "../AssetsApiClient";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import {setActiveFormAsset} from "../clientAssetsSlice";
import deepEquals from "fast-deep-equal";
import {TaxDetailsType, TaxStatusOptions} from "../models/TaxDetails";
import {mapStandaloneAccountToAccountDetails, mapStandaloneAccountToTaxDetails} from "./mappers";
import {extractOwnershipDetailsFormData, mapToOwnershipWriteModel} from "../Ownership/mappers";
import {AccountSummary} from "../AccountsCommon/AccountSummary";
import {isOwnershipPercentageNotEqual100} from "../Ownership/validation";
import {calculateInEstateFormValue} from "../AssetSummary/common/AssetSummaryCalculator";
import {hasSomeInEstateOwnershipInForm} from "../Ownership/helpers";
import {AssetClassifications} from "../models/AssetClassifications";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {NO_OP} from "../../constants/common";
import {HistoryBlockDiscardModal} from "../../components/HistoryBlockDiscardModal/HistoryBlockModal";
import { InstitutionOptions } from '../models/Institutions';
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";

type StandaloneAccountFormParams = {
    formatTitle: (accountName: string) => string,
    handleSave: (accountDetails: AccountDetailsType, taxDetails: TaxDetailsType, ownershipDetails: OwnershipDetailsFormData) => Promise<void>,
    handleCancel: (isFormChanged: boolean) => void,
    onClickEditHoldings: (accountDetails: AccountDetailsType, taxDetails: TaxDetailsType, ownershipDetails: OwnershipDetailsFormData) => Promise<void>,
    initialStandaloneAccount: StandaloneAccountFormData,
    classifications: AssetClassifications,
    memberGroup: MemberGroup,
    legalEntities: LegalEntityFormData[],
    updateLegalEntities: (legalEntities: LegalEntityFormData[]) => void,
    liabilityPaidByPortfolioForTaxableAccounts: boolean,
    liabilityPaidByPortfolioForDeferredAccounts: boolean
    loan?: StandaloneLiabilityType
};

const defaultDoesPermitBeneficiary = false;
const defaultIsEntityCapitalGains = true;

export function StandaloneAccountForm(props: StandaloneAccountFormParams) {
    const dispatch = useAppDispatch();
    const mounted = useRef(false);
    const initialUnrealizedCapitalGainsTax = props.initialStandaloneAccount.holdings?.unrealizedCapitalGainsTax === undefined
        ? null : props.initialStandaloneAccount.holdings?.unrealizedCapitalGainsTax;
    const initialDeferredTaxLiability = props.initialStandaloneAccount.holdings?.deferredTaxLiability === undefined?
        null : props.initialStandaloneAccount.holdings?.deferredTaxLiability;
    const initialInvestablePresentValue = props.initialStandaloneAccount.taxStatus === "Taxable" ? initialUnrealizedCapitalGainsTax : initialDeferredTaxLiability;
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const profile = useAppSelector(selectProfile);
    const [accountDetails, updateAccountDetails] = useState(mapStandaloneAccountToAccountDetails(props.initialStandaloneAccount));
    const [taxDetails, updateTaxDetails] = useState(mapStandaloneAccountToTaxDetails(props.initialStandaloneAccount));
    const [ownershipDetailsFormData, updateOwnershipDetailsFormData] = useState(extractOwnershipDetailsFormData(props.initialStandaloneAccount));
    const [unrealizedCapitalGainsTax, updateUnrealizedCapitalGainsTax] =  useState(initialUnrealizedCapitalGainsTax);
    const [deferredTaxLiability, updateDeferredTaxLiability] = useState(initialDeferredTaxLiability);
    const [isSaveButtonDisabled, updateSaveButtonDisabled] = useState(isProfileWithProposalsOrArchived);
    const [isRequiredFieldsBannerShown, setRequiredFieldsBannerShown] = useState(false);
    const [isOwnershipPercentageErrorBannerShown, setOwnershipPercentageErrorBannerShown] = useState(false);
    const [totalInvestablePresentValue, updateTotalInvestablePresentValue] = useState(initialInvestablePresentValue);
    const [showNavigationModal, setShowNavigationModal] = useState(true);
    const [unrealizedCapitalGainsTaxForAccountSummary, setUnrealizedCapitalGainsTaxForAccountSummary] = useState<number | null>(null);
    const [deferredTaxLiabilityForAccountSummary, setDeferredTaxLiabilityForAccountSummary] = useState<number | null>(null);
    let ownershipInEstate = ownershipDetailsFormData.memberOwnerships.reduce((total, memberOwnership) => total + Number(memberOwnership.percentage), 0) / 100;
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const [showBanner, setShowBanner] = useState(false)
    const [loans] = useState<StandaloneLiabilityType>(props.loan!);
    const currentLiabilityValue = loans!.personalLiabilities.reduce((x,pLiability) => x + pLiability.loanBalance, 0)

    useEffect(() => {
        const totalValue = props.initialStandaloneAccount.holdings?.totalMarketValue ?? 0;
        dispatch(setActiveFormAsset({
            assetType: 'standaloneAccount',
            id: props.initialStandaloneAccount.id,
            inEstateValue: calculateInEstateFormValue(totalValue, ownershipDetailsFormData.memberOwnerships),
            description: accountDetails.name,
            hasInEstateOwnership: hasSomeInEstateOwnershipInForm(mapToOwnershipWriteModel(ownershipDetailsFormData).memberOwnerships),
        }));
        setShowBanner(currentLiabilityValue > calculateInEstateFormValue(totalValue, ownershipDetailsFormData.memberOwnerships))
        return () => {
            dispatch(setActiveFormAsset(null));
        };
    }, [accountDetails.name, ownershipDetailsFormData]);

    useEffect(() => {
        setRequiredFieldsBannerShown(isRequiredFieldsBannerShown && isAnyRequiredFieldEmpty());
    }, [accountDetails, ownershipDetailsFormData.legalEntityOwnerships, taxDetails]);

    useEffect(() => {
        setOwnershipPercentageErrorBannerShown(isOwnershipPercentageErrorBannerShown &&
            isOwnershipPercentageNotEqual100(ownershipDetailsFormData));
    }, [ownershipDetailsFormData.legalEntityOwnerships, ownershipDetailsFormData.memberOwnerships]);

    useEffect(() => {
        mounted.current = true;
        setShowBanner(currentLiabilityValue > totalInEstateValue!)
        if (accountDetails.taxStatus === "Deferred") {
            const updatedTaxDetails: TaxDetailsType = {
                isEntityCapitalGains: null,
                isLiabilityPaidByPortfolio: props.liabilityPaidByPortfolioForDeferredAccounts,
            };
            updateTaxDetails(updatedTaxDetails);
            handleDeferredTaxLiabilityChange()
                .catch(reason => console.log(reason));
            updateUnrealizedCapitalGainsTax(null);
            setUnrealizedCapitalGainsTaxForAccountSummary(null);
        } else if (accountDetails.taxStatus === "Taxable" && taxDetails.isEntityCapitalGains !== null) {
            handleUnrealizedCapitalGainsTaxChange(taxDetails.isEntityCapitalGains)
                .catch(reason => console.log(reason));
            updateDeferredTaxLiability(null);
            setDeferredTaxLiabilityForAccountSummary(null);
        } else {
            updateUnrealizedCapitalGainsTax(null);
            updateDeferredTaxLiability(null);
            setUnrealizedCapitalGainsTaxForAccountSummary(null);
            setDeferredTaxLiabilityForAccountSummary(null);
        }
        return () => {
            mounted.current = false;
        }
    }, [accountDetails.taxStatus]);

    useEffect(() => {
        getDynamicProratedTaxValue()
    }, [ownershipDetailsFormData.legalEntityOwnerships, ownershipDetailsFormData.memberOwnerships, accountDetails.taxStatus, taxDetails])
    const getDynamicProratedTaxValue = () => {
        const hasOwnership = ownershipInEstate !== 0;
        if(accountDetails.taxStatus === "Deferred"){
            const proratedTaxValueDeferred = (deferredTaxLiabilityForAccountSummary !== null) && hasOwnership
                ? deferredTaxLiabilityForAccountSummary * ownershipInEstate : deferredTaxLiabilityForAccountSummary;
            updateDeferredTaxLiability(proratedTaxValueDeferred)
            updateTotalInvestablePresentValue(proratedTaxValueDeferred)


        } else if(accountDetails.taxStatus === "Taxable") {
            const proratedTaxValueCapGains = (unrealizedCapitalGainsTaxForAccountSummary !== null)  && hasOwnership
                ? unrealizedCapitalGainsTaxForAccountSummary * ownershipInEstate : unrealizedCapitalGainsTaxForAccountSummary;
            updateUnrealizedCapitalGainsTax(proratedTaxValueCapGains)
            updateTotalInvestablePresentValue(proratedTaxValueCapGains)
        }
    }

    const handleUnrealizedCapitalGainsTaxChange = async (isEntityCapitalGains: boolean) => {
        if (props.initialStandaloneAccount.id) {
            let unrealizedCapitalGainsTaxResponseAS = await assetsApiClient.getUnrealizedCapitalGainsTax(profile.id, props.initialStandaloneAccount.id, isEntityCapitalGains);
            setUnrealizedCapitalGainsTaxForAccountSummary(unrealizedCapitalGainsTaxResponseAS);
            const proratedCapGainsTax = (unrealizedCapitalGainsTaxResponseAS !== null) && (ownershipInEstate!== 0)
                ? unrealizedCapitalGainsTaxResponseAS * ownershipInEstate : unrealizedCapitalGainsTaxResponseAS;
            updateUnrealizedCapitalGainsTax(proratedCapGainsTax);
            updateTotalInvestablePresentValue(proratedCapGainsTax)
        }
    }

    const handleDeferredTaxLiabilityChange = async () => {
        if (props.initialStandaloneAccount.id) {
            let deferredTaxLiabilityResponseAS = await assetsApiClient.getDeferredTaxLiability(profile.id, props.initialStandaloneAccount.id);
            setDeferredTaxLiabilityForAccountSummary(deferredTaxLiabilityResponseAS);
            const proratedDeferredTax = (deferredTaxLiabilityResponseAS !== null) && (ownershipInEstate!==0)
                ? deferredTaxLiabilityResponseAS *  ownershipInEstate : deferredTaxLiabilityResponseAS;
            updateDeferredTaxLiability(proratedDeferredTax);
            updateTotalInvestablePresentValue(proratedDeferredTax)
        }
    }

    const isAnyRequiredFieldEmpty = () => {
        const accountNameIsBlank = !accountDetails.name.trim();
        const asOfDateIsBlank = !accountDetails.asOfDate?.trim();
        const institutionIsBlank = !accountDetails.institution?.trim();
        const otherInstitutionIsBlank = (!accountDetails.otherInstitution?.trim() && accountDetails.institution == 'Other');
        const otherInstitutionIsInvalid = /[,\\|\[\]?.()_{}=@!$%#^+~`;'"<>]/.test(accountDetails.otherInstitution ? accountDetails.otherInstitution : '') 
        const institutionIsInvalid = !institutionIsBlank ? (!InstitutionOptions.some(firm => firm.value === accountDetails.institution) && accountDetails.institution?.slice(0,6) != 'Other:' ) : undefined
        const isLiabilityQuestionVisibleForTaxable = accountDetails.taxStatus === "Taxable" && !!taxDetails.isEntityCapitalGains;
        const isLiabilityQuestionVisibleForDeferred = accountDetails.taxStatus === "Deferred";
        const isLiabilityQuestionVisible = isLiabilityQuestionVisibleForTaxable || isLiabilityQuestionVisibleForDeferred;
        const isLiabilityQuestionBlank = taxDetails.isLiabilityPaidByPortfolio === null;

        const isOwnershipDataMissing = ownershipDetailsFormData.legalEntityOwnerships.some((ownership) => {
            return !ownership.name.trim() || !ownership.type;
        });
        if(releaseToggles?.enableInstitutionDropdown){
            return accountNameIsBlank || asOfDateIsBlank || (institutionIsBlank || institutionIsInvalid || otherInstitutionIsBlank || otherInstitutionIsInvalid) || (isLiabilityQuestionBlank && isLiabilityQuestionVisible) || isOwnershipDataMissing;
        }else{
            return accountNameIsBlank || asOfDateIsBlank || (isLiabilityQuestionBlank && isLiabilityQuestionVisible) || isOwnershipDataMissing;
        }
    };

    const resetAccountAndTaxDetailsToDefaults = (selectedTaxStatus: TaxStatusOptions) => {
        const updatedAccountDetails: AccountDetailsType = {
            ...accountDetails,
            taxStatus: selectedTaxStatus,
            doesPermitBeneficiary: null,
        };
        const updatedTaxDetails: TaxDetailsType = {
            isEntityCapitalGains: null,
            isLiabilityPaidByPortfolio: null,
        };

        switch (selectedTaxStatus) {
            case "Taxable":
                updatedTaxDetails.isEntityCapitalGains = defaultIsEntityCapitalGains;
                updatedTaxDetails.isLiabilityPaidByPortfolio = props.liabilityPaidByPortfolioForTaxableAccounts
                break;
            case "Deferred":
                updatedAccountDetails.doesPermitBeneficiary = defaultDoesPermitBeneficiary;
                updatedTaxDetails.isLiabilityPaidByPortfolio = props.liabilityPaidByPortfolioForDeferredAccounts
                break;
            case "Exempt":
                updatedAccountDetails.doesPermitBeneficiary = defaultDoesPermitBeneficiary;
                break;
            default:
                break;
        }
        return {updatedAccountDetails, updatedTaxDetails};
    };

    const handleTaxStatusChange = (selectedTaxStatus: TaxStatusOptions) => {
        const {updatedAccountDetails, updatedTaxDetails} = resetAccountAndTaxDetailsToDefaults(selectedTaxStatus);
        updateTaxDetails(updatedTaxDetails);
        updateAccountDetails(updatedAccountDetails);
    }

    const handleUpdateAccountDetails = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        updateAccountDetails({
            ...accountDetails,
            [name]: value
        });
    };

    const isFormChanged = () => {
        const updated: StandaloneAccountFormData = {
            ...accountDetails,
            ...taxDetails,
            ...ownershipDetailsFormData,
        };
        const initial: StandaloneAccountFormData = {
            ...mapStandaloneAccountToAccountDetails(props.initialStandaloneAccount),
            ...mapStandaloneAccountToTaxDetails(props.initialStandaloneAccount),
            ...extractOwnershipDetailsFormData(props.initialStandaloneAccount),
        };
        return !deepEquals(initial, updated);
    };

    const onCancelClick = () => {
        setShowNavigationModal(false)
        props.handleCancel(isFormChanged());
    }

    const handleSaveButtonClick = async () => {
        const {isValid} = validateForm();
        if (isValid) {
            updateSaveButtonDisabled(true);
            setShowNavigationModal(false);
            const savedInstitutions: string | undefined = localStorage.getItem('recentInstitution')?.trim()
            const institutionArray: [string] = savedInstitutions ? JSON.parse(savedInstitutions) : []
            if (accountDetails.institution !== 'None' && accountDetails.institution?.slice(0,5) !== 'Other' && releaseToggles?.enableInstitutionDropdown) {
                localStorage.setItem('recentInstitution', JSON.stringify([accountDetails.institution, ...institutionArray.filter(option => option !== accountDetails.institution)]?.slice(0,4)));
            }
            if(accountDetails.otherInstitution && (accountDetails.institution === 'Other' || accountDetails.institution?.slice(0,7) === 'Other: ')){
                accountDetails.institution = "Other: " + accountDetails.otherInstitution
                accountDetails.otherInstitution = undefined
            }else if(accountDetails.otherInstitution && accountDetails.institution?.slice(0,7) !== 'Other: '){
                accountDetails.otherInstitution = undefined
            }
            await props.handleSave(accountDetails, taxDetails, ownershipDetailsFormData);
            return true
        }
        return false
    };

    const handleEditHoldingsClick = async () => {
        setShowNavigationModal(false)
        const {isValid} = validateForm();
        if (isValid) {
            if(accountDetails.otherInstitution && (accountDetails.institution === 'Other' || accountDetails.institution?.slice(0,7) === 'Other: ')){
                accountDetails.institution = "Other: " + accountDetails.otherInstitution
                accountDetails.otherInstitution = undefined
            }else if(accountDetails.otherInstitution && accountDetails.institution?.slice(0,7) !== 'Other: '){
                accountDetails.otherInstitution = undefined
            }
            await props.onClickEditHoldings(accountDetails, taxDetails, ownershipDetailsFormData);
        }
    };

    const validateForm = () => {
        const isRequiredFieldEmpty = isAnyRequiredFieldEmpty();
        if (isRequiredFieldEmpty) {
            setRequiredFieldsBannerShown(true);
        }

        const isOwnershipPercentageInvalid = isOwnershipPercentageNotEqual100(ownershipDetailsFormData);
        if (isOwnershipPercentageInvalid) {
            setOwnershipPercentageErrorBannerShown(true);
        }

        return {
            isValid: !isRequiredFieldEmpty && !isOwnershipPercentageInvalid
        };
    };

    const handleClose = () =>{
        setShowBanner(false)
    }

    const totalAssetValue = props.initialStandaloneAccount.holdings ? props.initialStandaloneAccount.holdings.totalMarketValue : null;

    const totalInEstateValue = props.initialStandaloneAccount.holdings ? props.initialStandaloneAccount.holdings.marketValue?.inEstateValue : null;

    return <div className="layout-data-entry-form">
        <HistoryBlockDiscardModal
            when={isFormChanged() && showNavigationModal}
        />
        <DataEntryHeader
            title={props.formatTitle(accountDetails.name)}
            primaryButtonText="Save"
            onPrimaryButtonClick={handleSaveButtonClick}
            disablePrimaryButton={isSaveButtonDisabled}
            secondaryButtonText="Cancel"
            onSecondaryButtonClick={onCancelClick}
        />
        <RequiredFieldsBanner showAlert={isRequiredFieldsBannerShown} itemType="standalone account"/>
        <AlertBanner
            className={"liability-width"}
            showAlert={showBanner}
            icon =  'error'
            showCloseBtn ={true}
            type = 'warning'
            fullWidth={false}
            onClose =  {handleClose}
            message = "The value of this asset is lower than its corresponding liabilities."
        />
        <div className="standalone-account__form layout-data-entry-form">
            <article>
                <AccountDetails
                    accountDetails={accountDetails}
                    handleUpdateAccountDetails={handleUpdateAccountDetails}
                    updateAccountDetails={updateAccountDetails}
                    handleTaxStatusChange={handleTaxStatusChange}
                    isRequiredFieldsBannerShown={isRequiredFieldsBannerShown}
                    isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                />
                {(accountDetails.taxStatus === "Taxable" || accountDetails.taxStatus === "Deferred") &&
                    <TaxDetails taxDetails={taxDetails}
                                updateTaxDetails={updateTaxDetails}
                                taxStatus={accountDetails.taxStatus}
                                isRequiredFieldsBannerShown={isRequiredFieldsBannerShown}
                                unrealizedCapitalGainsTax={unrealizedCapitalGainsTax}
                                deferredTaxLiability={deferredTaxLiability}
                                totalInvestablePresentValue={totalInvestablePresentValue}
                                handleUnrealizedCapitalGainsTaxChange={handleUnrealizedCapitalGainsTaxChange}
                                handleLiabilityPaidByPortfolioChange={NO_OP}
                                isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                    />
                }
                <Ownership
                    onFormDataChange={updateOwnershipDetailsFormData}
                    formData={ownershipDetailsFormData}
                    totalAssetValue={totalAssetValue}
                    isOwnershipPercentageErrorBannerShown={isOwnershipPercentageErrorBannerShown}
                    memberGroup={props.memberGroup}
                    legalEntities={props.legalEntities}
                    updateLegalEntities={props.updateLegalEntities}
                    isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                    taxDetails = {taxDetails}
                />
            </article>
            <AccountSummary
                assetType={'standaloneAccount'}
                holdings={props.initialStandaloneAccount.holdings}
                unrealizedCapitalGainsTax={unrealizedCapitalGainsTaxForAccountSummary}
                deferredTaxLiability={deferredTaxLiabilityForAccountSummary}
                onClick={handleEditHoldingsClick}
                classifications={props.classifications}
            />
        </div>
    </div>;
}