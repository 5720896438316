import {AssetsSummary, CurrentNetWorthAsset} from "../../models/Assets";
import {COLOR_ASSETS_ACCOUNTS} from "../../../constants/colors";
import {AssetAccordionHeader} from "../../AssetSummary/common/AssetAccordionHeader";
import {AccordionItemWithActionMenu} from "../../../components";
import React, {ReactNode, useContext, useEffect} from "react";
import {assetListData, sortByOrdinalAndInEstateOrMarketValue} from "../../AssetSummary/common/utils";
import {
    calculateAllAccountsInEstateTotalMarketValue,
    calculateCNWAssetsJointlyOwnedTotalPresentValue,
    calculateCNWAssetsMemberOwnedTotalPresentValue,
    calculateJointlyOwnedValue,
    calculateMemberOwnedValue
} from "../../AssetSummary/common/AssetSummaryCalculator";
import {InvestorGroupMember, InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {AssetAccordionContentHeader} from "../../AssetSummary/common/AssetAccordionContentHeader";
import {TableCell} from "../../../components/Table/TableCell";
import {HeldAwayAccountSummary, LegalAgreement} from "../../models/InvestmentProgram";
import {StandaloneAccount} from "../../models/StandaloneAccount";
import {StandaloneAccountRow} from "../../AssetSummary/InEstate/StandaloneAccountRow";
import {LegalAgreementRow} from "../../AssetSummary/InEstate/LegalAgreementRow";
import {showOwnershipType} from "../../Ownership/helpers";
import {getOwnershipDescription} from "./ownershipUtils";
import {useHistory} from "react-router-dom";
import AssetsViewContext from "../../common/AssetsViewContext";
import {DeleteModalProps} from "../../common/AssetsView";
import AccountActionMenu from "../../AssetSummary/InEstate/AccountActionMenu";
import {shortenName} from "../../common/textUtils";
import {
    PartiallyOwnedInvestmentAccountReadModel,
    PartiallyOwnedLegalAgreement
} from "../../models/PartiallyOwnedInvestmentAccount";
import {PartiallyOwnedInvestmentAccountRow} from "../../AssetSummary/InEstate/PartiallyOwnedInvestmentAccountRow";
import {HeldAwayAccountRow} from "../../AssetSummary/InEstate/HeldAwayAccountRow";
import {BankingAccountsSummary} from "../../models/BankingAccounts";
import {BankingAccountRow} from "../../AssetSummary/InEstate/BankingAccountRow";
import {StandaloneAccountOrLegalAgreement} from "../../AssetSummary/InEstate/AccountTableSection";

export interface InEstateAccountsProps {
    profileId: string,
    assetsData: AssetsSummary;
    investorGroup: InvestorGroupType;
    onRemoveAssetClick: (deleteModalProps: DeleteModalProps) => void;
    onRemoveInvestmentProgram: () => void;
    onRefreshInvestmentProgramHoldings: () => void;
}


const Accounts = ({
                      profileId,
                      assetsData,
                      investorGroup,
                      onRemoveAssetClick,
                      onRemoveInvestmentProgram,
                      onRefreshInvestmentProgramHoldings
                  }: InEstateAccountsProps) => {

    const history = useHistory();
    const viewType = useContext(AssetsViewContext);

    const {
        getFormattedTotalPresentValue,
        getFormattedPresentValueForCurrentNetWorth,
        hasInEstateAccount,
        hasInEstateInvestmentProgram,
        hasInEstateInvestmentProgramHeldAA,
        hasInEstateBankingAccount,
        hasInEstatePartiallyOwnedInvestmentAccounts,
        inEstatePartiallyOwnedInvestmentAccounts,
        inEstateStandaloneAccounts,
        inEstateLegalAgreements,
        inEstateHeldAwayAccounts,
        inEstateBankingAccounts,
    } = assetListData(assetsData);

    const primaryMember = investorGroup.primaryMember;
    const partnerMember = investorGroup.partnerMember;

    const currentNetWorthStandaloneAccounts: CurrentNetWorthAsset[] = inEstateStandaloneAccounts.map(account => ({
        id: account.id,
        name: account.name,
        presentValue: account.marketValue.totalValue,
        assetType: "standaloneAccount",
        ownershipCategory: account.ownershipCategory,
        memberOwnerships: account.memberOwnerships
    }));

    const currentNetWorthLegalAgreements: CurrentNetWorthAsset[] = inEstateLegalAgreements.map(legalAgreement => ({
        id: legalAgreement.id,
        name: legalAgreement.name,
        presentValue: legalAgreement.marketValue,
        assetType: "investmentProgram",
        ownershipCategory: legalAgreement.ownershipCategory,
        memberOwnerships: legalAgreement.memberOwnerships
    }));

    const currentNetWorthHeldAwayAccounts: CurrentNetWorthAsset[] = inEstateHeldAwayAccounts.map(heldAwayAccount => ({
        id: heldAwayAccount.id,
        name: heldAwayAccount.financialAccountName,
        presentValue: heldAwayAccount.holdings.marketValue?.totalValue || 0,
        assetType: "investmentProgramHAA",
        ownershipCategory: heldAwayAccount.ownershipCategory,
        memberOwnerships: heldAwayAccount.memberOwnerships
    }));

    const currentNetWorthPartiallyOwnedInvestmentAccounts: CurrentNetWorthAsset[] = inEstatePartiallyOwnedInvestmentAccounts.map(legalAgreement => ({
        id: legalAgreement.id,
        name: legalAgreement.legalAgreementName,
        presentValue: legalAgreement.marketEstateValue.totalValue,
        assetType: "partiallyOwnedInvestmentAccount",
        ownershipCategory: legalAgreement.ownershipCategory,
        memberOwnerships: legalAgreement.memberOwnerships
    }));

    const currentNetWorthBankingAccounts: CurrentNetWorthAsset[] = inEstateBankingAccounts.map(bankingAccount => ({
        id: bankingAccount.id,
        name: bankingAccount.accountName,
        presentValue: bankingAccount.holdings ? bankingAccount.holdings.marketValue?.totalValue || 0 : 0,
        assetType: "bankingAccount",
        ownershipCategory: bankingAccount.ownershipCategory,
        memberOwnerships: bankingAccount.memberOwnerships
    }));

    function generateAssetPopOverContent(account: StandaloneAccount | LegalAgreement | PartiallyOwnedLegalAgreement | HeldAwayAccountSummary | BankingAccountsSummary) {
        const ownershipDescription = getOwnershipDescription(account, primaryMember, partnerMember)
        return ownershipDescription ? <>{ownershipDescription}</> : undefined;
    }


    function calculateMemberOwnedAccountAndIPValue(member: InvestorGroupMember) {
        return calculateCNWAssetsMemberOwnedTotalPresentValue(member.id, currentNetWorthStandaloneAccounts)
            + calculateCNWAssetsMemberOwnedTotalPresentValue(member.id, currentNetWorthLegalAgreements)
            + calculateCNWAssetsMemberOwnedTotalPresentValue(member.id, currentNetWorthHeldAwayAccounts)
            + calculateCNWAssetsMemberOwnedTotalPresentValue(member.id, currentNetWorthPartiallyOwnedInvestmentAccounts)
            + calculateCNWAssetsMemberOwnedTotalPresentValue(member.id, currentNetWorthBankingAccounts);
    }

    function calculateJointlyOwnedAccountAndIPValue() {
        return calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthStandaloneAccounts)
            + calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthLegalAgreements)
            + calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthHeldAwayAccounts)
            + calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthPartiallyOwnedInvestmentAccounts)
            + calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthBankingAccounts);
    }

    const investmentProgramName = assetsData.investmentProgram ? assetsData.investmentProgram.name : '';
    const bankingData = assetsData.bankingAccounts ? assetsData.bankingAccounts : [];

    const actionMenu: ReactNode = assetsData.investmentProgram || assetsData.partiallyOwnedLegalAgreements.length > 0 || bankingData.length > 0
        ? <AccountActionMenu onRemoveInvestmentProgram={onRemoveInvestmentProgram}
                             onRefreshInvestmentProgramHoldings={onRefreshInvestmentProgramHoldings}
                             investmentProgramName={investmentProgramName}
                             hasInvestmentProgram={assetsData.investmentProgram  !== null}/>
        : undefined;


    const allAssetAccounts = sortByOrdinalAndInEstateOrMarketValue([
        ...inEstateLegalAgreements,
        ...inEstateHeldAwayAccounts,
        ...inEstateStandaloneAccounts,
        ...inEstatePartiallyOwnedInvestmentAccounts,
        ...inEstateBankingAccounts,
    ]);

    const renderAccountRow = (accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) => {
        const hasOutOfEstateOwnership = accountOrLegalAgreement.memberOwnerships?.filter(x=>!x.isInEstateMember)?.length > 0
            || accountOrLegalAgreement.legalEntityOwnerships?.length > 0;
        let accountToRender;
        if ((accountOrLegalAgreement as any).legalAgreementNumber) {
            if(hasOutOfEstateOwnership) {
                accountToRender = renderPartiallyOwnedInvestmentAccountRow(accountOrLegalAgreement);
            }
            else {
                accountToRender = renderLegalAgreementRow(accountOrLegalAgreement);
            }
        }
        else if ((accountOrLegalAgreement as any).financialAccountNumber) {
            accountToRender = renderHeldAwayAccountRow(accountOrLegalAgreement);
        }
        else if ((accountOrLegalAgreement as any).accountPersistentId) {
            accountToRender = renderBankingAccountRow(accountOrLegalAgreement);
        }
        else {
            accountToRender = renderStandaloneAccountRow(accountOrLegalAgreement);
        }
        return accountToRender;
    }

    const renderLegalAgreementRow = (accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) => {
        const legalAgreement = accountOrLegalAgreement as LegalAgreement;
        return <LegalAgreementRow legalAgreement={legalAgreement}
                                  actionsDisabled={false}
                                  onClickEdit={id => {
                                      history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditLegalAgreement/${id}`);
                                  }}
                                  onClickViewHoldings={(legalAgreementId: string) => {
                                      history.push(`/Profile/${profileId}/ClientProfile/${viewType}/LegalAgreementHoldings/${legalAgreementId}`);
                                  }}
                                  key={legalAgreement.id}
                                  gridClassName={"current-net-worth-grid-with-actionmenu"}
                                  renderLegalAgreementDetails={(agreement: LegalAgreement) => {
                                      return <>
                                          <TableCell text={shortenName(agreement.name)}
                                                     className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                                     popoverContent={generateAssetPopOverContent(agreement)}
                                                     popoverWidth={"288px"}
                                                     subtext={showOwnershipType(agreement.ownershipCategory)}
                                          />
                                          {investorGroup.partnerMember && <>
                                              <TableCell
                                                  text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.primaryMember.id, agreement.memberOwnerships, agreement.ownershipCategory, agreement.marketValue))}
                                                  className="textalign-right"/>
                                              <TableCell
                                                  text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.partnerMember.id, agreement.memberOwnerships, agreement.ownershipCategory, agreement.marketValue))}
                                                  className="textalign-right"/>
                                              <TableCell
                                                  text={getFormattedPresentValueForCurrentNetWorth(calculateJointlyOwnedValue(agreement.ownershipCategory, agreement.marketValue))}
                                                  className="textalign-right"/>
                                          </>
                                          }
                                      </>
                                  }}/>;

    }

    const renderPartiallyOwnedInvestmentAccountRow = (accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) => {
        const partiallyOwnedInvestmentAccount = accountOrLegalAgreement as PartiallyOwnedLegalAgreement;
        return <PartiallyOwnedInvestmentAccountRow
            partiallyOwnedInvestmentAccount={partiallyOwnedInvestmentAccount}
            actionsDisabled={false}
            onClickEdit={(accountId: string) => {
                history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditPartiallyOwnedInvestment/${accountId}`);
            }}
            onClickDelete={(account: PartiallyOwnedLegalAgreement) => {
                onRemoveAssetClick({
                    showDeleteModal: true,
                    modalTitle: "Asset",
                    assetDescription: account.legalAgreementName,
                    assetType: "partially owned investment account",
                    assetId: account.id,
                });
            }}
            onClickViewHoldings={(accountId: string) => {
                history.push(`/Profile/${profileId}/ClientProfile/${viewType}/PartiallyOwnedInvestmentAccountHoldings/${accountId}`);
            }}
            key={partiallyOwnedInvestmentAccount.id}
            gridClassName={"current-net-worth-grid-with-actionmenu"}
            renderPartiallyOwnedInvestmentAccountDetails={(account: PartiallyOwnedLegalAgreement) => {
                return <>
                    <TableCell text={shortenName(account.legalAgreementName)}
                               subtext={showOwnershipType(account.ownershipCategory)}
                               popoverContent={generateAssetPopOverContent(account)}
                               popoverWidth={"288px"}
                               className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                    />
                    {investorGroup.partnerMember && <>
                        <TableCell
                            text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.primaryMember.id, account.memberOwnerships, account.ownershipCategory, account.marketEstateValue.totalValue))}
                            className="textalign-right"/>
                        <TableCell
                            text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.partnerMember.id, account.memberOwnerships, account.ownershipCategory, account.marketEstateValue.totalValue))}
                            className="textalign-right"/>
                        <TableCell
                            text={getFormattedPresentValueForCurrentNetWorth(calculateJointlyOwnedValue(account.ownershipCategory, account.marketEstateValue.totalValue))}
                            className="textalign-right"/>
                    </>
                    }
                </>
            }}/>;
    }

    const renderHeldAwayAccountRow = (accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) => {
        const heldAwayAccount = accountOrLegalAgreement as HeldAwayAccountSummary;

        return <HeldAwayAccountRow heldAwayAccount={heldAwayAccount}
                                   actionsDisabled={false}
                                   onClickEdit={(heldAwayAccountId: string) => {
                                       history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditHeldAwayAccount/${heldAwayAccountId}`);
                                   }}
                                   onClickViewHoldings={(heldAwayAccountId: string) => {
                                       history.push(`/Profile/${profileId}/ClientProfile/${viewType}/HeldAwayAccountHoldings/${heldAwayAccountId}`);
                                   }}
                                   key={heldAwayAccount?.id}
                                   gridClassName={"current-net-worth-grid-with-actionmenu"}
                                   renderHeldAwayAccountDetails={(heldAwayAccounts: HeldAwayAccountSummary) => {
                                       return <>
                                           <TableCell text={shortenName(heldAwayAccounts.financialAccountName)}
                                                      subtext={showOwnershipType(heldAwayAccounts.ownershipCategory)}
                                                      className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                                      popoverContent={generateAssetPopOverContent(heldAwayAccounts)}
                                                      popoverWidth={"288px"}
                                           />
                                           {investorGroup.partnerMember && <>
                                               <TableCell
                                                   text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.primaryMember.id, heldAwayAccounts.memberOwnerships, heldAwayAccounts.ownershipCategory, heldAwayAccounts.holdings.marketValue?.totalValue || 0))}
                                                   className="textalign-right"/>
                                               <TableCell
                                                   text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.partnerMember.id, heldAwayAccounts.memberOwnerships, heldAwayAccounts.ownershipCategory, heldAwayAccounts.holdings.marketValue?.totalValue || 0))}
                                                   className="textalign-right"/>
                                               <TableCell
                                                   text={getFormattedPresentValueForCurrentNetWorth(calculateJointlyOwnedValue(heldAwayAccounts.ownershipCategory, heldAwayAccounts.holdings.marketValue?.totalValue || 0))}
                                                   className="textalign-right"/>
                                           </>
                                           }
                                       </>
                                   }}/>;
    }

    const renderBankingAccountRow = (accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) => {
        const bankingAccount = accountOrLegalAgreement as BankingAccountsSummary;

        return <BankingAccountRow bankingAccount={bankingAccount}
                                  actionsDisabled={false}
                                  onClickEdit={(bankingAccountId: string) => {
                                      history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditBankingAccount/${bankingAccountId}`);
                                  }}
                                  onClickDelete={(bankingAccountDelete: BankingAccountsSummary) => {
                                      onRemoveAssetClick({
                                          showDeleteModal: true,
                                          modalTitle: "Asset",
                                          assetDescription: bankingAccountDelete.accountName,
                                          assetType: "banking account",
                                          assetId: bankingAccountDelete.id,
                                      });
                                  }}
                                  onClickViewHoldings={(bankingAccountId: string) => {
                                      history.push(`/Profile/${profileId}/ClientProfile/${viewType}/BankingAccount/${bankingAccountId}/Holdings`);
                                  }}
                                  key={bankingAccount?.id}
                                  gridClassName={"current-net-worth-grid-with-actionmenu"}
                                  renderBankingAccountDetails={(bankingAccounts: BankingAccountsSummary) => {
                                      return <>
                                          <TableCell text={shortenName(bankingAccounts.accountName)}
                                                     subtext={showOwnershipType(bankingAccounts.ownershipCategory)}
                                                     className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                                     popoverContent={generateAssetPopOverContent(bankingAccounts)}
                                                     popoverWidth={"288px"}
                                          />
                                          {investorGroup.partnerMember && <>
                                              <TableCell
                                                  text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.primaryMember.id, bankingAccounts.memberOwnerships, bankingAccounts.ownershipCategory, bankingAccounts.holdings ? bankingAccounts.holdings.marketValue?.totalValue || 0 : 0))}
                                                  className="textalign-right"/>
                                              <TableCell
                                                  text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.partnerMember.id, bankingAccounts.memberOwnerships, bankingAccounts.ownershipCategory, bankingAccounts.holdings ? bankingAccounts.holdings.marketValue?.totalValue || 0 : 0))}
                                                  className="textalign-right"/>
                                              <TableCell
                                                  text={getFormattedPresentValueForCurrentNetWorth(calculateJointlyOwnedValue(bankingAccounts.ownershipCategory, bankingAccounts.holdings ? bankingAccounts.holdings.marketValue?.totalValue || 0 : 0))}
                                                  className="textalign-right"/>
                                          </>
                                          }
                                      </>
                                  }}/>;

    }


    const renderStandaloneAccountRow = (accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) => {
        const standaloneAccount = accountOrLegalAgreement as StandaloneAccount;
        return <StandaloneAccountRow standaloneAccount={standaloneAccount}
                                     actionsDisabled={false}
                                     onClickEdit={(accountId: string) => {
                                         history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditStandaloneAccount/${accountId}`);
                                     }}
                                     onClickDelete={(standaloneAccountDelete: StandaloneAccount) => {
                                         onRemoveAssetClick({
                                             showDeleteModal: true,
                                             modalTitle: "Asset",
                                             assetDescription: standaloneAccountDelete.name,
                                             assetType: "standalone account",
                                             assetId: standaloneAccountDelete.id,
                                         });
                                     }}
                                     onClickViewHoldings={(accountId: string) => {
                                         history.push(`/Profile/${profileId}/ClientProfile/${viewType}/StandaloneAccount/${accountId}/Holdings`);
                                     }}
                                     key={standaloneAccount.id}
                                     gridClassName={"current-net-worth-grid-with-actionmenu"}
                                     renderStandaloneAccountDetails={(account: StandaloneAccount) => {
                                         return <>
                                             <TableCell text={shortenName(account.name)}
                                                        subtext={showOwnershipType(account.ownershipCategory)}
                                                        className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                                        popoverContent={generateAssetPopOverContent(account)}
                                                        popoverWidth={"288px"}
                                             />
                                             {investorGroup.partnerMember && <>
                                                 <TableCell
                                                     text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.primaryMember.id, account.memberOwnerships, account.ownershipCategory, account.marketValue.totalValue))}
                                                     className="textalign-right"/>
                                                 <TableCell
                                                     text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.partnerMember.id, account.memberOwnerships, account.ownershipCategory, account.marketValue.totalValue))}
                                                     className="textalign-right"/>
                                                 <TableCell
                                                     text={getFormattedPresentValueForCurrentNetWorth(calculateJointlyOwnedValue(account.ownershipCategory, account.marketValue.totalValue))}
                                                     className="textalign-right"/>
                                             </>
                                             }
                                         </>
                                     }}/>;
    }

    return (
        <>
            {(hasInEstateAccount || hasInEstateInvestmentProgram || hasInEstatePartiallyOwnedInvestmentAccounts || hasInEstateInvestmentProgramHeldAA
                || hasInEstateBankingAccount) &&
                <AccordionItemWithActionMenu
                    uuid="Accounts"
                    accentColor={COLOR_ASSETS_ACCOUNTS}
                    HeaderComponent={({expanded}) => {
                        const totalInEstateMarketValue = calculateAllAccountsInEstateTotalMarketValue(
                            assetsData.accounts.data,
                            assetsData.investmentProgram,
                            assetsData.partiallyOwnedLegalAgreements,
                            assetsData.bankingAccounts);

                        return <AssetAccordionHeader
                            expanded={expanded}
                            title="Accounts"
                            formattedPrimaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateMemberOwnedAccountAndIPValue(investorGroup.primaryMember)) : undefined}
                            formattedSecondaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateMemberOwnedAccountAndIPValue(investorGroup.partnerMember)) : undefined}
                            formattedJointTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateJointlyOwnedAccountAndIPValue()) : undefined}
                            formattedTotalPresentValue={getFormattedTotalPresentValue(totalInEstateMarketValue)}
                            gridClassName={"current-net-worth-grid"}
                        />
                    }
                    }
                    actionMenu={actionMenu}
                >

                    <div role="table" className="current-net-worth-grid-with-actionmenu assets-grid-table"
                         aria-label="accounts-table">
                        <AssetAccordionContentHeader investorGroup={investorGroup}/>
                        {allAssetAccounts.map((allAssetAccount, index) => renderAccountRow(allAssetAccount))}

                    </div>

                </AccordionItemWithActionMenu>
            }
        </>
    );
}

export default Accounts;
