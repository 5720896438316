import React from "react";
import {formatCurrency} from "../../utils/format";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";
import PillTextComponent from "./PillTextComponent";
import {COLOR_COMPARE_PLANS_EXCESS, COLOR_COMPARE_PLANS_SHORTFALL} from "../../constants/colors";

interface EstimatedEstateTaxRowProps {
    ComparePlan1EstimatedEstateTax: number;
    ComparePlan2PlanSummaryResponse: PlanSummaryResponse;
    ComparePlan2EstimatedEstateTax: number;
    DifferenceInEstimatedEstateTax: number;
    isComparePlan2: boolean;
    showPillText?: boolean;
}

const EstimatedEstateTaxRow: React.FC<EstimatedEstateTaxRowProps> = ({
                                                                         ComparePlan1EstimatedEstateTax,
                                                                         ComparePlan2EstimatedEstateTax,
                                                                         ComparePlan2PlanSummaryResponse,
                                                                         DifferenceInEstimatedEstateTax,
                                                                         isComparePlan2,
                                                                         showPillText
                                                                     }) => {
    return (
        <div>
            {!isComparePlan2 && (
                <div className="plan-summary-card">
                    <div style={{textAlign: "center", marginTop: 15}}>
                        <b>{formatCurrency(ComparePlan1EstimatedEstateTax)}</b>
                    </div>
                </div>
            )}

            {isComparePlan2 && ComparePlan2PlanSummaryResponse != null && (
                <div className="plan-summary-card">
                    <div style={{textAlign: "center", marginTop: 15}}>
                        <b>{formatCurrency(ComparePlan2EstimatedEstateTax ?? 0)}</b>
                        {showPillText && DifferenceInEstimatedEstateTax != 0 &&
                            <PillTextComponent
                                color={DifferenceInEstimatedEstateTax >= 0 ? COLOR_COMPARE_PLANS_EXCESS : COLOR_COMPARE_PLANS_SHORTFALL}
                                pillTextValue={DifferenceInEstimatedEstateTax}
                                className={DifferenceInEstimatedEstateTax >= 0 ? "compare-plans-pill-container pill-container-excess" : "compare-plans-pill-container pill-container-shortfall"}/>
                        }
                    </div>
                </div>
            )}
        </div>
    )

}

export default EstimatedEstateTaxRow;