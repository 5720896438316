import {ServiceTeamMember} from "./models/ServiceTeam";
import {Partner, PartnerResource} from "./models/PartnerResource";
import {ProfileResponse, ProfileType, ProspectResponse, SampleResponse} from "./models/ProfileResponse";
import {Gender, LifeStatus, MemberType} from "./models/MemberType";
import {InvestorGroupMemberType, InvestorGroupType, MemberGroup} from "./models/InvestorGroupType";
import {Meeting, MeetingStatus, MeetingType} from "./Meeting/Meeting";
import {FamilyRelationshipType} from "./models/FamilyRelationshipType";
import {EstateFlowchartState, TrusteeType} from "src/EstateFlowchart/EstateFlowchartTypes";
import moment from "moment";
import {FamilyTreeType} from "./models/FamilyTreeType";

export const testServiceTeamMembers: ServiceTeamMember[] =
    [
        {
            partnerCode: "TP123",
            partnerName: "Test Partner",
            phoneNumber: "0123456789",
            email: "testPartner@ntrs.com",
            title: "Service Team Member 1"
        },
        {
            partnerCode: "rm620",
            partnerName: "Another Test",
            phoneNumber: "9876543210",
            email: "anotherPartner@ntrs.com",
            title: "Service Team Member 2"
        }
    ];

export const testPartnerResource: PartnerResource = {
    resourceCode: 'ulek123',
    entityName: 'Dave Nelson',
    firstName: 'Dave',
    lastName: 'Nelson',
    prospect: false,
    preferredName: 'David Bowie',
    partners: [{
        partnerCode: "axs63",
        partnerName: "Alex",
        phoneNumber: "911",
        email: "axs63@ntrs.com",
        title: "low life developer"
    }],
    accounts: [{
        accountNumbers: ['Account0001'],
        agreementNumber: 'LA0001',
        legalName: 'Dave account'
    }],
    linked: false
}

export const testPartnerResources: PartnerResource[] = [
    testPartnerResource
]

export const testPartners: Partner[] = [
    {
        partnerCode: 'rm620',
        partnerName: 'NeoRick Mercier',
        title: 'The Best',
        email: 'rm620@ntrs.com',
        phoneNumber: '9119119111'
    },
    {
        partnerCode: 'tu123',
        partnerName: 'Test User',
        title: 'Test User',
        email: 'tu123@ntrs.com',
        phoneNumber: '9119119111'
    },
    {
        partnerCode: 'tu456',
        partnerName: 'Test User2',
        title: 'Test User 2',
        email: 'tu456@ntrs.com',
        phoneNumber: '9119119111'
    },
]

export const testProfileResponse: ProfileResponse = {
    id: "profileResponse",
    createdDate: "2022-01-22",
    lastModifiedDate: "2022-03-17",
    managementFees: "",
    displayName: "Shauna and Dave Nelson",
    createdByName: "Sloan",
    lastModifiedByName: "Krishnan",
    owner: "",
    primaryContact: {
        firstName: "Shauna",
        middleInitial: "A",
        lastName: "Nelson",
        birthdate: "04/14/1964",
        age: 57,
        gender: Gender.Female,
        stateOfResidency: "Alabama",
        personalPlanningHorizon: 100,
        family: [],
        lifeStatus: LifeStatus.Living
    },
    effectiveTaxRate: {
        federal: "",
        stateAndLocal: "",
        total: "",
        capitalGains: ""
    },
    accreditedStatus: "Accredited",
    allocationType: "Strategic",
    resourceCode: "",
    type: ProfileType.PROFILE,
    archived: false,
    sample: false,
    prospect: false,
    activePortfolioReserve: false,
    portfolioReserveActivationDate: null,
    activeClient: true
}

export const testProspectResponse: ProspectResponse = {
    profile: {
        id: "test-prospect-id",
        createdDate: "2022-01-22",
        lastModifiedDate: "2022-03-17",
        managementFees: "",
        displayName: "Shauna and Dave Nelson",
        createdByName: "Sloan",
        lastModifiedByName: "Krishnan",
        owner: "",
        primaryContact: {
            firstName: "Shauna",
            middleInitial: "A",
            lastName: "Nelson",
            birthdate: "04/14/1964",
            age: 57,
            gender: Gender.Female,
            stateOfResidency: "Alabama",
            personalPlanningHorizon: 100,
            family: [],
            lifeStatus: LifeStatus.Living
        },
        effectiveTaxRate: {
            federal: "",
            stateAndLocal: "",
            total: "",
            capitalGains: ""
        },
        accreditedStatus: "Accredited",
        allocationType: "Strategic",
        resourceCode: "",
        type: ProfileType.PROFILE,
        archived: false,
        sample: false,
        prospect: false,
        activePortfolioReserve: false,
        portfolioReserveActivationDate: null,
        activeClient: true,
    },
    serviceTeamMembers: [{
        partnerCode: 'rb571',
        title: 'Expert Of Everything',
        email: 'bestprogrammerintheworld123@ntrs.com',
        phoneNumber: '911',
        partnerName: 'Ryan Brown'
    }]
}

export const testSampleResponse: SampleResponse = {
    ...testProspectResponse,
    profile: {
        ...testProspectResponse.profile,
        sample: true
    }
}

export const testProposalsResponse: ProfileResponse[] = [
    {
        ...testProfileResponse,
        id: "c4745ae1-52ba-4977-9ef2-37e1392a73a5",
        displayName: "Proposal 1",
        createdDate: "2022-02-23",
        lastModifiedDate: "2022-04-09",
        createdByName: "Shauna",
        lastModifiedByName: "Dave",
        archived: false,
    },
    {
        ...testProfileResponse,
        id: "f10c3613-2078-4bda-9345-2a94c19b7073",
        displayName: "Proposal 2",
        archived: false,
    }
]

export const testArchivedProposalsResponse: ProfileResponse[] = [
    {
        ...testProfileResponse,
        id: "c4745ae1-52ba-4977-9ef2-37e1392a73a5",
        displayName: "Proposal 1",
        createdDate: "2022-02-23",
        lastModifiedDate: "2022-04-09",
        createdByName: "Shauna",
        lastModifiedByName: "Dave",
        archived: true,
    },
    {
        ...testProfileResponse,
        id: "f10c3613-2078-4bda-9345-2a94c19b7073",
        displayName: "Proposal 2",
        archived: true,
    }
]

export const testInvestorGroupResponse: InvestorGroupType = {
    primaryMember: {
        id: "71c93bd7-6c43-4dc2-bf96-07c37c4fef06",
        firstName: "Shauna",
        name: "Shauna Nelson",
        birthdate: "1971-11-23",
        planningPeriod: {
            numberOfYears: 50,
            memberType: InvestorGroupMemberType.PRIMARY,
            ageFrom: 50,
            startYear: 2021,
        }
    },
    partnerMember: {
        id: "bf7e748d-32c5-41d2-8295-a35432071826",
        firstName: "Dave",
        name: "Dave Nelson",
        birthdate: "1971-11-23",
        planningPeriod: {
            numberOfYears: 50,
            memberType: InvestorGroupMemberType.PARTNER,
            ageFrom: 50,
            startYear: 2021,
        }
    },
    planningPeriod: {
        numberOfYears: 50,
        memberType: InvestorGroupMemberType.PRIMARY,
        ageFrom: 50,
        startYear: 2021,
    }
}

export const testMemberGroupResponse: MemberGroup = {
    primaryMember: {
        id: "71c93bd7-6c43-4dc2-bf96-07c37c4fef06",
        firstName: "Shauna",
        lastName: "Nelson",
        lifeStatus: LifeStatus.Living,
        deceasedDate: null,
        age: 55,
        relationshipType: null
    },
    partnerMember: {
        id: "bf7e748d-32c5-41d2-8295-a35432071826",
        firstName: "Dave",
        lastName: "Nelson",
        lifeStatus: LifeStatus.Living,
        deceasedDate: null,
        age: 60,
        relationshipType: FamilyRelationshipType.SPOUSE
    },
    additionalMembers: []
}

export const testDeceasedMemberGroupResponse: MemberGroup = {
    primaryMember: {
        id: "71c93bd7-6c43-4dc2-bf96-07c37c4fef06",
        firstName: "Shauna",
        lastName: "Nelson",
        lifeStatus: LifeStatus.Living,
        deceasedDate: null,
        age: 55,
        relationshipType: null
    },
    partnerMember: {
        id: "bf7e748d-32c5-41d2-8295-a35432071826",
        firstName: "Dave",
        lastName: "Nelson",
        lifeStatus: LifeStatus.Deceased,
        deceasedDate: "2010-11-23T10:00:00.000+00:00",
        age: 60,
        relationshipType: FamilyRelationshipType.SPOUSE
    },
    additionalMembers: []
}

export const testInvestorGroupWithNoSpouseResponse: InvestorGroupType = {
    primaryMember: {
        id: "71c93bd7-6c43-4dc2-bf96-07c37c4fef06",
        firstName: "Shauna",
        name: "Shauna Nelson",
        birthdate: "1971-11-23"
    },
    partnerMember: undefined,
    planningPeriod: {
        numberOfYears: 45,
        memberType: InvestorGroupMemberType.PRIMARY,
        ageFrom: 50,
        startYear: 2021,
    }
}

export const testMeeting: Meeting = {
    id: 'testMeetingId',
    relayContainerId: 'testRelayContainerId',
    fluidRelayDocumentId: 'fluidRelayDocumentId',
    title: 'Test Meeting',
    type: MeetingType.TRAINING_MEETING,
    status: MeetingStatus.CREATED,
    profileIdToPresent: 'testProfileId',
    createdBy: "",
    createdByName: "",
    endDate: "",
    presenter: "",
    presenterName: "",
    profileId: "",
    startDate: "",
    onlineMeetingId: 'testOnlineMeetingId',
    onlineMeetingCode: 'testOnlineMeetingCode',
    onlineMeetingJoinUrl: 'testOnlineMeetingJoinUrl',
    remoteEnabled: true
}
